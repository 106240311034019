import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';
import Clearfix from '../../ui/Clearfix';
import timestamp from '../../utilities/timestamp';
import Divider from '../../ui/Divider';
import CircleLink from '../../ui/CircleLink';
import ModalButton from '../../ui/ModalButton';
import Modal from '../../ui/Modal';
import TextInput from '../../ui/TextInput';
import Submit from '../../ui/Submit';

const Notes = ({ id, recipientType }) => {
  const [instance, setInstance] = useState();
  const [noteForm, setNoteForm] = useState({});

  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/notes/type?recipientType=${recipientType}&id=${id}`);
      if (response.status === 200) {
        setLoading(false);
        setList(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const deleteNote = (noteId) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        await __.delete(`/notes?id=${noteId}`);
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('Deleted successfully');
        setReload(!reload);
      } catch (err) {
        __.displayErrorMessage(err.response.data.message || err);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    };

    __.confirmDelete(deleteFn);
  };

  const addNote = async (e) => {
    try {
      e.preventDefault();
      __.showLoadingBar(dispatch);
      const response = await __.post(`/notes`, {
        note: noteForm.note,
        addedBy: localStorage.getItem('firstname'),
        recipientId: id,
        recipientType,
      });
      if (response.status === 200) {
        setLoading(false);
        setReload(!reload);
        setNoteForm({});
        __.displaySuccessMessage('Note added successful! Close the pop up or add another note.');
        // instance.close();
      }
    } catch (err) {
      console.log('err', err);
      // __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };
  return (
    <>
      <Panel className="col s12 mv3">
        <Heading className="left">Notes</Heading>
        <ModalButton className="right mv3" modalId="addNote">
          Add Note
        </ModalButton>
        <Clearfix />
        {list.map((note, i) => (
          <div className="col s12" key={i}>
            <p className="left">
              <b>Note by {note.addedBy}</b>
            </p>
            <p className="right">
              {timestamp.unixToDate(note.dateAdded)} <CircleLink onClick={() => deleteNote(note.id)} icon="delete" tooltip="Delete" className="ml3" />
            </p>
            <Clearfix />
            {note.note}
            <Divider className="mb4" />
          </div>
        ))}
      </Panel>
      <Modal id="addNote" setInstance={setInstance}>
        <form onSubmit={(e) => addNote(e)}>
          <TextInput label="Note" className="col s12" state={noteForm} isRequired id="note" setState={setNoteForm} value={noteForm.note} type="text" />
          <Submit className="col s12 l3">Add Note</Submit>
        </form>
      </Modal>
    </>
  );
};

export default Notes;
