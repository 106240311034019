import { useState, useEffect } from 'react';
import DTable from 'react-data-table-component';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';
import Datepicker from './Datepicker';
import Clearfix from './Clearfix';
import Submit from './Submit';
import __ from '../utilities/functions';
import timestamp from '../utilities/timestamp';
import DTCheckbox from './DTCheckbox';
import Button from './Button';
import CircleLink from './CircleLink';

// limitation: this can only take two fields as array in filters prop
const DataTable = ({
  data = [],
  columns,
  loading,
  filters,
  showDateRange = null,
  dateLabel = 'dateTime',
  withSelected = false,
  withSelectedFns,
  captureSelectedIds = false,
  setCapturedSelection,
  preSelectedRows = [],
}) => {
  const [filter, updateFilter] = useState('');
  const [table, updateTable] = useState();
  const dispatch = useDispatch();
  const [toDate, setToDate] = useState({ toDate: timestamp.now() });
  const [fromDate, setFromDate] = useState({ fromDate: timestamp.now() });
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    // populate the table variable with the initial data
    if (filter.length === 0) {
      updateTable(data);
    }
  }, [data]);

  // adding the ability to filter data
  const filterData = (text) => {
    text = text.toLowerCase();
    updateFilter(text);
    const filteredData = data.filter(
      (item) =>
        _.includes(String(item[filters[0]]).toLowerCase(), text) ||
        _.includes(String(item[filters[1]]).toLowerCase(), text) ||
        _.includes(String(item[filters[2]]).toLowerCase(), text) ||
        _.includes(String(item[filters[3]]).toLowerCase(), text)
    );

    updateTable(filteredData);
  };

  // fix to reload the tooltips after all the dynamically generated elements have been rendered in the dom
  setTimeout(() => {
    ReactTooltip.rebuild();
  }, 0);

  const dateSearch = (e) => {
    e.preventDefault();

    __.showLoadingBar(dispatch);
    const result = data.filter((row) => row[dateLabel] >= fromDate.fromDate && row[dateLabel] <= toDate.toDate);
    updateTable(result);
    __.hideLoadingBar(dispatch);
  };

  return (
    <>
      <ReactTooltip className="tooltip" />
      {showDateRange ? (
        <div className="col s12 mt4 no-padding-left">
          <form onSubmit={(e) => dateSearch(e)}>
            <Datepicker id="fromDate" state={fromDate} setState={setFromDate} className="col s12 l2 no-padding-left" label="From" />
            <Datepicker id="toDate" state={toDate} setState={setToDate} className="col s12 l2 " label="To" />
            <Submit className="col s12 l3 ">Search</Submit>
          </form>
        </div>
      ) : null}

      <Clearfix />

      {filters ? (
        <>
          <div className="input-field col s12 l3 right">
            <input
              id="filter"
              type="text"
              value={filter}
              onChange={(e) => {
                filterData(e.target.value);
              }}
              className="inp"
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="filter">Filter</label>
          </div>
        </>
      ) : null}

      {withSelected ? (
        <>
          <div className="input-field col s12 l3">
            {withSelectedFns.map((withSelectedFn) =>
              withSelectedFn.label ? (
                <CircleLink
                  onClick={() => {
                    if (selected.length > 0) {
                      withSelectedFn.fn(selected);
                    } else {
                      __.displayErrorMessage('Please select at least one item');
                    }
                  }}
                  tooltip={withSelectedFn.label}
                  icon={withSelectedFn.icon}
                />
              ) : null
            )}
          </div>
        </>
      ) : null}

      {withSelected ? (
        <DTable
          columns={columns}
          data={table}
          pagination
          paginationPerPage={15}
          progressPending={loading}
          selectableRows
          selectableRowsComponent={DTCheckbox}
          selectableRowsNoSelectAll
          selectableRowsComponentProps={{ selected: preSelectedRows }}
          onSelectedRowsChange={({ selectedRows }) => {
            const selectedArray = selectedRows.map((selectedData) => selectedData.id);
            if (captureSelectedIds) {
              setCapturedSelection(selectedArray);
            }
            setSelected(selectedArray);
          }}
          // selectableRowSelected={(row) => true}
        />
      ) : (
        <DTable columns={columns} data={table} pagination paginationPerPage={15} progressPending={loading} />
      )}
    </>
  );
};

export default DataTable;
