import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import DataTable from '../../../ui/DataTable';
import Text from '../../../ui/Text';
import DummyText from '../../../ui/DummyText';
import Breadcrumb from '../../../ui/Breadcrumb';

const ScoringLeads = () => {
  const { id } = useParams();
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [details, setDetails] = useState({});

  //get details
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/com/scoring?id=${id}`);
      if (response.status === 200) {
        setDetails(response.data[0]);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/com/leads?project=${localStorage.getItem('project')}&scoring=${id}`);
      if (response.status === 200) {
        setLoading(false);
        setList(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const columns = [
    {
      name: 'Lead',
      cell: (row) => (
        <p>
          {row.firstname} {row.lastname}
        </p>
      ),
    },
    {
      name: 'Contact Details',
      cell: (row) => (
        <p>
          Email: {row.email}
          <br />
          Phone: {row.phone}
        </p>
      ),
    },

    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          <CircleNavLink route={`/modules/com/lead-details/${row.id}`} icon="developer_board" tooltip="Details" />
        </span>
      ),
    },
  ];

  const breadcrumb = [
    { label: 'Scoring', route: '/modules/com/scoring' },
    { label: 'Leads By Score', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />

      <Panel className="col s12 mv3">
        <Heading className="left">Viewing Leads By Score: {details.label}</Heading>
        <Clearfix />
        <DataTable loading={loading} columns={columns} data={list} />
      </Panel>
    </>
  );
};

export default ScoringLeads;
