import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import Heading from '../../ui/Heading';
import Text from '../../ui/Text';
import Section from '../../ui/Section';
import Breadcrumb from '../../ui/Breadcrumb';
import TextInput from '../../ui/TextInput';
import Submit from '../../ui/Submit';
import __ from '../../utilities/functions';
import Clients from '../components/ClientDropdown';
import SubHeading from '../../ui/SubHeading';
import Clearfix from '../../ui/Clearfix';
import Panel from '../../ui/Panel';
import ModulePricingOptions from '../components/ModulePricingOptions';

function AddSubscription() {
  const dispatch = useDispatch();

  const breadcrumb = [
    { label: 'Subscriptions', route: '/admin/subscriptions' },
    { label: 'Add Subscription', route: '#' },
  ];

  const [form, setForm] = useState({ modules: [], users: 0, projects: 0 });
  const [total, setTotal] = useState(0);

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/subscriptions/quote?maxProjects=${form.projects}&maxUsers=${form.users}`);
      if (response.status === 200) {
        setTotal(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [form]);

  const addSubscription = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const response = await __.post('/subscriptions', {
        client: form.client,
        coreId: form.core_id,
        maxProjects: form.projects,
        maxUsers: form.users,
        modules: form.modules,
        billingCycle: 'monthly',
      });
      if (response.status === 200) {
        __.displaySuccessMessage('Subscription added!');
        __.redirect(dispatch, '/admin/subscriptions');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 l9 mv4">
        <Heading className="left">Add Subscription</Heading>
        <Clearfix />
        <form onSubmit={(e) => addSubscription(e)}>
          <SubHeading>Subscription Details</SubHeading>
          <TextInput label="Core ID" className="col s12 l3" state={form} isRequired id="core_id" setState={setForm} type="text" value={form.core_id} />
          <Clients setState={setForm} state={form} id="client" className="col s12 l5" label="Choose Client" initialValue={{ key: '0', value: 'None' }} />
          <TextInput label="Projects" className="col s12 l2" state={form} isRequired id="projects" setState={setForm} type="number" value={form.projects} />
          <TextInput label="Users" className="col s12 l2" state={form} isRequired id="users" setState={setForm} type="number" value={form.users} />
          <Clearfix />
          <Submit className="col s12">Add Subscription</Submit>
        </form>
      </Panel>

      <Panel className="col s12 l3 mv4">
        <SubHeading>Cost Summary</SubHeading>
        <Text>Users: {form.users}</Text>
        <Text>Projects: {form.projects}</Text>
        <Text className="light_blue_text">Total: ${total}/month</Text>
      </Panel>
    </>
  );
}

export default AddSubscription;
