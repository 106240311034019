import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import DataTable from '../../../ui/DataTable';
import CategoryVideos from './components/CategoryVideos';
import ModalButton from '../../../ui/ModalButton';
import Modal from '../../../ui/Modal';
import UploadAsset from '../cms/UploadAsset';
import AddVideo from './AddVideo';
import DummyText from '../../../ui/DummyText';

const Videos = () => {
  const [reload, setReload] = useState(false);
  const [categories, setCategories] = useState([]);
  const [instance, setInstance] = useState();

  const dispatch = useDispatch();

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/bms/categories?project=${localStorage.getItem('project')}`);
      if (response.status === 200) {
        setCategories(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  return (
    <>
      <Panel className="col s12 mv3">
        <Heading className="left">Videos</Heading>
        <Button route="/modules/bms/add-video" className="mv3 right">
          Add Video
        </Button>
        <Clearfix />
        <Text>
          The Broadcast Management System allows control over your own video page(s), allowing users to navigate, download, share, like and view your video content, on your website. The BMS allows you
          to customize page layouts, pick priority viewing and track user engagement.{' '}
        </Text>
      </Panel>

      {categories.map((cat) => (
        <Panel className="col s12 mv3">
          <Heading className="left">Category: {cat.name}</Heading>
          <Clearfix />
          <CategoryVideos category={cat.id} />
        </Panel>
      ))}
    </>
  );
};

export default Videos;
