function SubHeading({ children, className, icon }) {
  if (icon) {
    return (
      <h2 className={`proxima-light f4 ${className}`}>
        <i className="material-icons left">{icon}</i> {children}
      </h2>
    );
  }

  return <h2 className={`proxima-light f4 ${className}`}>{children}</h2>;
}

export default SubHeading;
