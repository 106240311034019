import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import __ from '../../utilities/functions';
import Breadcrumb from '../../ui/Breadcrumb';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import Clearfix from '../../ui/Clearfix';
import timestamp from '../../utilities/timestamp';
import InvoiceBreakdown from '../components/InvoiceBreakdown';

const InvoiceDetails = () => {
  const { id } = useParams();

  const breadcrumb = [
    { label: 'Invoices', route: '/client/invoices' },
    { label: 'Invoice Details', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv4">
        <Heading className="left">Invoice Details</Heading>
        <Clearfix />
        <InvoiceBreakdown id={id} />
      </Panel>
    </>
  );
};

export default InvoiceDetails;
