import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';
import CircleLink from '../../ui/CircleLink';
import ClientSubscriptionList from '../../admins/components/ClientSubscriptionList';
import InvoiceList from '../../admins/components/InvoiceList';
import TransactionList from '../../admins/components/TransactionList';
import Breadcrumb from '../../ui/Breadcrumb';
import Clearfix from '../../ui/Clearfix';
import timestamp from '../../utilities/timestamp';
import SubscriptionInvoiceList from '../../admins/components/SubscriptionInvoiceList';
import CircleNavLink from '../../ui/CircleNavLink';
import DataTable from '../../ui/DataTable';
import CircleAnchorLink from '../../ui/CircleAnchorLink';
import config from '../../utilities/config';
import TextInput from '../../ui/TextInput';
import Submit from '../../ui/Submit';

const EditUser = () => {
  const { id } = useParams();
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const [reload, setReload] = useState(true);
  const [loading, updateLoading] = useState(true);
  const [form, setForm] = useState({});

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);

      const response = await __.get(`/users?id=${id}`);

      if (response.status === 200) {
        __.hideLoadingBar(dispatch);
        setForm(response.data[0]);
      } else {
        __.displayErrorMessage(response.message);
      }
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage(err.response.data.message);
    }
  }, []);

  const breadcrumb = [
    { label: 'Subscriptions', route: '/client/subscriptions' },
    { label: 'Subscriptions Details', route: `/client/subscription-details/${form.subscription}` },
    { label: 'Manage Users', route: `/client/manage-users/${form.subscription}` },
    { label: 'Edit User', route: '#' },
  ];

  const editUser = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);

      const response = await __.patch(`/users`, form);

      if (response.status === 204) {
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('User updated successfully!');
        __.redirect(dispatch, `/client/manage-users/${form.subscription}`);
      } else {
        __.displayErrorMessage(response.message);
      }
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage(err.response.data.message);
    }
  };

  return (
    <div>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Edit User Account</Heading>
        <Clearfix />
        <form onSubmit={(e) => editUser(e)}>
          <TextInput label="First Name" className="col s3" state={form} isRequired id="firstname" setState={setForm} value={form.firstname} type="text" />
          <TextInput label="Last Name" className="col s3" state={form} isRequired id="lastname" setState={setForm} value={form.lastname} type="text" />
          <TextInput label="Email" className="col s3" state={form} isRequired id="email" setState={setForm} value={form.email} type="text" />
          <Submit className="col s12">Save Changes</Submit>
        </form>
      </Panel>
    </div>
  );
};

export default EditUser;
