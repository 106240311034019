import TopBarProgress from "react-topbar-progress-indicator";

TopBarProgress.config({
  barColors: {
    "1.0": "#FFD553",
    0: "#27A9E7",
  },
  shadowBlur: 5,
});

export default TopBarProgress;
