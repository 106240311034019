import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';
import CircleLink from '../../ui/CircleLink';
import ClientSubscriptionList from '../../admins/components/ClientSubscriptionList';
import InvoiceList from '../../admins/components/InvoiceList';
import TransactionList from '../../admins/components/TransactionList';
import Breadcrumb from '../../ui/Breadcrumb';
import Clearfix from '../../ui/Clearfix';
import timestamp from '../../utilities/timestamp';
import SubscriptionInvoiceList from '../../admins/components/SubscriptionInvoiceList';
import CircleNavLink from '../../ui/CircleNavLink';
import DataTable from '../../ui/DataTable';
import CircleAnchorLink from '../../ui/CircleAnchorLink';
import config from '../../utilities/config';
import TextInput from '../../ui/TextInput';
import Submit from '../../ui/Submit';
import Text from '../../ui/Text';
import TextArea from '../../ui/TextArea';
import ClickButton from '../../ui/ClickButton';

const EditProject = () => {
  const { id } = useParams();
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const [reload, setReload] = useState(true);
  const [loading, updateLoading] = useState(true);
  const [form, setForm] = useState({});

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);

      const response = await __.get(`/projects?id=${id}`);

      if (response.status === 200) {
        __.hideLoadingBar(dispatch);
        setForm(response.data[0]);
      } else {
        __.displayErrorMessage(response.message);
      }
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage(err.response.data.message);
    }
  }, [reload]);

  const breadcrumb = [
    { label: 'Subscriptions', route: '/client/subscriptions' },
    { label: 'Subscriptions Details', route: `/client/subscription-details/${form.subscription}` },
    { label: 'Manage Projects', route: `/client/manage-projects/${form.subscription}` },
    { label: 'Edit Project', route: '#' },
  ];

  const editProject = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);

      const response = await __.patch(`/projects`, form);

      if (response.status === 204) {
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('Project updated successfully!');
        __.redirect(dispatch, `/client/manage-projects/${form.subscription}`);
      }
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage(err.response.data.message);
    }
  };

  const resetToken = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);

      const response = await __.patch(`/projects/token`, { id: form.id });

      if (response.status === 200) {
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('Project token reset successfully!');
        setReload(!reload);
      }
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage(err.response.data.message);
    }
  };

  return (
    <div>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Edit Project</Heading>
        <Clearfix />
        <form onSubmit={(e) => editProject(e)}>
          <TextInput label="Project Name" className="col s4" state={form} isRequired id="projectName" setState={setForm} value={form.projectName} type="text" />
          <TextInput label="Project Description" className="col s4" state={form} isRequired id="projectDescription" setState={setForm} value={form.projectDescription} type="text" />
          <TextInput label="Website" className="col s4" state={form} isRequired id="website" setState={setForm} value={form.website} type="text" />
          <Submit className="col s12">Save Changes</Submit>
        </form>
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">Project API Key</Heading>
        <Clearfix />
        <Text>
          Use this token in combination with your project ID to make API calls. If you reset your API key, you will need to update them wherever you have used them in your applications that connect to
          the Core.
        </Text>
        <TextArea label="API Key" className="col s12" state={form} isRequired id="token" setState={setForm} value={form.token} disabled />
        <ClickButton
          onClick={(e) => {
            resetToken(e);
          }}
          className="right mb4"
        >
          Reset API Token
        </ClickButton>
        <Clearfix />
      </Panel>
    </div>
  );
};

export default EditProject;
