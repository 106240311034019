import { useState, useEffect, useMemo } from 'react';
import { Chart } from 'react-charts';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import getCustomerDetails from '../../utilities/getCustomerDetails';
import getLeadDetails from '../../utilities/getLeadDetails';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import Clearfix from '../../ui/Clearfix';
import Datepicker from '../../ui/Datepicker';
import NewLeads from '../data/NewLeads';
import DateRange from '../../ui/DateRange';
import timestamp from '../../utilities/timestamp';
import DummyText from '../../ui/DummyText';
import FormSubmissions from '../data/FormSubmissions';
import NewCustomers from '../data/NewCustomers';
import TextInput from '../../ui/TextInput';
import TextArea from '../../ui/TextArea';
import Submit from '../../ui/Submit';
import __ from '../../utilities/functions';

const SendMail = () => {
  const { recipient, id } = useParams();
  const [form, setForm] = useState({});
  const [details, setDetails] = useState({});

  const dispatch = useDispatch();

  // get the details of the recipient
  useEffect(async () => {
    __.showLoadingBar(dispatch);
    try {
      let response;

      if (recipient === 'leads') {
        response = await getLeadDetails(id);
      }
      if (recipient === 'customers') {
        response = await getCustomerDetails(id);
      }

      setDetails(response);
    } catch (err) {
      console.log('err', err);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const sendMail = async (e) => {
    e.preventDefault();
    __.showLoadingBar(dispatch);
    try {
      await __.post('/com/mail', { ...form, project: localStorage.getItem('project'), email: details.email });
      __.displaySuccessMessage('Mail sent successfully!');
      __.redirect(dispatch, '/users');
    } catch (err) {
      console.log('err', err);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };
  return (
    <div>
      <Panel className="col s12 mv3">
        <Heading className="left">
          Send Mail to {details.firstname} {details.lastname}
        </Heading>
        <Clearfix />
        <DummyText />
        <form onSubmit={(e) => sendMail(e)}>
          <TextInput label="Subject" className="col s12" state={form} isRequired id="subject" setState={setForm} type="text" value={form.subject} />
          <TextArea label="Message" className="col s12" state={form} isRequired id="message" setState={setForm} value={form.message} />
          <Submit className="col s2">Send Mail</Submit>
        </form>
      </Panel>
    </div>
  );
};
export default SendMail;
