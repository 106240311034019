import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import DataTable from '../../../ui/DataTable';
import Text from '../../../ui/Text';
import DummyText from '../../../ui/DummyText';
import Breadcrumb from '../../../ui/Breadcrumb';
import SelectMenu from '../../../ui/SelectMenu';
import getLeadDetails from '../../../utilities/getLeadDetails';
import Submissions from '../../components/Submissions';
import Submit from '../../../ui/Submit';

const SetScore = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [score, setScore] = useState({ score: '' });

  const [scoreList, setScoreList] = useState([]);

  // get score list
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/com/scoring?project=${localStorage.getItem('project')}`);
      setScoreList(response.data);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const [details, setDetails] = useState({ data: [] });
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await getLeadDetails(id);
      setScore({ score: response.score });
      setDetails(response);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const updateScore = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const response = await __.patch(`/com/leads`, { ...details, scoring: score.score });
      if (response.status === 204) {
        __.displaySuccessMessage('Changes saved successfully!');
        __.redirect(dispatch, `/modules/com/lead-details/${id}`);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Scoring', route: '/modules/com/scoring' },
    { label: 'Leads By Score', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />

      <Panel className="col s12 mv3">
        <Heading className="left">
          Set Score: {details.firstname} {details.lastname}
        </Heading>
        <Clearfix />
        <p>Set the lead's score. Leads with higher scores are ranked higher on the leads listing page.</p>
        <form onSubmit={(e) => updateScore(e)}>
          <SelectMenu id="score" state={score} setState={setScore} defaultValue={details.score} list={scoreList} className="col s12 l3" idKey="id" label="None" valueKey="label" />
          <Submit className="col s12 l3">Set Score</Submit>
        </form>
      </Panel>
    </>
  );
};

export default SetScore;
