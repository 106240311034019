import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import DataTable from '../../../ui/DataTable';
import TextInput from '../../../ui/TextInput';
import CodeEditor from '../../../ui/CodeEditor';
import Submit from '../../../ui/Submit';
import Breadcrumb from '../../../ui/Breadcrumb';

const EditSection = () => {
  const { id } = useParams();
  const [form, setForm] = useState({});

  const state = useSelector((store) => store);
  const dispatch = useDispatch();

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/cms/sections?id=${id}`);
      if (response.status === 200) {
        setForm(response.data[0]);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const editSection = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const response = await __.patch(`/cms/sections`, {
        label: form.label,
        code: form.code,
        name: form.name,
        project: localStorage.getItem('project'),
        id,
      });
      if (response.status === 204) {
        __.displaySuccessMessage('Changes have been saved!');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };
  const breadcrumb = [
    { label: 'Sections', route: '/modules/cms/sections' },
    { label: 'Edit Section', route: '#' },
  ];
  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />

      <Panel className="col s12 mv3">
        <Heading className="left">Edit Section</Heading>
        <Clearfix />
        <form onSubmit={(e) => editSection(e)}>
          <TextInput label="Name" className="col s3" state={form} isRequired id="name" setState={setForm} type="text" value={form.name} />
          <Clearfix />
          <TextInput label="Label" className="col s2" state={form} isRequired id="label" setState={setForm} type="text" value={form.label} />
          <CodeEditor className="col s12" state={form} setState={setForm} id="code" />
          <Submit className="col s12">Save Changes</Submit>
        </form>
      </Panel>
    </>
  );
};

export default EditSection;
