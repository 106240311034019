import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import SelectMenu from '../../../ui/SelectMenu';
import FileUpload from '../../../ui/FileUpload';
import Breadcrumb from '../../../ui/Breadcrumb';

const EditScoring = () => {
  const { id } = useParams();
  const [form, setForm] = useState({ id });
  const dispatch = useDispatch();

  //get details
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/com/scoring?id=${id}`);
      if (response.status === 200) {
        setForm(response.data[0]);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const editScoring = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const response = await __.patch(`/com/scoring`, form);
      if (response.status === 204) {
        __.displaySuccessMessage('Saved successfully!');
        __.redirect(dispatch, '/modules/com/scoring');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Scoring', route: '/modules/com/scoring' },
    { label: 'Edit Scoring', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Edit Scoring</Heading>
        <Clearfix />
        <form onSubmit={(e) => editScoring(e)}>
          <TextInput label="Scoring Label" className="col s12 l4" state={form} isRequired id="label" setState={setForm} type="text" value={form.label} />
          <TextInput label="Score" className="col s12 l4" state={form} isRequired id="score" setState={setForm} type="text" value={form.score} />
          <TextInput label="Color (HEX)" className="col s12 l4" state={form} isRequired id="color" setState={setForm} type="text" value={form.color} />
          <Submit className="col s12">Save Changes</Submit>
        </form>
      </Panel>
    </>
  );
};

export default EditScoring;
