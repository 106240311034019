import { Line } from 'react-chartjs-2';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import Clearfix from '../../ui/Clearfix';
import timestamp from '../../utilities/timestamp';
import __ from '../../utilities/functions';
import DateRange from '../../ui/DateRange';

const NewCustomers = ({ className, from, to }) => {
  const dispatch = useDispatch();

  const options = {};

  const [data, setData] = useState({ labels: [], datasets: [] });

  // get data
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/stats/users?dataset=customers&from=${from}&to=${to}&project=${localStorage.getItem('project')}`);
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err?.response?.data?.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [to, from, localStorage.getItem('project')]);
  return (
    <Panel className={`mv3 ${className}`}>
      <Heading className="left">New Customers</Heading>
      <Clearfix />
      <Line data={data} options={options} />
    </Panel>
  );
};

export default NewCustomers;
