import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import __ from '../../../utilities/functions';
import TextInput from '../../../ui/TextInput';
import CodeEditor from '../../../ui/CodeEditor';
import Submit from '../../../ui/Submit';
import Breadcrumb from '../../../ui/Breadcrumb';

const AddSection = () => {
  const [form, setForm] = useState({});

  const dispatch = useDispatch();

  const addSection = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const response = await __.post(`/cms/sections`, {
        label: form.label,
        code: form.code,
        name: form.name,
        project: localStorage.getItem('project'),
      });
      if (response.status === 200) {
        __.displaySuccessMessage('Section added!');
        __.redirect(dispatch, '/modules/cms/sections');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };
  const breadcrumb = [
    { label: 'Sections', route: '/modules/cms/sections' },
    { label: 'Add Section', route: '#' },
  ];
  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />

      <Panel className="col s12 mv3">
        <Heading className="left">Add Section</Heading>
        <Clearfix />
        <form onSubmit={(e) => addSection(e)}>
          <TextInput label="Name" className="col s3" state={form} isRequired id="name" setState={setForm} type="text" value={form.name} />
          <Clearfix />
          <TextInput label="Label" className="col s2" state={form} isRequired id="label" setState={setForm} type="text" value={form.label} />
          <CodeEditor className="col s12" state={form} setState={setForm} id="code" />
          <Submit className="col s12">Add Section</Submit>
        </form>
      </Panel>
    </>
  );
};

export default AddSection;
