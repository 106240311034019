/**
 * this component can be used for both customer and lead profiles
 * to list out inventory engagements tied to these accounts
 * typeIs is the id of the lead or customer
 * tagType is either lead or customer
 */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DataTable from '../../ui/DataTable';
import __ from '../../utilities/functions';

const ProfileInventoryEngagements = ({ typeId, tagType }) => {
  const dispatch = useDispatch();
  const [reload, setReload] = useState(true);

  const [loading, setLoading] = useState(true);
  const [engagements, setEngagements] = useState([]);
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/inv/tags?typeId=${typeId}&tagType=${tagType}`);
      setLoading(false);
      setEngagements(response.data);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const engagementsColumns = [
    {
      name: 'Inventory Item',
      cell: (row) => <p>{row.inventoryName}</p>,
    },
  ];
  return <DataTable data={engagements} loading={loading} columns={engagementsColumns} />;
};

export default ProfileInventoryEngagements;
