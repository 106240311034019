import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

function Breadcrumb({ trail, className="" }) {
  return (
        <div className={`rounded ${className}`}>
          {trail.map((item, i) => (
            <NavLink to={item.route} className="breadcrumb f5" key={i}>
              {item.label}
            </NavLink>
          ))}
        </div>
  );
}

Breadcrumb.propTypes = {
  trail: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, route: PropTypes.any })
  ),
};
export default Breadcrumb;
