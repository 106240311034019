import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import DataTable from '../../../ui/DataTable';
import ModalButton from '../../../ui/ModalButton';
import Modal from '../../../ui/Modal';
import AddGroup from './modals/AddGroup';

const Groups = () => {
  const [reload, setReload] = useState(false);
  const [loading, updateLoading] = useState(true);
  const [list, setList] = useState([]);

  const state = useSelector((store) => store);
  const dispatch = useDispatch();

  useEffect(async () => {
    __.showLoadingBar(dispatch);

    try {
      const response = await __.get(`/cms/groups?project=${localStorage.getItem('project')}`);
      if (response.status === 200) {
        updateLoading(false);
        setList(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const deleteGroup = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        const response = await __.delete(`/cms/groups?id=${id}`);
        if (response.status === 204) {
          __.hideLoadingBar(dispatch);
          __.displaySuccessMessage('Section deleted successfully');
          setReload(!reload);
        } else {
          throw Error('Could not delete!');
        }
      } catch (err) {
        __.hideLoadingBar(dispatch);
        __.displayErrorMessage(`API Error: ${err}`);
      }
    };

    __.confirmDelete(deleteFn);
  };

  const columns = [
    {
      name: 'Name',
      sortable: false,
      cell: (row) => <p>{row.name}</p>,
    },

    {
      name: 'ID',
      sortable: false,
      cell: (row) => <p>{row.id}</p>,
    },

    {
      name: '',
      sortable: false,
      right: true,
      cell: (row) => (
        <>
          {/*<CircleNavLink route={`/modules/cms/edit-group/${row.id}`} icon="edit" tooltip="Edit Section" className="mh4" />*/}

          <CircleLink
            icon="delete"
            tooltip="Delete Group"
            onClick={() => {
              deleteGroup(row.id);
            }}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Panel className="col s12 mv3">
        <Heading className="left">Groups</Heading>
        <Button route="/modules/cms/add-group" position="right" className="mv3">
          Add Group
        </Button>

        <Clearfix />
        <Text>
          Compose groups of sections to easily access multiple sections with one call to the API. Grouping your sections will help reduce the number of calls you make to the API to get these sections.
        </Text>
        <DataTable loading={loading} columns={columns} data={list} />
      </Panel>
    </>
  );
};

export default Groups;
