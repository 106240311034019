import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import SelectMenu from '../../../ui/SelectMenu';
import FileUpload from '../../../ui/FileUpload';
import Breadcrumb from '../../../ui/Breadcrumb';

const AddGroup = () => {
  const [form, setForm] = useState({ project: localStorage.getItem('project') });
  const dispatch = useDispatch();

  const addGroup = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const response = await __.post(`/crm/groups`, form);
      if (response.status === 200) {
        __.displaySuccessMessage('Group added!');
        __.redirect(dispatch, '/modules/crm/groups');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Groups', route: '/modules/crm/groups' },
    { label: 'Add Group', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Add Group</Heading>
        <Clearfix />
        <form onSubmit={(e) => addGroup(e)}>
          <TextInput label="Group Name" className="col s12 l3" state={form} isRequired id="groupName" setState={setForm} type="text" value={form.groupName} />
          <Submit className="col s12">Add Group</Submit>
        </form>
      </Panel>
    </>
  );
};

export default AddGroup;
