import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import DataTable from '../../../ui/DataTable';
import Text from '../../../ui/Text';
import GroupItems from './components/GroupItems';
import SubHeading from '../../../ui/SubHeading';
import DummyText from '../../../ui/DummyText';

const Items = () => {
  const [list, setList] = useState([]);
  const dispatch = useDispatch();

  // get list of groups
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/inv/groups?project=${localStorage.getItem('project')}`);
      if (response.status === 200) {
        setList(response.data);
      }
    } catch (err) {
      console.log(err);
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  return (
    <>
      <Panel className="col s12 mv3">
        <Heading className="left">Items</Heading>
        <Clearfix />
        <Text>
          The Inventory Management System Provides simultaneous front end, back end, and organizational control over the inventory for each project. The IMS interfaces directly into the “website”
          allowing changes and updates in inventory and pricing to happen in real time.
        </Text>
      </Panel>

      {list.map((group) => (
        <Panel className="col s12 mv3" key={group.id}>
          <SubHeading className="left">Group: {group.name}</SubHeading>
          <Button route={`/modules/inv/add-item/${group.id}`} position="right" className="mv3">
            Add Item
          </Button>
          <Clearfix />
          <GroupItems group={group.id} />
        </Panel>
      ))}
    </>
  );
};

export default Items;
