const moment = require('moment');

const time = {
  now: () => moment().unix(),

  convert: (unix) => moment.unix(unix).format('MMMM DD, YYYY'),

  unixToDate: (unix) => moment.unix(unix).format('MMMM DD, YYYY'),

  unixToDateTime: (unix) => moment.unix(unix).format('MMMM DD, YYYY (hh:mm a)'),
  unixToTime: (unix) => moment.unix(unix).format('hh:mm:ss a'),
  unixToTimeNoSeconds: (unix) => moment.unix(unix).format('hh:mm a'),
  unixToTimeNoSeconds24: (unix) => moment.unix(unix).format('HH:mm'),

  dateToUnix(date, format) {
    return moment(date, format).valueOf() / 1000;
  },
};

module.exports = time;
