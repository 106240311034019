import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../../../utilities/functions';
import Panel from '../../../../ui/Panel';
import Heading from '../../../../ui/Heading';
import Button from '../../../../ui/Button';
import Clearfix from '../../../../ui/Clearfix';
import Text from '../../../../ui/Text';
import DataTable from '../../../../ui/DataTable';
import CircleLink from '../../../../ui/CircleLink';
import config from '../../../../utilities/config';

const CategoryVideos = ({ category }) => {
  const dispatch = useDispatch();

  const [reload, setReload] = useState(false);
  const [loading, updateLoading] = useState(true);

  // get the cat videos
  const [videos, setVideos] = useState([]);
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/bms/videos?category=${category}`);
      if (response.status === 200) {
        // get stats for each video
        // eslint-disable-next-line no-restricted-syntax
        for await (const video of response.data) {
          const stats = await __.get(`/bms/videos/stats?id=${video.id}`);
          response.data[0].stats = stats.data;
        }
        updateLoading(false);
        setVideos(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const deleteVideo = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        const response = await __.delete(`/bms/videos?id=${id}`);
        if (response.status === 204) {
          __.hideLoadingBar(dispatch);
          __.displaySuccessMessage('Video deleted successfully');
          setReload(!reload);
        } else {
          throw Error('Could not delete!');
        }
      } catch (err) {
        __.hideLoadingBar(dispatch);
        __.displayErrorMessage(`API Error: ${err}`);
      }
    };

    __.confirmDelete(deleteFn);
  };

  const columns = [
    {
      name: 'Video',
      sortable: false,
      cell: (row) => (
        <p>
          Title: {row.title} <br />
          Video Source: {config.publicStorageUrl}
          {row.source} <br />
          Cover Source: {config.publicStorageUrl}
          {row.poster} <br />
          <small>
            Likes: {row.stats?.likes || 0}, Views: {row.stats?.views || 0}
          </small>
        </p>
      ),
    },

    {
      name: '',
      sortable: false,
      right: true,
      cell: (row) => (
        <>
          <CircleLink
            icon="delete"
            tooltip="Delete Video"
            onClick={() => {
              deleteVideo(row.id);
            }}
          />
        </>
      ),
    },
  ];

  return <DataTable loading={loading} columns={columns} data={videos} />;
};

export default CategoryVideos;
