import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import CircleLink from '../../ui/CircleLink';
import CircleNavLink from '../../ui/CircleNavLink';
import DataTable from '../../ui/DataTable';
import timestamp from '../../utilities/timestamp';

const TransactionList = ({ client = null }) => {
  const dispatch = useDispatch();
  const [reload, setReload] = useState(true);
  const [list, setList] = useState([]);
  const [loading, updateLoading] = useState(true);

  // get the list
  useEffect(async () => {
    __.showLoadingBar(dispatch);
    let response;
    try {
      if (client) {
        response = await __.get(`/transactions/client?id=${client}`);
      } else {
        response = await __.get(`/transactions`);
      }
      if (response.status === 200) {
        setList(response.data);
        __.hideLoadingBar(dispatch);
        updateLoading(false);
      }
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage(`API Error: ${err}`);
    }
  }, [reload]);

  const columns = [
    {
      name: 'ID',
      sortable: false,
      cell: (row) => <p>{row.id}</p>,
    },

    {
      name: 'Date',
      sortable: false,
      cell: (row) => <p>{timestamp.unixToDate(row.dateTime)}</p>,
    },

    {
      name: 'Amount',
      sortable: false,
      cell: (row) => <p>${row.amount}</p>,
    },

    {
      name: 'Description',
      sortable: false,
      cell: (row) => <p>{row.description}</p>,
    },
  ];

  return (
    <>
      <DataTable loading={loading} columns={columns} data={list} />
    </>
  );
};

export default TransactionList;
