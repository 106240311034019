const cmsMenu = {
  id: 'cms',
  icon: 'attach_money',
  name: 'Content Management',
  subMenu: [
    // { route: '/modules/cms/assets', name: 'Assets' },
    { route: '/modules/cms/sections', name: 'Sections' },
    { route: '/modules/cms/groups', name: 'Groups' },
  ],
};

const bmsMenu = {
  id: 'bms',
  icon: 'attach_money',
  name: 'Broadcasting',
  subMenu: [
    { route: '/modules/bms/videos', name: 'Videos' },
    { route: '/modules/bms/categories', name: 'Categories' },
  ],
};

const invMenu = {
  id: 'inv',
  icon: 'attach_money',
  name: 'Inventory',
  subMenu: [
    { route: '/modules/inv/items', name: 'Items' },
    { route: '/modules/inv/groups', name: 'Groups' },
    { route: '/modules/inv/templates', name: 'Templates' },
    { route: '/modules/inv/hottest-items', name: 'Hottest Items' },
  ],
};

const comMenu = {
  id: 'com',
  icon: 'attach_money',
  name: 'Lead Management',
  subMenu: [
    { route: '/modules/com/forms', name: 'Forms' },
    { route: '/modules/com/leads', name: 'Leads' },
    { route: '/modules/com/scoring', name: 'Scoring' },
  ],
};

const crmMenu = {
  id: 'crm',
  icon: 'attach_money',
  name: 'Customer Management',
  subMenu: [
    { route: '/modules/crm/customers', name: 'Customers' },
    { route: '/modules/crm/groups', name: 'Groups' },
  ],
};

const asmMenu = {
  id: 'asm',
  icon: 'attach_money',
  name: 'Digital Assets',
  subMenu: [{ route: '/modules/asm/assets', name: 'Assets' }],
};

const schMenu = {
  id: 'sch',
  icon: 'attach_money',
  name: 'Scheduler',
  subMenu: [{ route: '/modules/sch/events', name: 'Events' }],
};

const accountMenu = {
  id: 'account',
  icon: 'settings',
  name: 'Account',
  subMenu: [
    { route: '/users', name: 'Dashboard' },
    { route: '/users/change-password', name: 'Change Password' },
    { route: '/logout', name: 'Logout' },
  ],
};

const menu = [invMenu, comMenu, crmMenu, schMenu, asmMenu, bmsMenu, cmsMenu];

export default menu;
