/**
 * this component can be used for both customer and lead profiles
 * to list out inventory engagements tied to these accounts
 * typeIs is the id of the lead or customer
 * tagType is either lead or customer
 */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DataTable from '../../../ui/DataTable';
import __ from '../../../utilities/functions';
import timestamp from '../../../utilities/timestamp';
import CircleLink from '../../../ui/CircleLink';
import CircleAnchorLink from '../../../ui/CircleAnchorLink';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Button from '../../../ui/Button';
import DummyText from '../../../ui/DummyText';
import ModalButton from '../../../ui/ModalButton';
import CircleNavLink from '../../../ui/CircleNavLink';
import Modal from '../../../ui/Modal';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import Breadcrumb from '../../../ui/Breadcrumb';
import Text from '../../../ui/Text';

const Folders = ({ typeId, tagType }) => {
  const dispatch = useDispatch();
  const [reload, setReload] = useState(true);

  const [loading, setLoading] = useState(true);
  const [folders, setFolders] = useState([]);

  const [instance, setInstance] = useState();

  const [form, setForm] = useState({ project: localStorage.getItem('project') });

  //get list of folders
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/asm/folders?project=${localStorage.getItem('project')}`);
      setLoading(false);
      setFolders(response.data);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const deleteFolder = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        const response = await __.delete(`/asm/folders?id=${id}`);
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('Folder successfully deleted');
        setReload(!reload);
      } catch (err) {
        __.displayErrorMessage(err.response.data.message || err);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    };

    __.confirmDelete(deleteFn);
  };

  const folderColumns = [
    {
      name: 'Folders',
      cell: (row) => <p>{row.folder}</p>,
    },
    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          <CircleNavLink route={`/modules/asm/edit-folder/${row.id}`} icon="edit" tooltip="Edit Section" className="mh4" />
          <CircleLink onClick={() => deleteFolder(row.id)} icon="delete" tooltip="Delete" />
        </span>
      ),
    },
  ];

  const addFolder = async (e) => {
    e.preventDefault();

    try {
      __.hideLoadingBar(dispatch);
      const response = await __.post(`/asm/folders`, form);
      if (response.status === 200) {
        __.displaySuccessMessage('The folder has been added successfully');
        instance[0].close();
        setReload(!reload);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message || err);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };
  const breadcrumb = [
    { label: 'Assets', route: '/modules/asm/assets' },
    { label: 'Manage Folders', route: '#' },
  ];
  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />

      <Panel className="col s12 mv3">
        <Heading className="left">Manage Folders</Heading>
        <ModalButton className="mv3 right" modalId="addFolder">
          Add Folder
        </ModalButton>
        <Clearfix />
        <Text>
          This section allows you to create folders of relevant marketing documents, sale documents, leasing documents and“universally” required digital assets. Simply click “add folder” to create a
          file, then it will appear in the “assets” section, ready to load.
        </Text>

        <DataTable data={folders} loading={loading} columns={folderColumns} />
      </Panel>
      <Modal id="addFolder" setInstance={setInstance}>
        <Heading>Add Folder</Heading>
        <form onSubmit={(e) => addFolder(e)}>
          <TextInput label="Folder Name" className="col s12 l6" state={form} isRequired id="folder" value={form.folder} setState={setForm} type="text" />
          <Submit className="col s12">Add Folder</Submit>
        </form>
      </Modal>
    </>
  );
};

export default Folders;
