import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import DataTable from '../../ui/DataTable';
import CircleNavLink from '../../ui/CircleNavLink';
import timestamp from '../../utilities/timestamp';

const SubscriptionList = ({ client }) => {
  const dispatch = useDispatch();
  const [subs, setSubs] = useState([]);
  const [loading, updateLoading] = useState(true);

  // get data from api
  useEffect(async () => {
    __.showLoadingBar(dispatch);
    try {
      const response = await __.get(`/subscriptions/client?id=${client}`);
      if (response.status === 200) {
        setSubs(response.data);
        __.hideLoadingBar(dispatch);
        updateLoading(false);
      }
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage('API Error:', err);
    }
  }, [client]);

  // prepare table columns
  const columns = [
    {
      name: 'Status (Core ID)',
      sortable: false,
      cell: (row) => (
        <p>
          {row.status} <br />
          <small>Core ID: {row.coreId}</small>
        </p>
      ),
    },

    {
      name: 'Dates',
      sortable: false,
      cell: (row) => (
        <p>
          Start Date: {timestamp.unixToDate(row.startDate)} <br />
          Renewal Due: {timestamp.unixToDate(row.endDate)}
        </p>
      ),
    },

    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          <CircleNavLink route={`/client/manage-users/${row.id}`} icon="people" tooltip="Manage Users" />
          <CircleNavLink route={`/client/manage-projects/${row.id}`} icon="corporate_fare" tooltip="Manage Projects" className="mh4" />
          <CircleNavLink route={`/client/subscription-details/${row.id}`} icon="developer_board" tooltip="Details" />
        </span>
      ),
    },
  ];

  return (
    <>
      <DataTable loading={loading} columns={columns} data={subs} />
    </>
  );
};

export default SubscriptionList;
