import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import TextInput from '../../ui/TextInput';
import Submit from '../../ui/Submit';
import redirect from '../../utilities/redirect';
import Breadcrumb from '../../ui/Breadcrumb';
import Clearfix from '../../ui/Clearfix';
import Text from '../../ui/Text';
import TextArea from '../../ui/TextArea';
import ClickButton from '../../ui/ClickButton';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const [reload, setReload] = useState(true);
  const [form, setForm] = useState({});

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);

      const response = await __.get(`/projects?id=${localStorage.getItem('project')}`);
      console.log('response', response);
      if (response.status === 200) {
        __.hideLoadingBar(dispatch);
        setForm(response.data[0]);
      } else {
        __.displayErrorMessage(response.message);
      }
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage(err.response.data.message);
    }
  }, [reload]);

  const resetToken = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);

      const response = await __.patch(`/projects/token`, { id: form.id });

      if (response.status === 200) {
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('Your API token has been reset!');
        setReload(!reload);
      }
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage(err.response.data.message);
    }
  };

  return (
    <>
      <Panel className="col s12 mv4">
        <Heading className="left">API Access</Heading>
        <Clearfix />
        <Text>
          You can carry out the actions below through the Core API. The API documentation is available in a separate document.
          <br />
          1. Add a new lead <br />
          2. Submit a form
        </Text>
      </Panel>
      <Panel className="col s12 mv3">
        <Heading className="left">Project ID & API URL</Heading>
        <Clearfix />
        <Text>
          Your project ID is a unique number assigned to your project. Some of the calls you make may require you to provide the project ID in your request. <br />
          Project ID: {localStorage.getItem('project')} <br />
          API URL: {process.env.REACT_APP_API_URL}
        </Text>
        <Clearfix />
      </Panel>
      <Panel className="col s12 mv3">
        <Heading className="left">API Token</Heading>
        <Clearfix />
        <Text>
          Use this token in combination with your project ID to make API calls. If you reset your API key, you will need to update them wherever you have used them in your applications that connect to
          the Core.
        </Text>
        <TextArea label="API Token" className="col s12" state={form} isRequired id="token" setState={setForm} value={form.token} disabled />
        <ClickButton
          onClick={(e) => {
            resetToken(e);
          }}
          className="right mb4"
        >
          Reset API Token
        </ClickButton>
        <Clearfix />
      </Panel>
    </>
  );
};

export default ChangePassword;
