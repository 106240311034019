import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../../../utilities/functions';
import DataTable from '../../../../ui/DataTable';
import CircleLink from '../../../../ui/CircleLink';
import CircleNavLink from '../../../../ui/CircleNavLink';

const GroupItems = ({ group }) => {
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/inv/items?group=${group}`);
      if (response.status === 200) {
        setLoading(false);
        setList(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const deleteItem = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        await __.delete(`/inv/items?id=${id}`);
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('Deleted successfully');
        setReload(!reload);
      } catch (err) {
        __.displayErrorMessage(err.response.data.message || err);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    };

    __.confirmDelete(deleteFn);
  };

  const columns = [
    {
      name: 'Item',
      cell: (row) => (
        <p>
          {row.item} <br /> ID: {row.id}
        </p>
      ),
    },
    {
      name: 'Price',
      cell: (row) => {
        let availability;
        switch (row.status) {
          case 0:
            availability = 'Available';
            break;

          case 1:
            availability = 'Hold';
            break;

          default:
            availability = 'Sold';
        }
        return (
          <>
            <p>
              {__.cad(row.cost)} <br />
              Status: {availability}
            </p>
          </>
        );
      },
    },

    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          <CircleNavLink route={`/modules/inv/edit-item/${group}/${row.id}`} icon="edit" tooltip="Edit" />
          <CircleNavLink route={`/modules/inv/item-details/${row.id}`} icon="developer_board" tooltip="Details" className="mh4" />
          <CircleLink onClick={() => deleteItem(row.id)} icon="delete" tooltip="Delete" />
        </span>
      ),
    },
  ];

  return <DataTable loading={loading} data={list} columns={columns} filters={['item', 'cost']} />;
};

export default GroupItems;
