import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import timestamp from '../../utilities/timestamp';
import CircleLink from '../../ui/CircleLink';
import Clearfix from '../../ui/Clearfix';
import Divider from '../../ui/Divider';
import Button from '../../ui/Button';

const Submissions = ({ scope, scopeId, showProfileButton = false }) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [reload, setReload] = useState(true);
  let getUrl = '';
  if (scope === 'form') {
    getUrl = `/com/submissions?form=${scopeId}`;
  }
  if (scope === 'project') {
    getUrl = `/com/submissions?project=${scopeId}`;
  }
  if (scope === 'lead') {
    getUrl = `/com/submissions?leadId=${scopeId}`;
  }

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(getUrl);
      if (response.status === 200) {
        setList(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const deleteSubmission = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        await __.patch(`/com/submissions`, {
          status: 'inactive',
          id,
        });
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('Deleted successfully');
        setReload(!reload);
      } catch (err) {
        __.displayErrorMessage(err.response.data.message || err);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    };

    __.confirmDelete(deleteFn);
  };

  return (
    <>
      {list.map((entry, i) => (
        <div className="col s12" key={i}>
          <p className="left">
            <b>Sender: {entry.email}</b>
          </p>
          <p className="right">
            {timestamp.unixToDateTime(entry.dateTime)} <CircleLink onClick={() => deleteSubmission(entry.id)} icon="delete" tooltip="Delete" className="ml3" />
          </p>
          <Clearfix />
          <div>
            {Object.keys(entry.formData).map((val, j) => (
              <span key={j}>
                <b>{val}:</b> {entry.formData[val]} <br />
              </span>
            ))}
          </div>
          <div>
            <p>Form: {entry.formDetails.label}</p>
          </div>
          {showProfileButton && entry.leadId > 0 ? (
            <Button route={`/modules/com/lead-details/${entry.leadId}`} position="right">
              View Lead Profile
            </Button>
          ) : null}

          <Clearfix />
          <Divider className="mb4" />
        </div>
      ))}
    </>
  );
};

export default Submissions;
