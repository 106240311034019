import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import DataTable from '../../../ui/DataTable';
import Text from '../../../ui/Text';
import GroupItems from './components/GroupItems';
import SubHeading from '../../../ui/SubHeading';
import DummyText from '../../../ui/DummyText';
import DateRange from '../../../ui/DateRange';
import timestamp from '../../../utilities/timestamp';
import ClickButton from '../../../ui/ClickButton';

const HottestItems = () => {
  const dispatch = useDispatch();
  const [from, setFrom] = useState(timestamp.now() - 600000);
  const [to, setTo] = useState(timestamp.now());
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const getHottestItems = async () => {
    try {
      __.showLoadingBar(dispatch);
      setLoading(true);
      const response = await __.get(`/inv/items/hottest?project=${localStorage.getItem('project')}&dateFrom=${from}&dateTo=${to}`);
      if (response.status === 200) {
        setLoading(false);
        setItems(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const columns = [
    {
      name: 'Inventory',
      cell: (row) => <p>{row.name}</p>,
    },

    {
      name: 'Engagements',
      cell: (row) => <p>{row.count}</p>,
    },

    {
      name: 'Cost',
      cell: (row) => <p>{__.cad(row.price)}</p>,
    },
  ];
  return (
    <>
      <Panel className="col s12 l6 mv3">
        <Heading className="left">Hottest Items</Heading>
        <Clearfix />
        <Text>Here you have access to realtime data on your inventory and how they have performed over different periods of time.</Text>
        <Text>To know what inventory items customers have engaged the most for a time frame, select the timeframe below and then generate the report.</Text>
        <Text>Generating the report may take several minutes depending on the size of your current inventory.</Text>
        <ClickButton onClick={() => getHottestItems()} tooltip="Generate report" className="mb4 right">
          Generate Report
        </ClickButton>
        <Clearfix />
      </Panel>

      <Panel className="col s12 l6 mv3 pl4">
        <DateRange setFromProp={setFrom} setToProp={setTo} />
      </Panel>

      <Panel className="col s12 mv3">
        <DataTable loading={loading} columns={columns} data={items} />
      </Panel>
    </>
  );
};

export default HottestItems;
