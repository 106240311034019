import PropTypes from 'prop-types';

function Spacer({ children, className }) {
  return <p className={className}>{children}</p>;
}

Text.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default Spacer;
