import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';
import { locale } from 'moment/moment';
import __ from '../../utilities/functions';
import getLeadDetails from '../../utilities/getLeadDetails';

const LeadScore = ({ lead }) => {
  //get details of the lead
  const dispatch = useDispatch();

  const [details, setDetails] = useState({ data: [] });
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await getLeadDetails(lead);

      setDetails(response);
    } catch (err) {
      //__.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  // get details of the lead score
  const [scoreDetails, setScoreDetails] = useState({});
  useEffect(async () => {
    if (details.scoring) {
      try {
        __.showLoadingBar(dispatch);
        const response = await __.get(`/com/scoring?id=${details.scoring}`);
        if (response.status === 200) {
          setScoreDetails(response.data[0]);
        }
      } catch (err) {
        //__.displayErrorMessage(err.response.data.message);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    }
  }, [details]);

  if (details.scoring > 0) {
    return (
      <p>
        Lead Score:{' '}
        <i className="material-icons" style={{ color: scoreDetails?.color }}>
          star
        </i>{' '}
        {scoreDetails?.score}
      </p>
    );
  }

  return <p>Lead Score: None</p>;
};

export default LeadScore;
