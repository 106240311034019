/**
 * this component can be used for both customer and lead profiles
 * to list out inventory engagements tied to these accounts
 * typeIs is the id of the lead or customer
 * tagType is either lead or customer
 */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DataTable from '../../../ui/DataTable';
import __ from '../../../utilities/functions';
import timestamp from '../../../utilities/timestamp';
import CircleLink from '../../../ui/CircleLink';
import CircleAnchorLink from '../../../ui/CircleAnchorLink';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Button from '../../../ui/Button';
import DummyText from '../../../ui/DummyText';
import FolderIcon from '../../../ui/FolderIcon';
import Text from '../../../ui/Text';
import Spacer from '../../../ui/Spacer';
import Breadcrumb from '../../../ui/Breadcrumb';

const Assets = ({ typeId, tagType }) => {
  const dispatch = useDispatch();
  const [reload, setReload] = useState(true);

  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState([]);
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/asm/folders?project=${localStorage.getItem('project')}`);
      setLoading(false);
      setAssets(response.data);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const deleteAsset = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        await __.delete(`/asm/assets?id=${id}`);
        __.displaySuccessMessage('Asset successfully deleted');
        setReload(!reload);
      } catch (err) {
        __.displayErrorMessage(err.response.data.message || err);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    };

    __.confirmDelete(deleteFn);
  };

  const assetColumns = [
    {
      name: 'File Label',
      cell: (row) => (
        <p>
          {row.folder} <br /> Uploaded: {timestamp.unixToDate(row.dateTime)}
        </p>
      ),
    },
    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          <CircleAnchorLink icon="cloud_download" tooltip="Download" className="mh3" />
          <CircleLink onClick={() => deleteAsset(row.id)} icon="delete" tooltip="Delete" />
        </span>
      ),
    },
  ];

  return (
    <>
      <Panel className="col s12 mv3 pb4">
        <Heading className="left">Digital Assets</Heading>
        <Button route="/modules/asm/folders" position="right" className="mv3">
          Manage Folders
        </Button>
        <Clearfix />
        <Text>
          Asset Management allow you to organize your multi media & digital assets with universal team access for each project. Assets within the project library can also be assigned to inventory,
          customers and leads, ensuring that the proper documents are in the right place through the presale & releasing process.
        </Text>
        <Spacer className="mv5" />

        {assets.map((folder) => (
          <FolderIcon route={`/modules/asm/files/${folder.id}`} icon="folder_open" title={folder.folder}>
            {folder.folder}
          </FolderIcon>
        ))}
        <Clearfix />
        <Spacer className="mv5" />
      </Panel>
    </>
  );
};

export default Assets;
