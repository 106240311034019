import { useEffect } from 'react';
import M from 'materialize-css';
import $ from 'jquery';
import timestamp from '../utilities/timestamp';

const Datepicker = ({ state, setState, id, label, className }) => {
  useEffect(() => {
    $('.datepickerLabel').addClass('active');
    M.Datepicker.init(document.querySelectorAll(`#${id}`), {
      onClose: () => {
        setState({ ...state, [id]: timestamp.dateToUnix($(`#${id}`).val(), 'MMMM DD, YYYY') });
      },
    });
  }, [state]); // added state as dependency to fix form fields disappearing when date is changed. noticed in admins > edit invoices

  return (
    <div className={`input-field ${className}`}>
      <input type="text" className="datepicker inp" id={id} value={timestamp.convert(state[id])} onChange={() => {}} />
      <label htmlFor={id} className="datepickerLabel">
        {label}
      </label>
    </div>
  );
};

export default Datepicker;
