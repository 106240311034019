import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';

const CodeEditor = ({ state, setState, id, className, language = 'html' }) => (
  <div className={`input-field ${className}`}>
    <AceEditor
      mode={language}
      theme="monokai"
      name="blah2"
      onChange={(code) => {
        setState({ ...state, [id]: code });
      }}
      fontSize={14}
      showPrintMargin
      showGutter
      highlightActiveLine
      value={state[id]}
      width="100%"
      setOptions={{
        enableBasicAutocompletion: false,
        enableLiveAutocompletion: false,
        enableSnippets: false,
        showLineNumbers: true,
        tabSize: 2,
      }}
    />
  </div>
);

export default CodeEditor;
