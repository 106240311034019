const menu = [
  {
    id: 'billing',
    icon: 'attach_money',
    name: 'Core Config',
    subMenu: [
      { route: '/client/dashboard', name: 'Dashboard' },
      { route: '/client/subscriptions', name: 'Subscriptions' },
      { route: '/client/invoices', name: 'Invoices' },
      { route: '/client/transactions', name: 'Transactions' },
    ],
  },
  {
    id: 'account',
    icon: 'settings',
    name: 'Account',
    subMenu: [
      { route: '/client/change-password', name: 'Change Password' },
      { route: '/logout', name: 'Logout' },
    ],
  },
];

export default menu;
