import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import __ from '../../utilities/functions';
import Clearfix from '../../ui/Clearfix';
import timestamp from '../../utilities/timestamp';
import Heading from '../../ui/Heading';
import ClickButton from '../../ui/ClickButton';

const InvoiceBreakdown = ({ id }) => {
  const [details, setDetails] = useState({});
  const [subDetails, setSubDetails] = useState({});
  const [clientDetails, setClientDetails] = useState({});
  const [reload, setReload] = useState(true);
  const dispatch = useDispatch();

  // invoice details
  useEffect(async () => {
    __.showLoadingBar(dispatch);
    try {
      const response = await __.get(`/invoices?id=${id}`);
      if (response.status === 200) {
        // if invoice does not exist
        if (response.data.length === 0) {
          __.displayInfoMessage('The invoice does not exist');
          __.redirect(dispatch, '/');
        }
        setDetails(response.data[0]);
      }
    } catch (err) {
      __.displayErrorMessage(err?.response?.data?.message || err);
    } finally {
      // __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  // subscription details
  useEffect(async () => {
    // __.showLoadingBar(dispatch);
    try {
      if (details.subscription) {
        const response = await __.get(`/subscriptions?id=${details.subscription}`);
        if (response.status === 200) {
          setSubDetails(response.data[0]);
        }
      }
    } catch (err) {
      __.displayErrorMessage(err?.response?.data?.message || err);
    } finally {
      // __.hideLoadingBar(dispatch);
    }
  }, [details]);

  // client details
  useEffect(async () => {
    // __.showLoadingBar(dispatch);
    try {
      if (subDetails?.client) {
        const response = await __.get(`/clients?id=${subDetails?.client}`);
        if (response.status === 200) {
          setClientDetails(response.data[0]);
        }
      }
    } catch (err) {
      __.displayErrorMessage(err?.response?.data?.message || err);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [subDetails]);

  const markInvoicePaid = async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.patch('/invoices/status', {
        id,
        status: 'paid',
      });
      if (response.status === 204) {
        // set subscription to active
        await __.patch('/subscriptions/status', {
          id: details.subscription,
          status: 'active',
        });

        // create a transaction log
        await __.post('/transactions', {
          description: `Invoice payment for invoice ID ${id}`,
          transactionRef: id,
          client: clientDetails.id,
          amount: details.amount,
        });
        __.displaySuccessMessage('Invoice successfully marked as paid');
        setReload(!reload);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  return (
    <div>
      <div className="left">
        <p>
          For: {clientDetails.firstname} {clientDetails.lastname}
        </p>
        <p>{clientDetails.email}</p>
        <p>{clientDetails.phone}</p>
      </div>
      <div className="right">
        <p>Status: {_.upperFirst(details.status)}</p>
        <p>Due: {timestamp.convert(details.dueDate)}</p>
      </div>
      <Clearfix />
      <div>
        <Heading className="tc">Invoice (ID: {id})</Heading>
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th className="right">Amount</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>{details.description}</td>
              <td className="right">{__.cad(details.amount)}</td>
            </tr>
            <tr>
              <td>
                <b>Total</b>
              </td>
              <td className="right">
                <b>{__.cad(details.amount)}</b>
              </td>
            </tr>
          </tbody>
        </table>
        {details.status === 'unpaid' ? (
          <ClickButton onClick={() => markInvoicePaid()} className="right mv4">
            Set as Paid
          </ClickButton>
        ) : null}
      </div>
    </div>
  );
};

export default InvoiceBreakdown;
