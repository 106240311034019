import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import __ from '../../utilities/functions';
import SubHeading from '../../ui/SubHeading';
import DataTable from '../../ui/DataTable';
import timestamp from '../../utilities/timestamp';
import CircleNavLink from '../../ui/CircleNavLink';
import CircleLink from '../../ui/CircleLink';
import Clearfix from '../../ui/Clearfix';
import InvoiceList from '../components/InvoiceList';
import TransactionList from '../components/TransactionList';

const Dashboard = () => {
  const state = useSelector((store) => store);

  return (
    <div>
      <Panel className="col s12 mv3">
        <Heading className="left">Unpaid Invoices</Heading>
        <InvoiceList client={state.userId} />
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">Recent Transactions</Heading>
        <TransactionList client={state.userId} />
      </Panel>
    </div>
  );
};
export default Dashboard;
