import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import __ from '../../utilities/functions';
import DataTable from '../../ui/DataTable';
import CircleNavLink from '../../ui/CircleNavLink';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';
import ClientSubscriptionList from '../../admins/components/ClientSubscriptionList';
import SubHeading from '../../ui/SubHeading';
import Clearfix from '../../ui/Clearfix';
import InvoiceList from '../components/InvoiceList';

const Invoices = () => {
  const state = useSelector((store) => store);

  return (
    <Panel className="col s12 mv3">
      <Heading className="left">My Invoices</Heading>
      <Clearfix />
      <InvoiceList client={state.userId} />
    </Panel>
  );
};

export default Invoices;
