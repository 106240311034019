import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import SelectMenu from '../../../ui/SelectMenu';
import FileUpload from '../../../ui/FileUpload';
import Breadcrumb from '../../../ui/Breadcrumb';

const AddForm = () => {
  const [form, setForm] = useState({ project: localStorage.getItem('project') });
  const dispatch = useDispatch();

  const addForm = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const response = await __.post(`/com/forms`, form);
      if (response.status === 200) {
        __.displaySuccessMessage('Form added!');
        __.redirect(dispatch, '/modules/com/forms');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Forms', route: '/modules/com/forms' },
    { label: 'Add Form', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Add Form</Heading>
        <Clearfix />
        <form onSubmit={(e) => addForm(e)}>
          <TextInput label="Form Name" className="col s12 l4" state={form} isRequired id="label" setState={setForm} type="text" value={form.label} />
          <TextInput label="Notify SMS" className="col s12 l4" state={form} isRequired id="notifySms" setState={setForm} type="text" value={form.notifySms} />
          <TextInput label="Notify Emails" className="col s12 l4" state={form} isRequired id="notifyEmail" setState={setForm} type="text" value={form.notifyEmail} />
          <Submit className="col s12">Add Form</Submit>
        </form>
      </Panel>
    </>
  );
};

export default AddForm;
