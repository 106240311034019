import PropTypes from "prop-types";

function Heading({ children, className, icon }) {
  if (icon) {
    return (
      <h1 className={`proxima-light f3 ${className}`}>
        <i className="material-icons left">{icon}</i> {children}
      </h1>
    );
  }

  return <h1 className={`proxima-light f3 ${className}`}>{children}</h1>;
}

Heading.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
};

export default Heading;
