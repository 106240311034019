import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import Breadcrumb from '../../../ui/Breadcrumb';

const EditTemplate = () => {
  const { id } = useParams();
  const [form, setForm] = useState({ id });
  const dispatch = useDispatch();

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/inv/templates?id=${id}`);
      if (response.status === 200) {
        setForm({ ...response.data[0], fieldstring: response.data[0].fields.join(',') });
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const editTemplate = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const formFields = form.fieldstring.split(',').map((field) => field.trim().replace(/(\s*)("*)('*)/g, ''));

      // check to ensure a comma separated list is provided
      if (formFields.length === 0) {
        throw new Error('You need to provide a comma-separated list of fields');
      }

      // check to ensure the keys item and cost are present
      if (!_.includes(formFields, 'item') || !_.includes(formFields, 'cost')) {
        throw new Error('Your keys have to contain item and cost');
      }

      const response = await __.patch(`/inv/templates`, { ...form, fields: formFields });
      if (response.status === 204) {
        __.displaySuccessMessage('Changes saved');
        __.redirect(dispatch, '/modules/inv/templates');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Templates', route: '/modules/inv/templates' },
    { label: 'Edit Template', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Edit Template ({form.name})</Heading>
        <Clearfix />
        <form onSubmit={(e) => editTemplate(e)}>
          <TextInput label="Template Name" className="col s12 l3" state={form} isRequired id="name" setState={setForm} type="text" value={form.name} />
          <TextInput label="Fields" className="col s12 l9" state={form} isRequired id="fieldstring" setState={setForm} type="text" value={form.fieldstring} />
          <Submit className="col s12">Save Changes</Submit>
        </form>
      </Panel>
    </>
  );
};

export default EditTemplate;
