import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import adminMenu from '../admins/components/AdminMenu';
import clientMenu from '../clients/components/ClientMenu';
import userMenu from '../users/components/UserMenu';
import SideMenu from './SideMenu';
import logo from '../assets/images/logo.png';
import Text from '../ui/Text';
import Clearfix from '../ui/Clearfix';

function SidePanel() {
  const state = useSelector((store) => store);
  const [menu, setMenu] = useState([]);

  const buildUserMenu = (originalMenu, userModules) => {
    const newMenu = [];
    newMenu.push(originalMenu.accountMenu);

    userModules?.map((module) => newMenu.push(originalMenu[module.toLowerCase()]));

    return newMenu;
  };

  // ddh

  useEffect(() => {
    if (state.loggedIn) {
      if (state.userType === 'admins') {
        setMenu(adminMenu);
      }
      if (state.userType === 'clients') {
        setMenu(clientMenu);
      }

      if (state.userType === 'users') {
        // logic to bring up menu based on usergroup permissions

        setMenu(userMenu);
      }
    }
  }, [state.loggedIn, state.userModules]);

  return (
    <>
      <div className="col m12 l12 xl12 logo_box">
        <div className="logo mv5">
          <img src={logo} alt="logo" className="logo_pst" />
        </div>
      </div>
      {state.loggedIn ? (
        <>
          <div className="col s12">
            <Text className="light_blue_text tc f3 b">Welcome, {localStorage.getItem('firstname')}!</Text>
          </div>
          <div className="col m12 l12 xl12 dashboard" style={{ overflowY: 'scroll', height: '500px' }}>
            <SideMenu menu={menu} />
          </div>
        </>
      ) : null}
    </>
  );
}

export default SidePanel;
