/*eslint-disable*/
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';
import { locale } from 'moment/moment';
import __ from '../../../utilities/functions';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import SubHeading from '../../../ui/SubHeading';
import getCustomerDetails from '../../../utilities/getCustomerDetails';
import getLeadDetails from '../../../utilities/getLeadDetails';
import DataTable from '../../../ui/DataTable';
import Notes from '../../components/Notes';
import ModalButton from '../../../ui/ModalButton';
import Modal from '../../../ui/Modal';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import timestamp from '../../../utilities/timestamp';
import ProfileInventoryEngagements from '../../components/ProfileInventoryEngagements';
import ProfileScheduledEvents from '../../components/ProfileScheduledEvents';
import ProfileDigitalAssets from '../../components/ProfileDigitalAssets';
import Breadcrumb from '../../../ui/Breadcrumb';
import Submissions from '../../components/Submissions';

const CustomerDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  // get the customer details
  const [details, setDetails] = useState({ data: [] });
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await getCustomerDetails(id);
      setDetails(response);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const breadcrumb = [
    { label: 'Customers', route: '/modules/crm/customers' },
    { label: 'Customer Details', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Customer Details</Heading>
        <Clearfix />
        <SubHeading className="left">
          {details.firstname} {details.lastname}
        </SubHeading>
        <Clearfix />
        <Text>
          <b>Email:</b> {details.email} <br />
          <b>Phone:</b> {details.phone}
        </Text>
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">Inventory Engagements</Heading>
        <Clearfix />
        <ProfileInventoryEngagements typeId={id} tagType="customer" />
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">Scheduled Events</Heading>
        <Clearfix />
        <ProfileScheduledEvents tagType="customer" typeId={id} />
      </Panel>

      <ProfileDigitalAssets typeId={id} tagType="customer" />

      <Notes id={id} recipientType="customer" />

      {details.leadId > 0 ? (
        <Panel className="col s12 mv3">
          <Heading className="left">Form Submissions</Heading>
          <Clearfix />
          <Submissions scope="lead" scopeId={details.leadId} />
        </Panel>
      ) : null}
    </>
  );
};

export default CustomerDetails;
