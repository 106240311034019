import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';
import CircleLink from '../../ui/CircleLink';
import ClientSubscriptionList from '../components/ClientSubscriptionList';
import InvoiceList from '../components/InvoiceList';
import TransactionList from '../components/TransactionList';
import Breadcrumb from '../../ui/Breadcrumb';
import Clearfix from '../../ui/Clearfix';

const ClientDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});

  useEffect(async () => {
    __.showLoadingBar(dispatch);
    try {
      const response = await __.get(`/clients?id=${id}`);
      if (response.status === 200) {
        setDetails(response.data[0]);
      }
    } catch (err) {
      __.displayErrorMessage(`API Error: ${err}`);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const breadcrumb = [
    { label: 'Clients', route: '/admin/clients' },
    { label: 'Client Details', route: '#' },
  ];

  const deleteClient = () => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        const response = await __.delete(`/clients?id=${id}`);
        if (response.status === 204) {
          __.displaySuccessMessage('Deleted!');
          __.redirect(dispatch, '/admin/clients');
        }
      } catch (err) {
        __.displayErrorMessage(err.response.data.message || err);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    };

    __.confirmDelete(deleteFn);
  };

  return (
    <div>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Client Details</Heading>
        <CircleLink icon="delete" onClick={() => deleteClient()} tooltip="Delete" className="right mv3" />

        <Button route={`/admin/edit-client/${id}`} position="right" className="right mv3">
          Edit
        </Button>
        <Clearfix />
        <p className="no-margin-top">
          {details.firstname} {details.lastname} <br />
          {details.company} <br />
          {details.email} <br />
          {details.phone}
        </p>
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">Subscriptions</Heading>
        <Clearfix />

        <ClientSubscriptionList client={id} />
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">Invoices</Heading>
        <Clearfix />

        <InvoiceList client={id} />
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">Transaction History</Heading>
        <Clearfix />

        <TransactionList client={id} />
      </Panel>
    </div>
  );
};

export default ClientDetails;
