import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Calendar } from 'react-calendar';
import { GoogleLogin } from 'react-google-login';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import DataTable from '../../../ui/DataTable';
import Text from '../../../ui/Text';
import DummyText from '../../../ui/DummyText';
import Spacer from '../../../ui/Spacer';
import timestamp from '../../../utilities/timestamp';
import SubHeading from '../../../ui/SubHeading';
import ClickButton from '../../../ui/ClickButton';

const Events = () => {
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [day, setDay] = useState(timestamp.now());

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/sch/events?project=${localStorage.getItem('project')}&day=${day}`);
      if (response.status === 200) {
        setLoading(false);
        setList(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload, day]);

  const deleteEvent = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        await __.delete(`/sch/events?id=${id}`);
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('Deleted successfully');
        setReload(!reload);
      } catch (err) {
        __.displayErrorMessage(err.response.data.message || err);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    };

    __.confirmDelete(deleteFn);
  };

  const columns = [
    {
      name: `Events for ${timestamp.unixToDate(day)}`,
      cell: (row) => (
        <p>
          {row.event} ({timestamp.unixToTimeNoSeconds(row.startDateTime)}) <br /> {row.description}
        </p>
      ),
    },

    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          <CircleNavLink route={`/modules/sch/event-details/${row.id}`} icon="developer_board" tooltip="Details" />
          <CircleNavLink route={`/modules/sch/edit-event/${row.id}`} icon="edit" tooltip="Edit" className="mh4" />
          <CircleLink onClick={() => deleteEvent(row.id)} icon="delete" tooltip="Delete" />
        </span>
      ),
    },
  ];

  const syncGoogleCalendar = async (authCode) => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.post(`/services/google/calendarsync`, { project: localStorage.getItem('project'), authCode });
      if (response.status === 204) {
        __.displaySuccessMessage('Google Calendar sync completed successfully!');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  return (
    <>
      <Panel className="col s12 mv3">
        <Heading className="left">Scheduled Events</Heading>
        <Button route="/modules/sch/add-event" position="right" className="mv3">
          Add Event
        </Button>

        <GoogleLogin
          clientId="730813801970-e6sj4ne4njp58js4fnievavsv2u019n0.apps.googleusercontent.com"
          buttonText="Login"
          onSuccess={(response) => syncGoogleCalendar(response.code)}
          onFailure={(response) => {
            console.log('failed oauth', response);
          }}
          cookiePolicy="single_host_origin"
          scope="https://www.googleapis.com/auth/calendar"
          render={(renderProps) => (
            <ClickButton hreg="#" className="mv3 right" onClick={renderProps.onClick}>
              Sync Google Calendar
            </ClickButton>
          )}
          responseType="code"
        />
        <Clearfix />
        <Text>
          The Schedule Management System allows online leads to book guided remote tour appointments, on-site viewings, or meetings directly on-line or can be used to schedule, confirm and manage
          appointments requests through your website.
        </Text>
        <Text>Click on a date to see the events for that day.</Text>

        <Calendar
          onViewChange={() => {}}
          minDetail="year"
          onClickDay={(value) => {
            setDay(timestamp.dateToUnix(value, 'YYYY-MM-DDTHH:mm:ss. sssZ'));
          }}
        />
        <Spacer className="mv5" />
        <DataTable loading={loading} columns={columns} data={list} />
      </Panel>
    </>
  );
};

export default Events;
