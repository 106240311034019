import { useEffect, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import __ from '../../utilities/functions';
import CircleLink from '../../ui/CircleLink';
import CircleNavLink from '../../ui/CircleNavLink';
import DataTable from '../../ui/DataTable';
import timestamp from '../../utilities/timestamp';

const InvoiceList = ({ client = null }) => {
  const dispatch = useDispatch();
  const [reload, setReload] = useState(true);
  const [list, setList] = useState([]);
  const [loading, updateLoading] = useState(true);

  // get the invoices list
  useEffect(async () => {
    __.showLoadingBar(dispatch);
    try {
      const response = await __.get(`/invoices/client?id=${client}`);

      if (response.status === 200) {
        setList(response.data);
        __.hideLoadingBar(dispatch);
        updateLoading(false);
      } else {
        throw Error('Cannot get list');
      }
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage(`API Error: ${err}`);
    }
  }, [reload, client]);

  const columns = [
    {
      name: 'Invoice ID',
      sortable: false,
      cell: (row) => (
        <p>
          {row.id} ({row.status})
        </p>
      ),
    },

    {
      name: 'Dates',
      sortable: false,
      cell: (row) => (
        <p>
          Due Date: {timestamp.unixToDate(row.dueDate)}
          <br /> Date Created: {timestamp.unixToDate(row.dateCreated)}
        </p>
      ),
    },

    {
      name: 'Amount',
      sortable: false,
      cell: (row) => <p>${row.amount}</p>,
    },

    {
      name: 'Description',
      sortable: false,
      cell: (row) => <p>{row.description}</p>,
    },

    {
      name: '',
      sortable: false,
      right: true,
      cell: (row) => (
        <>
          <CircleNavLink route={`/client/invoice-details/${row.id}`} icon="developer_board" tooltip="Details" className="mh4" />
        </>
      ),
    },
  ];

  return (
    <>
      <DataTable loading={loading} columns={columns} data={list} />
    </>
  );
};

export default InvoiceList;
