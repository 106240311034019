/**
 * this component can be used for both customer and lead profiles
 * to list out inventory engagements tied to these accounts
 * typeIs is the id of the lead or customer
 * tagType is either lead or customer
 */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';
import DataTable from '../../../ui/DataTable';
import __ from '../../../utilities/functions';
import timestamp from '../../../utilities/timestamp';
import CircleLink from '../../../ui/CircleLink';
import CircleAnchorLink from '../../../ui/CircleAnchorLink';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Button from '../../../ui/Button';
import config from '../../../utilities/config';
import Breadcrumb from '../../../ui/Breadcrumb';
import Text from '../../../ui/Text';

const Files = () => {
  const { folder } = useParams();
  const dispatch = useDispatch();
  const [reload, setReload] = useState(true);

  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState([]);

  // get folder details like name etc
  const [folderDetails, setFolderDetails] = useState({});
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/asm/folders?id=${folder}`);
      setLoading(false);
      setFolderDetails(response.data[0]);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  // get all the files of a folder
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/asm/assets?folder=${folder}`);
      setLoading(false);
      setAssets(response.data);
      console.log(response.data);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const deleteAsset = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        await __.delete(`/asm/assets?id=${id}`);
        __.displaySuccessMessage('Asset successfully deleted');
        setReload(!reload);
      } catch (err) {
        __.displayErrorMessage(err.response.data.message || err);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    };

    __.confirmDelete(deleteFn);
  };

  const downloadAsset = async (id, filename) => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/asm/assets/download?id=${id}`);
      __.download({ filename, contentType: 'application/octet-stream', content: response.data });
      setReload(!reload);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message || err);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const assetColumns = [
    {
      name: 'File Label',
      cell: (row) => (
        <p>
          {row.label} <br /> Uploaded: {timestamp.unixToDate(row.dateTime)} (ID: {row.id}){/*{row.access === 'public' ? (*/}
          {/*  <>*/}
          {/*    <br /> Download Link: {`${config.publicStorageUrl}${row.source}`}*/}
          {/*  </>*/}
          {/*) : null}*/}
        </p>
      ),
    },
    {
      name: 'Access Type',
      cell: (row) => <p>{_.upperFirst(row.access)}</p>,
    },
    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          {row.access === 'public' ? (
            <CircleAnchorLink icon="cloud_download" tooltip="Download" className="mh3" href={`${config.publicStorageUrl}${row.source}`} target="_blank" />
          ) : (
            <CircleLink onClick={() => downloadAsset(row.id, row.source)} icon="cloud_download" tooltip="Download" className="mh3" />
          )}
          <CircleLink onClick={() => deleteAsset(row.id)} icon="delete" tooltip="Delete" />
        </span>
      ),
    },
  ];
  const breadcrumb = [
    { label: 'Assets', route: '/modules/asm/assets' },
    { label: 'Files', route: '#' },
  ];
  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />

      <Panel className="col s12 mv3">
        <Heading className="left">Files (Folder: {folderDetails.folder})</Heading>
        <Button route={`/modules/asm/upload-asset/${folder}`} position="right" className="mv3">
          Upload File
        </Button>
        <Clearfix />
        <Text>
          Upload any digital files to folders simply by clicking “upload file”, and it will automatically place it within the folder you're in. It is important to remember that files loaded within
          digital assets can also be attached to inventory, customers and leads, so it is important to keep your files organized in a way that is easy to find from those modules.
        </Text>
        <DataTable data={assets} loading={loading} columns={assetColumns} />
        <Clearfix />
      </Panel>
    </>
  );
};

export default Files;
