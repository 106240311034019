import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import DataTable from '../../../ui/DataTable';
import Text from '../../../ui/Text';
import DummyText from '../../../ui/DummyText';

const Scoring = () => {
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/com/scoring?project=${localStorage.getItem('project')}`);
      if (response.status === 200) {
        setLoading(false);
        setList(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const deleteScoring = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        await __.delete(`/com/scoring?id=${id}`);
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('Deleted successfully');
        setReload(!reload);
      } catch (err) {
        __.displayErrorMessage(err.response.data.message || err);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    };

    __.confirmDelete(deleteFn);
  };

  const columns = [
    {
      name: 'Scoring Label',
      cell: (row) => (
        <p>
          <i className="material-icons pr2" style={{ color: row.color }}>
            star
          </i>
          {row.label} (Score: {row.score})
        </p>
      ),
    },
    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          <CircleNavLink route={`/modules/com/scoring-leads/${row.id}`} icon="fact_check" tooltip="Show Leads" />
          <CircleNavLink route={`/modules/com/edit-scoring/${row.id}`} icon="edit" tooltip="Edit" className="mh4" />
          <CircleLink onClick={() => deleteScoring(row.id)} icon="delete" tooltip="Delete" />
        </span>
      ),
    },
  ];

  return (
    <>
      <Panel className="col s12 mv3">
        <Heading className="left">Scoring</Heading>
        <Button route="/modules/com/add-scoring" position="right" className="mv3">
          Add Scoring
        </Button>
        <Clearfix />
        <Text>
          The Lead scoring section allows you to customize your leads rankings and activity by score and color, making urgent leads easily visible by your entire team in their individual accounts.
          This section can also be used to label and group your lead management.
        </Text>
        <DataTable loading={loading} columns={columns} data={list} />
      </Panel>
    </>
  );
};

export default Scoring;
