import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import DataTable from '../../../ui/DataTable';
import TextInput from '../../../ui/TextInput';
import CodeEditor from '../../../ui/CodeEditor';
import Submit from '../../../ui/Submit';
import SelectMenu from '../../../ui/SelectMenu';
import FileUpload from '../../../ui/FileUpload';
import Breadcrumb from '../../../ui/Breadcrumb';
import config from '../../../utilities/config';

const AddSection = () => {
  const [loading, updateLoading] = useState(true);
  const [form, setForm] = useState({});
  const [categories, setCategories] = useState([]);

  const state = useSelector((store) => store);
  const dispatch = useDispatch();

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/bms/categories?project=${localStorage.getItem('project')}`);
      if (response.status === 200) {
        if (response.data.length === 0) {
          __.displayInfoMessage('To add a video, please add at least one video category');
          __.redirect(dispatch, '/modules/bms/categories');
        }
        setCategories(response.data);
      }
    } catch (err) {
      __.displayErrorMessage('Cannot fetch categories');
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const addVideo = async (e) => {
    e.preventDefault();

    // call s3-upload api first to upload file. and get source
    try {
      __.showLoadingBar(dispatch);
      const uploadData = new FormData();
      if (e.target.video) {
        uploadData.append('videoFile', e.target.video.files[0]);
      }
      if (e.target.cover) {
        uploadData.append('coverFile', e.target.cover.files[0]);
      }
      uploadData.append('title', form.title);
      uploadData.append('category', form.category);
      const response = await __.post('/bms/videos', uploadData);
      if (response.status === 200) {
        __.displaySuccessMessage('Upload successful!');
        __.redirect(dispatch, '/modules/bms/videos');
      }
    } catch (err) {
      __.displayErrorMessage(err?.response?.data?.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Videos', route: '/modules/bms/videos' },
    { label: 'Add Video', route: '#' },
  ];
  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />

      <Panel className="col s12 mv3">
        <Heading className="left">Upload Video</Heading>
        <Clearfix />
        <form onSubmit={(e) => addVideo(e)}>
          <TextInput label="Video Title" className="col s12 l8" state={form} isRequired id="title" setState={setForm} type="text" value={form.title} />
          <Clearfix />
          <SelectMenu setState={setForm} id="category" state={form} label="Category" className="col s12 l4" list={categories} idKey="id" valueKey="name" />
          <Clearfix />
          <FileUpload label="Choose Video File" id="video" className="col s12 l4" />
          <FileUpload label="Choose Cover Photo File" id="cover" className="col s12 l4" />
          <Submit className="col s12">Add Video</Submit>
        </form>
      </Panel>
    </>
  );
};

export default AddSection;
