import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import __ from '../../utilities/functions';
import SelectMenu from '../../ui/SelectMenu';

const LeadsSelect = ({ className, id, state, setState }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState([]);
  const [list, setList] = useState([]);

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/com/leads?project=${localStorage.getItem('project')}`);
      if (response.status === 200) {
        setList(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  return <SelectMenu id={id} list={list} setState={setState} label="Leads" state={state} className={className} idKey="id" valueKey="firstname" defaultValue={state[id]} />;
};

export default LeadsSelect;
