import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import TextInput from '../../../ui/TextInput';
import SelectMenu from '../../../ui/SelectMenu';
import UserProjectSelect from '../../components/UserProjectSelect';
import Submit from '../../../ui/Submit';
import FileUpload from '../../../ui/FileUpload';
import __ from '../../../utilities/functions';
import config from '../../../utilities/config';

const UploadAsset = ({ setReload, reload }) => {
  const [form, setForm] = useState({ project: localStorage.getItem('project') });
  const dispatch = useDispatch();

  const assetTypeList = [
    { id: 'image', value: 'Image' },
    { id: 'video', value: 'Video' },
  ];

  const locationList = [
    { id: 'top', value: 'Top' },
    { id: 'bottom', value: 'Bottom' },
  ];

  const uploadAsset = async (e) => {
    e.preventDefault();

    // call s3-upload api first to upload file. and get source
    try {
      __.showLoadingBar(dispatch);
      const uploadData = new FormData();
      if (e.target.file) {
        uploadData.append('file', e.target.file.files[0]);
      }
      const response = await __.callApi('post', `storage`, uploadData);
      if (response.status === 200) {
        const source = response.data.filename;

        const response2 = await __.post('/cms/assets', { ...form, source });
        if (response2.status === 200) {
          __.displaySuccessMessage('Upload successful!');
          setReload(!reload);
          setForm({ project: localStorage.getItem('project') });
          // __.redirect(dispatch, '/modules/cms/assets');
        }
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  return (
    <>
      <Heading className="left">Upload Asset</Heading>
      <Clearfix />
      <Text className="pb4">You can upload images and videos and use them throughout the website.</Text>
      <form onSubmit={(e) => uploadAsset(e)} className="mb5">
        <FileUpload label="Browse" id="file" className="col s12 l2" isRequired={false} />

        <SelectMenu id="assetType" className="col s12 l3" state={form} setState={setForm} label="Asset Type" list={assetTypeList} idKey="id" valueKey="value" />
        <TextInput label="Label" className="col s12 l7" state={form} isRequired id="label" setState={setForm} type="text" value={form.label} />

        <Submit className="col s12">Upload</Submit>
      </form>
    </>
  );
};

export default UploadAsset;
