import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import DataTable from '../../ui/DataTable';
import CircleNavLink from '../../ui/CircleNavLink';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';
import ClientSubscriptionList from '../components/ClientSubscriptionList';
import SubHeading from '../../ui/SubHeading';
import Clearfix from '../../ui/Clearfix';
import InvoiceList from '../components/InvoiceList';

const Invoices = () => {
  const dispatch = useDispatch();

  // get client details
  const [clients, setClients] = useState([]);
  useEffect(async () => {
    __.showLoadingBar(dispatch);
    try {
      const response = await __.get(`/clients`);
      if (response.status === 200) {
        __.hideLoadingBar(dispatch);
        setClients(response.data);
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage(`API Error: ${err}`);
    }
  }, []);

  return (
    <Panel className="col s12 mv3">
      <Heading className="left">Invoices</Heading>
      <Button route="/admin/add-invoice" className="right mv3">
        Add Invoice
      </Button>
      <Clearfix />
      <InvoiceList />
    </Panel>
  );
};

export default Invoices;
