import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';
import CircleLink from '../../ui/CircleLink';
import ClientSubscriptionList from '../../admins/components/ClientSubscriptionList';
import InvoiceList from '../../admins/components/InvoiceList';
import TransactionList from '../../admins/components/TransactionList';
import Breadcrumb from '../../ui/Breadcrumb';
import Clearfix from '../../ui/Clearfix';
import timestamp from '../../utilities/timestamp';
import SubscriptionInvoiceList from '../../admins/components/SubscriptionInvoiceList';

const SubscriptionDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [details, setDetails] = useState({ modules: [] });

  useEffect(async () => {
    __.showLoadingBar(dispatch);
    try {
      const response = await __.get(`/subscriptions?id=${id}`);
      if (response.status === 200) {
        setDetails(response.data[0]);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const breadcrumb = [
    { label: 'Subscriptions', route: '/client/subscriptions' },
    { label: 'Subscription Details', route: '#' },
  ];

  return (
    <div>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Subscription Details</Heading>
        <Button route={`/client/manage-users/${id}`} position="right" className="mv3">
          Manage Users
        </Button>

        <Button route={`/client/manage-projects/${id}`} position="right" className="mv3">
          Manage Projects
        </Button>
        <Clearfix />
        <p className="no-margin-top">
          Core ID: {details.coreId} <br />
          Start Date: {timestamp.unixToDate(details.startDate)}, Renewal Date: {timestamp.unixToDate(details.endDate)} <br />
          Max Projects: {details.maxProjects}, Max Users: {details.maxUsers} <br />
        </p>
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">Invoices</Heading>
        <Clearfix />
        <SubscriptionInvoiceList subscription={id} />
      </Panel>
    </div>
  );
};

export default SubscriptionDetails;
