import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import SelectMenu from '../../../ui/SelectMenu';
import FileUpload from '../../../ui/FileUpload';
import Breadcrumb from '../../../ui/Breadcrumb';
import Datepicker from '../../../ui/Datepicker';
import timestamp from '../../../utilities/timestamp';
import SubHeading from '../../../ui/SubHeading';
import TimePicker from '../../../ui/TimePicker';

const AddEvent = () => {
  const [form, setForm] = useState({ project: localStorage.getItem('project') });
  const [customers, setCustomers] = useState([]);
  const [leads, setLeads] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [startDate, setStartDate] = useState({ startDate: timestamp.now() });
  const [endDate, setEndDate] = useState({ endDate: timestamp.now() });
  const [startTime, setStartTime] = useState({ time: '12:00' });
  const [endTime, setEndTime] = useState({ time: '12:00' });
  const dispatch = useDispatch();

  const addEvent = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const response = await __.post(`/sch/events`, { ...form, startDate: startDate.startDate, endDate: endDate.endDate, startTime: startTime.time, endTime: endTime.time });
      if (response.status === 200) {
        const id = response.data.insertId;
        // eslint-disable-next-line no-restricted-syntax
        for await (const selectedCustomer of selectedCustomers) {
          await __.post('/sch/tags', {
            event: id,
            tagType: 'customer',
            typeId: selectedCustomer,
          });
        }

        // eslint-disable-next-line no-restricted-syntax
        for await (const selectedLead of selectedLeads) {
          await __.post('/sch/tags', {
            event: id,
            tagType: 'lead',
            typeId: selectedLead,
          });
        }
        __.displaySuccessMessage('Event added!');
        __.redirect(dispatch, '/modules/sch/events');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/crm/customers?project=${localStorage.getItem('project')}`);
      if (response.status === 200) {
        setCustomers(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/com/leads?project=${localStorage.getItem('project')}`);
      if (response.status === 200) {
        setLeads(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const breadcrumb = [
    { label: 'Events', route: '/modules/sch/events' },
    { label: 'Add Event', route: '#' },
  ];

  const handleCustomerSelection = (e) => {
    const customerId = e.target.value;
    const customerArray = [...selectedCustomers];
    if (_.includes(customerArray, customerId)) {
      // eslint-disable-next-line array-callback-return
      const newCustomerArray = customerArray.filter((id) => {
        if (id !== customerId) {
          return true;
        }
      });
      setSelectedCustomers(newCustomerArray);
    } else {
      customerArray.push(customerId);
      setSelectedCustomers(customerArray);
    }
  };

  const handleLeadSelection = (e) => {
    const leadId = e.target.value;
    const leadArray = [...selectedLeads];
    if (_.includes(leadArray, leadId)) {
      // eslint-disable-next-line array-callback-return
      const newLeadArray = leadArray.filter((id) => {
        if (id !== leadId) {
          return true;
        }
      });
      setSelectedLeads(newLeadArray);
    } else {
      leadArray.push(leadId);
      setSelectedLeads(leadArray);
    }
  };

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Add Event</Heading>
        <Clearfix />
        <form onSubmit={(e) => addEvent(e)}>
          <Clearfix />
          {customers.length > 0 ? (
            <>
              <SubHeading className="left">Select Customers</SubHeading>
              <Clearfix />
              <p>
                {customers.map((customer) => (
                  <span className="mh3">
                    <label>
                      <input
                        type="checkbox"
                        value={customer.id}
                        onChange={(e) => {
                          handleCustomerSelection(e);
                        }}
                      />
                      <span>
                        {customer.firstname} {customer.lastname}
                      </span>
                    </label>
                  </span>
                ))}
              </p>
            </>
          ) : null}
          {leads.length > 0 ? (
            <>
              <SubHeading className="left">Select Leads</SubHeading>
              <Clearfix />
              {leads.map((lead) => (
                <span className="mh3">
                  <label>
                    <input
                      type="checkbox"
                      value={lead.id}
                      onChange={(e) => {
                        handleLeadSelection(e);
                      }}
                    />
                    <span>
                      {lead.firstname} {lead.lastname}
                    </span>
                  </label>
                </span>
              ))}
            </>
          ) : null}

          <Clearfix />
          <SubHeading className="left">Event Details</SubHeading>
          <Clearfix />
          <TextInput label="Event Name" className="col s12 l3" state={form} isRequired id="event" setState={setForm} type="text" value={form.event} />
          <TextInput label="Description" className="col s12 l9" state={form} isRequired id="description" setState={setForm} type="text" value={form.description} />
          <Datepicker id="startDate" state={startDate} setState={setStartDate} className="col s12 l4" label="Start Date" />
          <TimePicker label="Time (24-hour format)" className="col s12 l2" setState={setStartTime} state={startTime} id="startTime" />
          <Clearfix />
          <Datepicker id="endDate" state={endDate} setState={setEndDate} className="col s12 l4" label="End Date" />
          <TimePicker label="Time (24-hour format)" className="col s12 l2" setState={setEndTime} state={endTime} id="endTime" />
          <Submit className="col s12">Add Event</Submit>
          <Clearfix />
        </form>
      </Panel>
    </>
  );
};

export default AddEvent;
