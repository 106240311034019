import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';
import DataTable from '../../../ui/DataTable';
import __ from '../../../utilities/functions';
import timestamp from '../../../utilities/timestamp';
import CircleLink from '../../../ui/CircleLink';
import CircleAnchorLink from '../../../ui/CircleAnchorLink';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Button from '../../../ui/Button';
import DummyText from '../../../ui/DummyText';
import FolderIcon from '../../../ui/FolderIcon';
import config from '../../../utilities/config';
import Breadcrumb from '../../../ui/Breadcrumb';
import SubHeading from '../../../ui/SubHeading';
import Spacer from '../../../ui/Spacer';
import Submit from '../../../ui/Submit';
import Text from '../../../ui/Text';
import ClickButton from '../../../ui/ClickButton';

const ManageTags = () => {
  const { tagType, typeId } = useParams();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/asm/assets/manage?project=${localStorage.getItem('project')}`);

      const selectItems = [];

      response.data.map((asset) => {
        asset.files.map((file) =>
          // eslint-disable-next-line array-callback-return
          file.tags.map((tag) => {
            if (tag.tagType === tagType && tag.typeId === Number(typeId)) {
              selectItems.push(file.id);
            }
          })
        );
        setSelected(selectItems);
        return true;
      });

      setLoading(false);
      setAssets(response.data);
    } catch (err) {
      console.log('errr', err);
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const onChange = (e) => {
    if (e.target.checked) {
      setSelected([...selected, Number(e.target.id)]);
    } else {
      const newSelected = selected.filter((id) => id !== Number(e.target.id));
      setSelected(newSelected);
    }
  };

  const saveChanges = async (e) => {
    e.preventDefault();

    try {
      __.showLoadingBar(dispatch);
      const response = await __.patch(`/asm/assets/manage`, {
        selected,
        tagType,
        typeId,
      });

      if (response.status === 204) {
        __.displaySuccessMessage('Changes saved successfully!');
      }

      setLoading(false);
    } catch (err) {
      console.log('errr', err);
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Assets', route: '/modules/asm/assets' },
    { label: 'Manage Assets', route: '#' },
  ];
  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />

      <Panel className="col s12 mv3">
        <Heading className="left">Manage Assets</Heading>
        <ClickButton
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            history.back();
          }}
          href="#"
          className="mv3 right"
        >
          Back
        </ClickButton>
        <Clearfix />
        <DummyText />
        <Text>You can select and unselect the files you want associated with this {tagType}.</Text>
        <Clearfix />
        <form onSubmit={(e) => saveChanges(e)}>
          {assets.map((asset) => (
            <>
              <SubHeading className="left">Folder: {asset.folder}</SubHeading>
              <Clearfix />
              {asset.files.map((file) => {
                let checked = false;
                file.tags.map((tag) => {
                  if (tag.tagType === tagType && tag.typeId === Number(typeId)) {
                    checked = true;
                  }
                  return true;
                });
                return (
                  <label className="inp pa3 mr3">
                    <input type="checkbox" defaultChecked={checked} onChange={(e) => onChange(e)} id={file.id} />
                    <span>{file.filename}</span>
                  </label>
                );
              })}
              <Clearfix />
              <Spacer className="mv3" />
            </>
          ))}
          <Clearfix />
          <Submit className="col s12" position="right">
            Save Changes
          </Submit>
        </form>
        <Clearfix />
        <Clearfix />
      </Panel>
    </>
  );
};

export default ManageTags;
