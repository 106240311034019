import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import __ from '../../../utilities/functions';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import Breadcrumb from '../../../ui/Breadcrumb';

const EditItem = () => {
  const { group, id } = useParams();
  const [form, setForm] = useState({ inventoryGroup: group, id, data: {} });
  const dispatch = useDispatch();
  const [groupDetails, setGroupDetails] = useState({ fields: [] });

  // get item details
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/inv/items?id=${id}`);
      if (response.status === 200) {
        // add the items in the 'data' object to the main item object
        // so that we can reference this in the input fields
        const item = { ...response.data[0], ...response.data[0].data };
        setForm(item);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  // get additional field names from group template
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/inv/groups?id=${group}`);
      if (response.status === 200) {
        setGroupDetails(response.data[0]);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const editItem = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      // build additional data body
      const data = {};
      groupDetails.fields.map((field) => {
        data[field] = form[field];
        return true;
      });

      const response = await __.patch(`/inv/items`, {
        ...form,
        data,
      });
      if (response.status === 204) {
        __.displaySuccessMessage('Item added!');
        __.redirect(dispatch, '/modules/inv/items');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Items', route: '/modules/inv/items' },
    { label: 'Edit Item', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Edit Item</Heading>
        <Clearfix />
        <form onSubmit={(e) => editItem(e)}>
          {groupDetails.fields.map((field, i) => (
            <TextInput label={_.upperFirst(field)} className="col s12 l2" state={form} isRequired id={field} setState={setForm} type="text" value={form[field]} key={i} />
          ))}
          <Submit className="col s12">Save Changes</Submit>
        </form>
      </Panel>
    </>
  );
};

export default EditItem;
