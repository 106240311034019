import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import TextInput from '../../ui/TextInput';
import Submit from '../../ui/Submit';
import redirect from '../../utilities/redirect';
import Breadcrumb from '../../ui/Breadcrumb';
import Clearfix from '../../ui/Clearfix';

const AddClient = () => {
  const [form, setForm] = useState({});
  const dispatch = useDispatch();

  const addClient = async (e) => {
    e.preventDefault();
    __.showLoadingBar(dispatch);
    try {
      const response = await __.post('/clients', form);
      if (response.status === 200) {
        __.displaySuccessMessage('Client added successfully!');
        __.redirect(dispatch, '/admin/clients');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Clients', route: '/admin/clients' },
    { label: 'Add Client', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv4">
        <Heading className="left">Add a Client</Heading>
        <Clearfix />
        <form onSubmit={(e) => addClient(e)}>
          <TextInput label="First Name" className="col s12 l3" state={form} isRequired id="firstname" setState={setForm} type="text" value={form.firstname} />
          <TextInput label="Last Name" className="col s12 l3" state={form} isRequired id="lastname" setState={setForm} type="text" value={form.lastname} />
          <TextInput label="Company" className="col s12 l2" state={form} isRequired id="company" setState={setForm} type="text" value={form.company} />
          <TextInput label="Email" className="col s12 l4" state={form} isRequired id="email" setState={setForm} type="text" value={form.email} />
          <TextInput label="Password" className="col s12 l2" state={form} isRequired id="password" setState={setForm} type="text" value={form.password} />
          <TextInput label="Phone" className="col s12 l3" state={form} isRequired id="phone" setState={setForm} type="text" value={form.phone} />
          <Submit className="col s12">Add Client</Submit>
        </form>
      </Panel>
    </>
  );
};

export default AddClient;
