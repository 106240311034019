import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import SelectMenu from '../../../ui/SelectMenu';
import FileUpload from '../../../ui/FileUpload';
import Breadcrumb from '../../../ui/Breadcrumb';
import UsersSelect from '../../components/UsersSelect';
import SubHeading from '../../../ui/SubHeading';

const EditCustomer = () => {
  const { id } = useParams();
  const [loading, updateLoading] = useState(true);
  const [form, setForm] = useState({ id });
  const dispatch = useDispatch();

  // get the customers details
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/crm/customers?id=${id}`);
      if (response.status === 200) {
        setForm(response.data[0]);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const editCustomer = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const response = await __.patch(`/crm/customers`, form);
      if (response.status === 204) {
        __.displaySuccessMessage('Changes saved successfully!');
        __.redirect(dispatch, '/modules/crm/customers');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  // get customer Groups
  const [groups, setGroups] = useState([]);
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/crm/groups?project=${localStorage.getItem('project')}`);
      if (response.status === 200) {
        setGroups(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);
  const breadcrumb = [
    { label: 'Customers', route: '/modules/crm/customers' },
    { label: 'Edit Customer', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Edit Customer</Heading>
        <Clearfix />
        <form onSubmit={(e) => editCustomer(e)}>
          <TextInput label="First Name" className="col s12 l3" state={form} isRequired id="firstname" setState={setForm} type="text" value={form.firstname} />
          <TextInput label="Last Name" className="col s12 l3" state={form} isRequired id="lastname" setState={setForm} type="text" value={form.lastname} />
          <TextInput label="Phone" className="col s12 l3" state={form} id="phone" isRequired={false} setState={setForm} type="text" value={form.phone} />
          <TextInput label="Email" className="col s12 l3" state={form} id="email" isRequired setState={setForm} type="text" value={form.email} />
          <TextInput label="Address" className="col s12 l5" state={form} id="address" isRequired={false} setState={setForm} type="text" value={form.address} />
          <TextInput label="City" className="col s12 l2" state={form} id="city" setState={setForm} isRequired={false} type="text" value={form.city} />
          <TextInput label="Province" className="col s12 l2" state={form} id="province" setState={setForm} isRequired={false} type="text" value={form.province} />
          <SelectMenu
            list={groups}
            id="customerGroup"
            className="col s12 l3"
            setState={setForm}
            state={form}
            idKey="id"
            valueKey="groupName"
            label="Customer Group"
            defaultValue={form.customerGroup}
          />
          <Clearfix />

          <SubHeading className="left">Assign To User</SubHeading>
          <Clearfix />
          <Text>Assign this customer to a user. Once assigned, the customer will be visible under the 'My Customers' list of the user.</Text>

          <UsersSelect id="assignedTo" state={form} setState={setForm} className="col s12 l2" label="Assign to User" />

          <Submit className="col s12">Save Changes</Submit>
        </form>
      </Panel>
    </>
  );
};

export default EditCustomer;
