/*eslint-disable*/
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';
import { locale } from 'moment/moment';
import __ from '../../../utilities/functions';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import SubHeading from '../../../ui/SubHeading';
import getCustomerDetails from '../../../utilities/getCustomerDetails';
import getLeadDetails from '../../../utilities/getLeadDetails';
import DataTable from '../../../ui/DataTable';
import Notes from '../../components/Notes';
import ModalButton from '../../../ui/ModalButton';
import Modal from '../../../ui/Modal';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import timestamp from '../../../utilities/timestamp';
import ProfileInventoryEngagements from '../../components/ProfileInventoryEngagements';
import ProfileScheduledEvents from '../../components/ProfileScheduledEvents';
import ProfileDigitalAssets from '../../components/ProfileDigitalAssets';
import Breadcrumb from '../../../ui/Breadcrumb';
import ClickButton from '../../../ui/ClickButton';
import DummyText from '../../../ui/DummyText';
import stringify from 'json-stringify-pretty-compact';
import CodeEditor from '../../../ui/CodeEditor';
import SelectMenu from '../../../ui/SelectMenu';
import Button from '../../../ui/Button';
import LeadScore from '../../components/LeadScore';
import Submissions from '../../components/Submissions';
import EnrichData from '../../components/EnrichData';

const LeadDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [instance, setInstance] = useState();
  const [enrichedData, setEnrichedData] = useState({ data: '{}' });

  // get the customer details
  const [details, setDetails] = useState({ data: [] });
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await getLeadDetails(id);
      setDetails(response);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const convertLead = async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.post(`/com/leads/convert`, {
        project: localStorage.getItem('project'),
        id,
      });
      __.displaySuccessMessage('The lead has been successfully converted to a customer');
      __.redirect(dispatch, '/modules/crm/customers');
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Leads', route: '/modules/com/leads' },
    { label: 'Lead Details', route: '#' },
  ];
  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />

      <Panel className="col s12 mv3">
        <Heading className="left">Lead Details</Heading>
        <ClickButton
          className="mv3 right"
          onClick={() => {
            return convertLead();
          }}
        >
          Convert To Customer
        </ClickButton>
        <Button route={`/modules/com/set-score/${id}`} className="mv3 right">
          Set Score
        </Button>
        <Clearfix />
        <SubHeading className="left">
          {details.firstname} {details.lastname}
        </SubHeading>
        <Clearfix />
        <Text>
          <b>Email:</b> {details.email} <br />
          <b>Phone:</b> {details.phone}
        </Text>
        <LeadScore lead={id} />
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">Enriched Data</Heading>
        <Clearfix />
        <p>
          Lead enrichment ensures the leads in your system are as updated and accurate as possible with any data that is available about the lead. It is the process of associating publicly available,
          third party data on a lead or customer.
        </p>
        <EnrichData email={details.email} />
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">Inventory Engagements</Heading>
        <Clearfix />
        <ProfileInventoryEngagements typeId={id} tagType="lead" />
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">Scheduled Events</Heading>
        <Clearfix />
        <ProfileScheduledEvents tagType="lead" typeId={id} />
      </Panel>

      <ProfileDigitalAssets typeId={id} tagType="lead" />

      <Notes id={id} recipientType="lead" />

      <Panel className="col s12 mv3">
        <Heading className="left">Form Submissions</Heading>
        <Clearfix />
        <Submissions scope="lead" scopeId={id} />
      </Panel>
    </>
  );
};

export default LeadDetails;
