import { useSelector } from 'react-redux';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import SubscriptionList from '../components/SubscriptionList';
import Clearfix from '../../ui/Clearfix';

const Subscriptions = () => {
  const state = useSelector((store) => store);

  return (
    <Panel className="col s12 mv3">
      <Heading className="left">My Subscriptions</Heading>
      <Clearfix />
      <SubscriptionList client={state.userId} />
    </Panel>
  );
};

export default Subscriptions;
