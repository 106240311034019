import { useState, useEffect, useMemo } from 'react';
import { Chart } from 'react-charts';
import { Line } from 'react-chartjs-2';

import { useSelector } from 'react-redux';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import Clearfix from '../../ui/Clearfix';
import Datepicker from '../../ui/Datepicker';
import NewLeads from '../data/NewLeads';
import DateRange from '../../ui/DateRange';
import timestamp from '../../utilities/timestamp';
import DummyText from '../../ui/DummyText';
import FormSubmissions from '../data/FormSubmissions';
import NewCustomers from '../data/NewCustomers';
import DataWidgetUni from '../data/DataWidgetUni';
import Text from '../../ui/Text';

const Dashboard = () => {
  const [from, setFrom] = useState(timestamp.now() - 600000);
  const [to, setTo] = useState(timestamp.now());

  return (
    <div>
      <Panel className="col s12 mv3">
        <Heading className="left">Dashboard</Heading>
        <Clearfix />
        <Text>
          The CORE Dashboard interface provides at-a-glance views of key performance indicators relevant to a particular objective, project or process. Consider you're dashboard as a data
          visualization of a “progress report” to monitor your efforts and success in a way that is immediately understood.
        </Text>
      </Panel>
      <Panel className="col s12 l6 mv3 pl4">
        <DateRange setFromProp={setFrom} setToProp={setTo} />
      </Panel>
      <NewLeads className="col s12 l6" to={to} from={from} />
      <DataWidgetUni className="col s12 l6 right" to={to} from={from} label="Inventory Engagements" dataset="engagements" />
      <FormSubmissions className="col s12 l6 left" to={to} from={from} />
      <NewCustomers className="col s12 l6 right" to={to} from={from} />
      <DataWidgetUni className="col s12 l6 left" to={to} from={from} label="Video Likes" dataset="likes" />
      <DataWidgetUni className="col s12 l6 right" to={to} from={from} label="Video Views" dataset="views" />
    </div>
  );
};
export default Dashboard;
