const index = {
    lead_management: 4,
    inventory_management: 3,
    home: 0,
    crm: 5,
    cms: 1,
    bms: 2,
    admin: 6
}

export default index;