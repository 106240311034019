import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function ClickButton({ href, icon, className = '', tooltip, onClick, children }) {
  return (
    <a
      href={href}
      className={`dim ph4 pv2 mb2 mh2 dib nButton nEmboss light-blue-text ${className}`}
      onClick={(e) => {
        onClick(e);
      }}
    >
      {children.toLowerCase()}
    </a>
  );
}

export default ClickButton;
