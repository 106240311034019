import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import DataTable from '../../../ui/DataTable';
import Text from '../../../ui/Text';
import DummyText from '../../../ui/DummyText';
import LeadScore from '../../components/LeadScore';

const Leads = () => {
  const state = useSelector((store) => store);
  const [scoredList, setScoredList] = useState([]);
  const [unscoredList, setUnscoredList] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [myLeads, setMyleads] = useState([]);

  useEffect(async () => {
    if (state.userId) {
      try {
        __.showLoadingBar(dispatch);
        const response = await __.get(`/com/leads?project=${localStorage.getItem('project')}&sorting=true&assigned=${state.userId}`);
        if (response.status === 200) {
          setLoading(false);
          setScoredList(response.data.scoredLeads);
          setUnscoredList(response.data.unscoredLeads);
          setMyleads(response.data.assignedLeads);
        }
      } catch (err) {
        console.log('error', err);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    }
  }, [reload, state.userId]);

  const deleteLead = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        await __.delete(`/com/leads?id=${id}`);
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('Deleted successfully');
        setReload(!reload);
      } catch (err) {
        __.displayErrorMessage(err.response.data.message || err);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    };

    __.confirmDelete(deleteFn);
  };

  const columns = [
    {
      name: 'Lead',
      cell: (row) => (
        <>
          <p>
            {row.firstname} {row.lastname} <br />
            Email: {row.email}
            <br />
            Phone: {row.phone}
            <br />
            {row.scoring > 0 ? (
              <small>
                Score:{' '}
                <i className="material-icons" style={{ color: row.color }}>
                  star
                </i>{' '}
                {row.score}
              </small>
            ) : null}
          </p>
        </>
      ),
    },

    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          <CircleNavLink route={`/users/send-mail/${row.id}/leads`} icon="mail" tooltip="Send Mail" className="mh4" />
          <CircleNavLink route={`/modules/com/lead-details/${row.id}`} icon="developer_board" tooltip="Details" />
          <CircleNavLink route={`/modules/com/edit-lead/${row.id}`} icon="edit" tooltip="Edit" className="mh4" />
          <CircleLink onClick={() => deleteLead(row.id)} icon="delete" tooltip="Delete" />
        </span>
      ),
    },
  ];

  //get bulk customer data
  const getBulkLeadData = async (ids) => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.post(`/com/leads/batch`, { ids });
      if (response.status === 200) {
        setLoading(false);
        return response.data;
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const convertLeadData = (ids) => {
    getBulkLeadData(ids).then((data) => {
      __.jsonToCsv(JSON.stringify(data));
    });
  };

  // list of bulk functions available to this customers list
  const withSelectedFns = [
    {
      fn: convertLeadData,
      label: 'Export To CSV',
      icon: 'list_alt',
    },
  ];

  return (
    <>
      <Panel className="col s12 mv3">
        <Heading className="left">Leads</Heading>
        <Button route="/modules/com/add-lead" position="right" className="mv3">
          Add Lead
        </Button>
        <Clearfix />
        <Text>
          Leads from all forms are displayed and searchable by score and date, at-a-glance. This section keeps you up to date on all your lead activity, lead assignments, lead follow ups, lead details
          and progress as they convert to a customer.
        </Text>
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">My Leads</Heading>
        <Clearfix />
        <DataTable loading={loading} columns={columns} data={myLeads} filters={['firstname', 'lastname', 'email', 'phone']} withSelected withSelectedFns={withSelectedFns} />
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">Ranked Leads</Heading>
        <Clearfix />
        <DataTable loading={loading} columns={columns} data={scoredList} filters={['firstname', 'lastname', 'email', 'phone']} showDateRange withSelected withSelectedFns={withSelectedFns} />
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">Unranked Leads</Heading>
        <Clearfix />
        <DataTable loading={loading} columns={columns} data={unscoredList} filters={['firstname', 'lastname', 'email', 'phone']} showDateRange withSelected withSelectedFns={withSelectedFns} />
      </Panel>
    </>
  );
};

export default Leads;
