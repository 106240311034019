import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import SelectMenu from '../../../ui/SelectMenu';
import FileUpload from '../../../ui/FileUpload';
import Breadcrumb from '../../../ui/Breadcrumb';

const EditGroup = () => {
  const { id } = useParams();
  const [form, setForm] = useState({ id });
  const dispatch = useDispatch();

  // get template list
  const [templates, setTemplates] = useState([]);
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/inv/templates?project=${localStorage.getItem('project')}`, form);
      if (response.status === 200) {
        setTemplates(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/inv/groups?id=${id}`);
      if (response.status === 200) {
        setForm(response.data[0]);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const editGroup = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const response = await __.patch(`/inv/groups`, form);
      if (response.status === 204) {
        __.displaySuccessMessage('Group updated!');
        __.redirect(dispatch, '/modules/inv/groups');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Groups', route: '/modules/inv/groups' },
    { label: 'Edit Group', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Edit Group ({form.name})</Heading>
        <Clearfix />
        <form onSubmit={(e) => editGroup(e)}>
          <TextInput label="Group Name" className="col s12 l3" state={form} isRequired id="name" setState={setForm} type="text" value={form.name} />
          <SelectMenu list={templates} state={form} setState={setForm} id="template" className="col s12 l4" label="Choose Template" idKey="id" valueKey="name" defaultValue={form.template} />
          <Submit className="col s12">Save Changes</Submit>
        </form>
      </Panel>
    </>
  );
};

export default EditGroup;
