import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import __ from '../../utilities/functions';
import SubHeading from '../../ui/SubHeading';
import DataTable from '../../ui/DataTable';
import timestamp from '../../utilities/timestamp';
import CircleNavLink from '../../ui/CircleNavLink';
import CircleLink from '../../ui/CircleLink';
import Clearfix from '../../ui/Clearfix';

const Dashboard = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  // get stats
  const [stats, setStats] = useState({});
  useEffect(async () => {
    __.showLoadingBar(dispatch);
    try {
      const response = await __.get(`/stats/admin`);
      __.hideLoadingBar(dispatch);
      setLoading(false);
      setStats(response.data);
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage(`API Error: ${err}`);
    }
  }, []);

  // invoice column
  const invoiceColumns = [
    {
      name: 'Invoice ID',
      sortable: false,
      cell: (row) => (
        <p>
          {row.id} ({row.status})
        </p>
      ),
    },

    {
      name: 'Dates',
      sortable: false,
      cell: (row) => (
        <p>
          Due Date: {timestamp.unixToDate(row.dueDate)}
          <br /> Date Created: {timestamp.unixToDate(row.dateCreated)}
        </p>
      ),
    },

    {
      name: 'Amount',
      sortable: true,
      cell: (row) => <p>${row.amount}</p>,
    },

    {
      name: 'Description',
      sortable: false,
      cell: (row) => <p>{row.description}</p>,
    },

    {
      name: '',
      sortable: false,
      right: true,
      cell: (row) => (
        <>
          <CircleNavLink route={`/invoice-details/${row.id}`} icon="developer_board" tooltip="Details" />
        </>
      ),
    },
  ];
  const transactionColumns = [
    {
      name: 'ID',
      sortable: false,
      cell: (row) => <p>{row.id}</p>,
    },

    {
      name: 'Date',
      sortable: false,
      cell: (row) => <p>{timestamp.unixToDate(row.dateTime)}</p>,
    },

    {
      name: 'Amount',
      sortable: false,
      cell: (row) => <p>${row.amount}</p>,
    },

    {
      name: 'Description',
      sortable: false,
      cell: (row) => <p>{row.description}</p>,
    },
  ];

  return (
    <div>
      <Panel className="col s12 mv3">
        <Heading className="left">Dashboard</Heading>
        <Clearfix />
        <SubHeading>Unpaid Invoices</SubHeading>
        <DataTable loading={loading} columns={invoiceColumns} data={stats.invoices} />

        <SubHeading>Transactions</SubHeading>
        <DataTable loading={loading} columns={transactionColumns} data={stats.transactions} />
      </Panel>
    </div>
  );
};
export default Dashboard;
