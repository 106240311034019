import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function ModalButton({ modalId, icon, className = '', tooltip, children }) {
  return (
    <a href={`#${modalId}`} className={`dim ph4 pv2 mb2 mh2 dib nButton nEmboss modal-trigger light-blue-text ${className}`}>
      {children.toLowerCase()}
    </a>
  );
}

export default ModalButton;
