// select menu of projects for this user, get project list from redux store.

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import SelectMenu from '../../ui/SelectMenu';
import __ from '../../utilities/functions';

const UserProjectSelect = ({ className }) => {
  const state = useSelector((store) => store);
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const [form, setForm] = useState({ project: localStorage.getItem('project') });

  useEffect(() => {
    const prevProject = localStorage.getItem('project');
    // redirect only if the project dropdown was changed to a different project
    if (prevProject !== form.project && Number(form.project) !== 0) {
      localStorage.setItem('project', form.project);
      __.displaySuccessMessage('Project switched successfully');
      __.redirect(dispatch, '/users');
    }
  }, [form.project]);

  useEffect(async () => {
    // if (Number(form.project) !== 0) {
    __.showLoadingBar(dispatch);
    const list = [];
    // eslint-disable-next-line no-restricted-syntax
    for await (const project of state.userProjects) {
      const response = await __.get(`/projects?id=${project}`);
      list.push({ id: project, name: response.data[0].projectName });
    }
    setProjects(list);
    __.hideLoadingBar(dispatch);
    // }
  }, [state.userProjects, form.project]);

  return <SelectMenu id="project" list={projects} setState={setForm} label="Projects" state={form} className={className} idKey="id" valueKey="name" defaultValue={localStorage.getItem('project')} />;
};

export default UserProjectSelect;
