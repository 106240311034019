import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import SelectMenu from '../../../ui/SelectMenu';
import FileUpload from '../../../ui/FileUpload';
import Breadcrumb from '../../../ui/Breadcrumb';

const AddSection = () => {
  const { id } = useParams();
  const [loading, updateLoading] = useState(true);
  const [form, setForm] = useState({});
  const dispatch = useDispatch();

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/bms/categories?id=${id}`);
      if (response.status === 200) {
        setForm(response.data[0]);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const editCategory = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const response = await __.patch(`/bms/categories`, { ...form, id });
      if (response.status === 204) {
        __.displaySuccessMessage('Category updated successfully!');
        __.redirect(dispatch, '/modules/bms/categories');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Categories', route: '/modules/bms/categories' },
    { label: 'Edit Category', route: '#' },
  ];
  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Edit Category</Heading>
        <Clearfix />
        <form onSubmit={(e) => editCategory(e)}>
          <TextInput label="Category Name" className="col s12" state={form} isRequired id="name" setState={setForm} type="text" value={form.name} />
          <Submit className="col s12">Save Change</Submit>
        </form>
      </Panel>
    </>
  );
};

export default AddSection;
