import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import SelectMenu from '../../../ui/SelectMenu';
import FileUpload from '../../../ui/FileUpload';
import Breadcrumb from '../../../ui/Breadcrumb';

const AddTemplate = () => {
  const [loading, updateLoading] = useState(true);
  const [form, setForm] = useState({ project: localStorage.getItem('project') });
  const dispatch = useDispatch();

  const addTemplate = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const formFields = form.fieldstring.split(',').map((field) => field.trim().replace(/(\s*)("*)('*)/g, ''));

      // check to ensure a comma separated list is provided
      if (formFields.length === 0) {
        throw new Error('You need to provide a comma-separated list of fields');
      }

      // check to ensure the keys item and cost are present
      if (!_.includes(formFields, 'item') || !_.includes(formFields, 'cost')) {
        throw new Error('Your keys have to contain item and cost');
      }

      const response = await __.post(`/inv/templates`, { ...form, fields: formFields });
      if (response.status === 200) {
        __.displaySuccessMessage('Template added!');
        __.redirect(dispatch, '/modules/inv/templates');
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        __.displayErrorMessage(err.response.data.message);
      } else {
        __.displayErrorMessage(err.message);
      }
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Templates', route: '/modules/inv/templates' },
    { label: 'Add Template', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Add Template</Heading>
        <Clearfix />
        <p>A template should always include the following two fields: item and cost. So start naming your fields with "item,cost,....."</p>
        <form onSubmit={(e) => addTemplate(e)}>
          <TextInput label="Template Name" className="col s12 l3" state={form} isRequired id="name" setState={setForm} type="text" value={form.name} />
          <TextInput label="Fields (comma-separated, no spaces)" className="col s12 l9" state={form} isRequired id="fieldstring" setState={setForm} type="text" value={form.fieldstring} />
          <Submit className="col s12">Add Template</Submit>
        </form>
      </Panel>
    </>
  );
};

export default AddTemplate;
