import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';
import CircleLink from '../../ui/CircleLink';
import ClientSubscriptionList from '../../admins/components/ClientSubscriptionList';
import InvoiceList from '../../admins/components/InvoiceList';
import TransactionList from '../../admins/components/TransactionList';
import Breadcrumb from '../../ui/Breadcrumb';
import Clearfix from '../../ui/Clearfix';
import timestamp from '../../utilities/timestamp';
import SubscriptionInvoiceList from '../../admins/components/SubscriptionInvoiceList';
import CircleNavLink from '../../ui/CircleNavLink';
import DataTable from '../../ui/DataTable';
import CircleAnchorLink from '../../ui/CircleAnchorLink';
import config from '../../utilities/config';
import TextInput from '../../ui/TextInput';
import Submit from '../../ui/Submit';

const AddProject = () => {
  const { subscription } = useParams();
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const [reload, setReload] = useState(true);
  const [loading, updateLoading] = useState(true);
  const [form, setForm] = useState({});

  const breadcrumb = [
    { label: 'Subscriptions', route: '/client/subscriptions' },
    { label: 'Subscriptions Details', route: `/client/subscription-details/${subscription}` },
    { label: 'Manage Projects', route: `/client/manage-projects/${subscription}` },
    { label: 'Add Project', route: '#' },
  ];

  const addProject = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);

      const response = await __.post(`/projects`, {
        ...form,
        subscription,
      });

      if (response.status === 200) {
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('Project added successfully!');
        __.redirect(dispatch, `/client/manage-projects/${subscription}`);
      } else {
        __.displayErrorMessage(response.message);
      }
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage(err.response.data.message);
    }
  };

  return (
    <div>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Add Project</Heading>
        <Clearfix />
        <form onSubmit={(e) => addProject(e)}>
          <TextInput label="Project Name" className="col s4" state={form} isRequired id="projectName" setState={setForm} value={form.projectName} type="text" />
          <TextInput label="Project Description" className="col s4" state={form} isRequired id="projectDescription" setState={setForm} value={form.projectDescription} type="text" />
          <TextInput label="Website" className="col s4" state={form} isRequired id="website" setState={setForm} value={form.website} type="text" />
          <Submit className="col s12">Add Project</Submit>
        </form>
      </Panel>
    </div>
  );
};

export default AddProject;
