import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import DataTable from '../../../ui/DataTable';
import Text from '../../../ui/Text';
import Submissions from '../../components/Submissions';
import Breadcrumb from '../../../ui/Breadcrumb';

const AllSubmissions = () => {
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);

  const breadcrumb = [
    { label: 'Forms', route: '/modules/com/forms' },
    { label: 'All Submissions', route: '#' },
  ];
  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />

      <Panel className="col s12 mv3">
        <Heading className="left">All Form Submissions</Heading>
        <Clearfix />
        <Submissions scope="project" scopeId={localStorage.getItem('project')} showProfileButton />
      </Panel>
    </>
  );
};

export default AllSubmissions;
