import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import NoData from './NoData';
import Clearfix from '../../ui/Clearfix';

const EnrichData = ({ email }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/com/data/enrich?email=${email}`);
      setData(response.data);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [email]);

  if (data.length === 0) {
    return <NoData />;
  }

  const person = data[0].data;

  const PersonalInfo = () => {
    if (person.avatar) {
      return (
        <>
          <div className="left mr3">
            <img src={person.avatar} alt="avatar" style={{ width: '200px', height: '200px' }} />
          </div>
          <div>
            <p className="no-margin-top">
              Full Name: {person.fullName || 'No data'} <br />
              Gender: {person.gender || 'No data'} <br />
              Age Range: {person.ageRange || 'No data'} <br />
              Organization: {person.organization || 'No data'} <br />
              Title: {person.title || 'No data'} <br />
              Location: {person.location || 'No data'} <br />
            </p>
          </div>
        </>
      );
    }

    return (
      <>
        <p>Full Name: {person.fullName || 'No data'}</p>
        Gender: {person.gender || 'No data'} <br />
        Age Range: {person.ageRange || 'No data'} <br />
        Organization: {person.organization || 'No data'} <br />
        Title: {person.title || 'No data'} <br />
        Location: {person.location || 'No data'} <br />
      </>
    );
  };

  const About = () => {
    if (person.bio) {
      return (
        <>
          <p>
            <u>About {person.fullName}</u> <br />
            {person.bio}
          </p>
        </>
      );
    }

    return null;
  };

  const Education = () => {
    if (person.details.education) {
      return (
        <>
          <p>
            <u>Education</u>
          </p>
          {person.details.education.map((education) => (
            <div>
              <p>
                Degree: {education?.degree || 'No data'}
                <br />
                From: {education?.name || 'No data'}
                <br />
                Year: {education?.end?.year || 'No data'}
              </p>
            </div>
          ))}
        </>
      );
    }

    return null;
  };

  const Socials = () => (
    <p>
      Facebook: {person.facebook || 'No data'} <br />
      Twitter: {person.twitter || 'No data'} <br />
      LinkedIn: {person.linkedin || 'No data'} <br />
    </p>
  );

  const Employment = () => {
    if (person.details.employment) {
      return (
        <>
          <p>
            <u>Employment</u>
          </p>
          {person.details.employment.map((employment) => (
            <div>
              <p>
                Company: {employment?.name || 'No data'}
                <br />
                Title: {employment?.title || 'No data'}
                <br />
              </p>
            </div>
          ))}
        </>
      );
    }

    return null;
  };

  return (
    <div>
      <div className="col l6">
        <PersonalInfo />
        <Clearfix />
        <About />
        <Socials />
      </div>
      <div className="col l6">
        <Education />
        <Employment />
      </div>
    </div>
  );
};

export default EnrichData;
