import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import { useState, useEffect } from 'react';
import { createBrowserHistory } from 'history';
import $ from 'jquery';
import os from 'os';
import dotenv from 'dotenv';
import LoadingOverlay from 'react-loading-overlay';
import ScrollToTop from './utilities/ScrollToTop';
import Loader from './utilities/Loader';
import Clearfix from './ui/Clearfix';
import MyRedirect from './utilities/redirect';
import ValidateSession from './utilities/validateSession';
import SidePanel from './components/SidePanel';
import SideNav from './ui/SideNav';
import SwitchButton from './ui/SwitchButton';
import Logout from './utilities/Logout';
import Login from './Login';
import config from './utilities/config';
import __ from './utilities/functions';

// ADMINS
import AdminDashboard from './admins/pages/Dashboard';
import Clients from './admins/pages/Clients';
import ClientDetails from './admins/pages/ClientDetails';
import AddClient from './admins/pages/AddClient';
import EditClient from './admins/pages/EditClient';
import Subscriptions from './admins/pages/Subscriptions';
import AddSubscription from './admins/pages/AddSubscription';
import SubscriptionDetails from './admins/pages/SubscriptionDetails';
import Invoices from './admins/pages/Invoices';
import AddInvoice from './admins/pages/AddInvoice';
import EditInvoice from './admins/pages/EditInvoice';
import InvoiceDetails from './admins/pages/InvoiceDetails';
import Transactions from './admins/pages/Transactions';
import ChangePassword from './admins/pages/ChangePassword';

// CLIENTS
import ClientDashboard from './clients/pages/Dashboard';
import ClientSubscriptions from './clients/pages/Subscriptions';
import ClientInvoices from './clients/pages/Invoices';
import ClientInvoiceDetails from './clients/pages/InvoiceDetails';
import ClientTransactions from './clients/pages/Transactions';
import ClientChangePassword from './clients/pages/ChangePassword';
import ClientSubscriptionDetails from './clients/pages/SubscriptionDetails';
import ClientManageProjects from './clients/pages/ManageProjects';
import ClientManageUsers from './clients/pages/ManageUsers';
import ClientAddProject from './clients/pages/AddProject';
import ClientEditProject from './clients/pages/EditProject';
import ClientAddUser from './clients/pages/AddUser';
import ClientEditUser from './clients/pages/EditUser';

// USERS
import UserDashboard from './users/pages/Dashboard';
import UserChangePassword from './users/pages/ChangePassword';
import CmsAssets from './users/modules/cms/Assets';
import CmsUploadAsset from './users/modules/cms/UploadAsset';
import CmsSections from './users/modules/cms/Sections';
import CmsGroups from './users/modules/cms/Groups';
import CmsAddGroup from './users/modules/cms/AddGroup';
import CmsAddSection from './users/modules/cms/AddSection';
import CmsEditSection from './users/modules/cms/EditSection';
import CmsEditGroup from './users/modules/cms/EditGroup';

import BmsVideos from './users/modules/bms/Videos';
import BmsAddVideo from './users/modules/bms/AddVideo';
import BmsCategories from './users/modules/bms/Categories';
import BmsAddCategory from './users/modules/bms/AddCategory';
import BmsEditCategory from './users/modules/bms/EditCategory';

import InvTemplates from './users/modules/inv/Templates';
import InvAddTemplate from './users/modules/inv/AddTemplate';
import InvEditTemplate from './users/modules/inv/EditTemplate';
import InvGroups from './users/modules/inv/Groups';
import InvAddGroup from './users/modules/inv/AddGroup';
import InvEditGroup from './users/modules/inv/EditGroup';
import InvItems from './users/modules/inv/Items';
import InvAddItem from './users/modules/inv/AddItems';
import InvEditItem from './users/modules/inv/EditItem';
import InvEngagements from './users/modules/inv/Engagements';
import InvItemDetails from './users/modules/inv/ItemDetails';
import UserProjectSelect from './users/components/UserProjectSelect';
import HottestItems from './users/modules/inv/HottestItems';

import CrmCustomers from './users/modules/crm/Customers';
import CrmAddCustomer from './users/modules/crm/AddCustomer';
import CrmEditCustomer from './users/modules/crm/EditCustomer';
import CrmCustomerDetails from './users/modules/crm/CustomerDetails';
import CrmGroups from './users/modules/crm/Groups';
import CrmAddGroup from './users/modules/crm/AddGroup';
import CrmEditGroup from './users/modules/crm/EditGroup';

import ComForms from './users/modules/com/Forms';
import ComAllSubmissions from './users/modules/com/AllSubmissions';
import ComAddForm from './users/modules/com/AddForm';
import ComEditForm from './users/modules/com/EditForm';
import ComFormSubmissions from './users/modules/com/FormSubmissions';
import ComLeads from './users/modules/com/Leads';
import ComAddLead from './users/modules/com/AddLead';
import ComEditLead from './users/modules/com/EditLead';
import ComLeadDetails from './users/modules/com/LeadDetails';
import ComScoring from './users/modules/com/Scoring';
import ComAddScoring from './users/modules/com/AddScoring';
import ComEditScoring from './users/modules/com/EditScoring';
import ComScoringLeads from './users/modules/com/ScoringLeads';
import ComSetScore from './users/modules/com/SetScore';

import AsmAssets from './users/modules/asm/Assets';
import AsmUploadAsset from './users/modules/asm/UploadAsset';
import AsmFolders from './users/modules/asm/Folders';
import AsmEditFolder from './users/modules/asm/EditFolder';
import AsmFiles from './users/modules/asm/Files';
import AsmManageTags from './users/modules/asm/ManageTags';

import SchEvents from './users/modules/sch/Events';
import SchAddEvent from './users/modules/sch/AddEvent';
import SchEditEvent from './users/modules/sch/EditEvent';
import SchEventDetails from './users/modules/sch/EventDetails';

import SendMail from './users/pages/SendMail';
import ClickButton from './ui/ClickButton';
import SmallButton from './ui/SmallButton';

import ApiAccess from './users/pages/ApiAccess';

export const history = createBrowserHistory();

console.log('Env', process.env.PUBLIC_URL);

export default function App() {
  const state = useSelector((store) => store);
  const dispatch = useDispatch();

  return (
    <BrowserRouter>
      <LoadingOverlay active={state.loading} spinner text="loading...">
        <ScrollToTop>
          <ValidateSession />
          <div className="App">
            <div className="container-fluid">
              <div className="row">
                <div className="col l3 hide-on-med-and-down pb5" id="sidenav" style={{ minHeight: '100vh', position: 'fixed' }}>
                  <SidePanel />
                </div>
                {/*<div className="col l3 hide-on-med-and-down pb5" id="sidenav" style={{ minHeight: '100vh' }} />*/}

                <div className="col l9 offset-l3 s12 no-padding-left no-padding-right">
                  {/* <div className="col s2 no-padding"> */}
                  {/*  <a className="btn-floating btn-large circle_btn_hamb hide-on-large-only sidenav-trigger" data-target="mobile-menu"> */}
                  {/*    <i className="material-icons">menu</i> */}
                  {/*  </a> */}
                  {/*  <SideNav /> */}
                  {/* </div> */}
                  <div className="col s12">
                    {state.userType === 'users' ? <UserProjectSelect className="col s12 l3 left" /> : null}

                    {/*<SwitchButton*/}
                    {/*  on*/}
                    {/*  id="darkmode"*/}
                    {/*  label="Darkmode"*/}
                    {/*  className="right pt4 pr4"*/}
                    {/*  onClick={() => {*/}
                    {/*    console.log('clicked');*/}
                    {/*  }}*/}
                    {/*/>*/}

                    <div className="col right no-padding-right">
                      {/*<label className="label mt3">*/}
                      {/*  <div className="toggle">*/}
                      {/*    <input className="toggle-state" onInput="" type="checkbox" id="darkmode" name="check" value="check" />*/}
                      {/*    <div className="indicator" />*/}
                      {/*  </div>*/}
                      {/*  <div className="label-text" id="mode">*/}
                      {/*    dark mode*/}
                      {/*  </div>*/}
                      {/*</label>*/}
                      {state.loggedIn ? (
                        <>
                          <SmallButton route={`/${state.userType}`} icon="dashboard" className="f5 mt4">
                            Dashboard
                          </SmallButton>
                          <SmallButton route={`/${state.userType}/change-password`} icon="lock" className="f5 mt4">
                            Change Password
                          </SmallButton>
                          <SmallButton route={`/${state.userType}/api-access`} icon="api" className="f5 mt4">
                            API Access
                          </SmallButton>
                          <SmallButton route="/logout" icon="logout" className="f5 mt4">
                            Logout
                          </SmallButton>
                        </>
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <Switch>
                      <Route path="/logout" render={() => <Logout />} />
                      <Route
                        exact
                        path="/"
                        render={() => {
                          if (state.loggedIn) {
                            if (state.userType === 'admins') {
                              return <AdminDashboard />;
                            }

                            if (state.userType === 'clients') {
                              return <ClientDashboard />;
                            }

                            if (state.userType === 'users') {
                              return <UserDashboard />;
                            }
                          } else {
                            return <Login />;
                          }
                        }}
                      />
                      <Route path="/admins" exact component={AdminDashboard} />
                      <Route path="/admin/dashboard" exact component={AdminDashboard} />
                      <Route exact path="/admin/clients" render={() => <Clients />} />
                      <Route exact path="/admin/client-details/:id" render={() => <ClientDetails />} />
                      <Route exact path="/admin/add-client" render={() => <AddClient />} />
                      <Route exact path="/admin/edit-client/:id" render={() => <EditClient />} />
                      <Route exact path="/admin/subscriptions" render={() => <Subscriptions />} />
                      <Route exact path="/admin/add-subscription" render={() => <AddSubscription />} />
                      <Route exact path="/admin/subscription-details/:id" render={() => <SubscriptionDetails />} />
                      <Route exact path="/admin/invoices" render={() => <Invoices />} />
                      <Route exact path="/admin/add-invoice" render={() => <AddInvoice />} />
                      <Route exact path="/admin/edit-invoice/:id" render={() => <EditInvoice />} />
                      <Route exact path="/admin/invoice-details/:id" render={() => <InvoiceDetails />} />
                      <Route exact path="/admin/transactions" render={() => <Transactions />} />
                      <Route exact path="/admin/change-password" render={() => <ChangePassword />} />

                      <Route path="/clients" exact component={ClientDashboard} />
                      <Route path="/client/dashboard" exact component={ClientDashboard} />
                      <Route path="/client/subscriptions" exact component={ClientSubscriptions} />
                      <Route path="/client/invoices" exact component={ClientInvoices} />
                      <Route path="/client/invoice-details/:id" exact component={ClientInvoiceDetails} />
                      <Route path="/client/transactions" exact component={ClientTransactions} />
                      <Route path="/client/transactions" exact component={ClientTransactions} />
                      <Route path="/client/change-password" exact component={ClientChangePassword} />
                      <Route path="/client/subscription-details/:id" exact component={ClientSubscriptionDetails} />
                      <Route path="/client/manage-projects/:subscription" exact component={ClientManageProjects} />
                      <Route path="/client/manage-users/:subscription" exact component={ClientManageUsers} />
                      <Route path="/client/add-project/:subscription" exact component={ClientAddProject} />
                      <Route path="/client/add-user/:subscription" exact component={ClientAddUser} />
                      <Route path="/client/edit-project/:id" exact component={ClientEditProject} />
                      <Route path="/client/edit-user/:id" exact component={ClientEditUser} />

                      <Route path="/users" exact component={UserDashboard} />
                      <Route path="/users/change-password" exact component={UserChangePassword} />
                      <Route path="/users/api-access" exact component={ApiAccess} />
                      <Route path="/users/send-mail/:id/:recipient" exact component={SendMail} />
                      <Route path="/modules/cms/assets" exact component={CmsAssets} />
                      <Route path="/modules/cms/upload-asset" exact component={CmsUploadAsset} />
                      <Route path="/modules/cms/sections" exact component={CmsSections} />
                      <Route path="/modules/cms/groups" exact component={CmsGroups} />
                      <Route path="/modules/cms/add-group" exact component={CmsAddGroup} />
                      <Route path="/modules/cms/add-section" exact component={CmsAddSection} />
                      <Route path="/modules/cms/edit-section/:id" exact component={CmsEditSection} />
                      <Route path="/modules/cms/edit-group/:id" exact component={CmsEditGroup} />

                      <Route path="/modules/bms/videos" exact component={BmsVideos} />
                      <Route path="/modules/bms/add-video" exact component={BmsAddVideo} />
                      <Route path="/modules/bms/categories" exact component={BmsCategories} />
                      <Route path="/modules/bms/add-category" exact component={BmsAddCategory} />
                      <Route path="/modules/bms/edit-category/:id" exact component={BmsEditCategory} />

                      <Route path="/modules/inv/templates" exact component={InvTemplates} />
                      <Route path="/modules/inv/add-template" exact component={InvAddTemplate} />
                      <Route path="/modules/inv/edit-template/:id" exact component={InvEditTemplate} />
                      <Route path="/modules/inv/groups" exact component={InvGroups} />
                      <Route path="/modules/inv/add-group" exact component={InvAddGroup} />
                      <Route path="/modules/inv/edit-group/:id" exact component={InvEditGroup} />
                      <Route path="/modules/inv/items" exact component={InvItems} />
                      <Route path="/modules/inv/add-item/:group" exact component={InvAddItem} />
                      <Route path="/modules/inv/edit-item/:group/:id" exact component={InvEditItem} />
                      <Route path="/modules/inv/engagements" exact component={InvEngagements} />
                      <Route path="/modules/inv/item-details/:id" exact component={InvItemDetails} />
                      <Route path="/modules/inv/hottest-items" exact component={HottestItems} />

                      <Route path="/modules/crm/customers" exact component={CrmCustomers} />
                      <Route path="/modules/crm/add-customer" exact component={CrmAddCustomer} />
                      <Route path="/modules/crm/edit-customer/:id" exact component={CrmEditCustomer} />
                      <Route path="/modules/crm/customer-details/:id" exact component={CrmCustomerDetails} />
                      <Route path="/modules/crm/groups" exact component={CrmGroups} />
                      <Route path="/modules/crm/add-group" exact component={CrmAddGroup} />
                      <Route path="/modules/crm/edit-group/:id" exact component={CrmEditGroup} />

                      <Route path="/modules/com/forms" exact component={ComForms} />
                      <Route path="/modules/com/all-submissions" exact component={ComAllSubmissions} />
                      <Route path="/modules/com/form-submissions/:id" exact component={ComFormSubmissions} />
                      <Route path="/modules/com/edit-form/:id" exact component={ComEditForm} />
                      <Route path="/modules/com/add-form" exact component={ComAddForm} />
                      <Route path="/modules/com/leads" exact component={ComLeads} />
                      <Route path="/modules/com/add-lead" exact component={ComAddLead} />
                      <Route path="/modules/com/edit-lead/:id" exact component={ComEditLead} />
                      <Route path="/modules/com/lead-details/:id" exact component={ComLeadDetails} />
                      <Route path="/modules/com/scoring" exact component={ComScoring} />
                      <Route path="/modules/com/add-scoring" exact component={ComAddScoring} />
                      <Route path="/modules/com/edit-scoring/:id" exact component={ComEditScoring} />
                      <Route path="/modules/com/scoring-leads/:id" exact component={ComScoringLeads} />
                      <Route path="/modules/com/set-score/:id" exact component={ComSetScore} />

                      <Route path="/modules/asm/assets" exact component={AsmAssets} />
                      <Route path="/modules/asm/folders" exact component={AsmFolders} />
                      <Route path="/modules/asm/upload-asset/:folder" exact component={AsmUploadAsset} />
                      <Route path="/modules/asm/edit-folder/:id" exact component={AsmEditFolder} />
                      <Route path="/modules/asm/files/:folder" exact component={AsmFiles} />
                      <Route path="/modules/asm/manage-tags/:tagType/:typeId" exact component={AsmManageTags} />

                      <Route path="/modules/sch/events" exact component={SchEvents} />
                      <Route path="/modules/sch/add-event" exact component={SchAddEvent} />
                      <Route path="/modules/sch/edit-event/:id" exact component={SchEditEvent} />
                      <Route path="/modules/sch/event-details/:id" exact component={SchEventDetails} />
                    </Switch>
                    <Clearfix />
                    <p className="tc">© 2022 The Core </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NotificationContainer />
          {/*{state.loading ? <Loader /> : null}*/}
          {state.redirect.status ? <MyRedirect /> : ''}
        </ScrollToTop>
      </LoadingOverlay>
    </BrowserRouter>
  );
}
