import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import $ from 'jquery';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';

/**
 * A <select> dropdown list of all clients
 * @param defaultValue
 * @param className
 * @param id
 * @param initialValue
 * @param state
 * @param setState
 * @param label
 * @returns {JSX.Element}
 * @constructor
 */
function ClientDropdown({ defaultValue, className, id, initialValue = null, state, setState, label }) {
  const dispatch = useDispatch();

  useEffect(() => {
    M.FormSelect.init($(`#${id}`), {
      classes: 'select-wrapper rounded nEngrave',
    });
  });

  const [clients, updateClients] = useState([]);

  useEffect(() => {
    __.showLoadingBar(dispatch);
    __.get(`/clients`).then((response) => {
      __.hideLoadingBar(dispatch);

      if (response.error) {
        __.displayErrorMessage(response.message);
        return;
      }

      updateClients(response.data);
      // console.log("list", list)
    });
  }, []);

  return (
    <div className={`file-field selectpicker input-field ${className} selectMenu`}>
      <select
        id={id}
        className={className}
        value={defaultValue}
        onChange={(e) => {
          setState({ ...state, [id]: e.target.value });
        }}
        data-testid={id}
      >
        {initialValue ? (
          <option value={initialValue.key}>
            {' '}
            {'   '}
            {initialValue.value}
          </option>
        ) : null}
        {clients.map((client) => (
          <option key={client.id} value={client.id}>
            {' '}
            {client.firstname} {client.lastname}
          </option>
        ))}
      </select>
      <label>{label}</label>
    </div>
  );
}

ClientDropdown.propTypes = {
  initialValue: PropTypes.shape({ key: PropTypes.string, value: PropTypes.string }),
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
};

export default ClientDropdown;
