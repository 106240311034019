import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import M from 'materialize-css';
import $ from 'jquery';
import Text from '../ui/Text';

function SideMenu({ menu, className = '' }) {
  useEffect(() => {
    M.Collapsible.init($('.collapsible'), {
      onOpenStart(element) {
        $(element).find('.main-icon').text('remove');
        $(element).find('span').addClass('in');
        $(element).find('a').addClass('light-blue-text');
      },

      onCloseStart(element) {
        $(element).find('.main-icon').text('add');
        $(element).find('span').removeClass('in');
        $(element).find('a').removeClass('light-blue-text');
      },
    });
  }, []);

  return (
    <div className={`navs ${className}`}>
      <ul className="collapsible">
        {menu.map((item) => (
          <li id={item.id} key={item.id} className="mv3">
            <div className="collapsible-header">
              <a className="f4">
                {item.name.toLowerCase()}
                <span className="circular-button right">
                  <i className="material-icons main-icon tiny icon-position right">add</i>
                </span>
              </a>
            </div>
            <div className="collapsible-body pv2">
              <ul>
                {item.subMenu.map((child, i) => (
                  <li key={i} className="mv3">
                    <NavLink to={child.route} activeClassName="open" className="collapsible-sub-menu f5">
                      <b>
                        <i className="material-icons" style={{ fontSize: '10px' }} /> {child.name.toLowerCase()}
                      </b>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

SideMenu.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      icon: PropTypes.string,
      name: PropTypes.string,
      subMenu: PropTypes.arrayOf(
        PropTypes.shape({
          route: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    })
  ).isRequired,
};

export default SideMenu;
