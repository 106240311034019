import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CopyText from 'react-copy-text';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import DataTable from '../../../ui/DataTable';
import ModalButton from '../../../ui/ModalButton';
import Modal from '../../../ui/Modal';
import UploadAsset from './UploadAsset';
import config from '../../../utilities/config';

const Assets = () => {
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, updateLoading] = useState(true);
  const [instance, setInstance] = useState();

  const state = useSelector((store) => store);
  const dispatch = useDispatch();

  useEffect(async () => {
    __.showLoadingBar(dispatch);

    try {
      const response = await __.get(`/cms/assets?project=${localStorage.getItem('project')}&type=video`);
      if (response.status === 200) {
        updateLoading(false);
        setVideos(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  useEffect(async () => {
    __.showLoadingBar(dispatch);

    try {
      const response = await __.get(`/cms/assets?project=${localStorage.getItem('project')}&type=image`);
      if (response.status === 200) {
        updateLoading(false);
        setImages(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const deleteItem = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        const response = await __.delete(`/cms/assets?id=${id}`);
        if (response.status === 204) {
          __.hideLoadingBar(dispatch);
          __.displaySuccessMessage('Item deleted successfully');
          setReload(!reload);
        } else {
          throw Error('Could not delete!');
        }
      } catch (err) {
        __.hideLoadingBar(dispatch);
        __.displayErrorMessage(`API Error: ${err}`);
      }
    };

    __.confirmDelete(deleteFn);
  };

  const videoColumns = [
    {
      name: 'Videos',
      sortable: false,
      cell: (row) => (
        <p>
          {row.label}
          <br />
          {config.storageUrl + row.source}
        </p>
      ),
    },

    {
      name: '',
      sortable: false,
      right: true,
      cell: (row) => (
        <>
          <CircleLink
            icon="delete"
            tooltip="Delete Video"
            onClick={() => {
              deleteItem(row.id);
            }}
          />
        </>
      ),
    },
  ];

  const imageColumns = [
    {
      name: 'Images',
      sortable: false,
      cell: (row) => (
        <>
          <p>
            {row.label}
            <br />
            {config.storageUrl + row.source}
          </p>
        </>
      ),
    },

    {
      name: '',
      sortable: false,
      right: true,
      cell: (row) => (
        <>
          <CircleLink
            icon="delete"
            tooltip="Delete Image"
            onClick={() => {
              deleteItem(row.id);
            }}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Panel className="col s12 mv3">
        <Heading className="left">Assets</Heading>
        <ModalButton className="mv3 right" modalId="uploadAsset">
          Upload Asset
        </ModalButton>
        <Clearfix />
        <Text className="pb4">
          Manage your website's assets here. You can upload images and videos. The files you upload will be displayed in the categories below. Delete any file by clicking on the
          <i className="material-icons">delete</i> button.
        </Text>
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">Images</Heading>
        <DataTable loading={loading} columns={imageColumns} data={images} />
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">Videos</Heading>
        <DataTable loading={loading} columns={videoColumns} data={videos} />
      </Panel>
      <Modal id="uploadAsset" setInstance={setInstance}>
        <UploadAsset setReload={setReload} reload={reload} />
      </Modal>
    </>
  );
};

export default Assets;
