import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import SelectMenu from '../../../ui/SelectMenu';
import FileUpload from '../../../ui/FileUpload';
import Breadcrumb from '../../../ui/Breadcrumb';

const AddSection = ({ instance, reload, setReload }) => {
  const [loading, updateLoading] = useState(true);
  const [form, setForm] = useState({ project: localStorage.getItem('project') });
  const dispatch = useDispatch();

  const addCategory = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const response = await __.post(`/bms/categories`, form);
      if (response.status === 200) {
        __.displaySuccessMessage('Category added!');
        instance[0].close();
        setReload(!reload);
        // __.redirect(dispatch, '/modules/bms/categories');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  return (
    <>
      <Heading className="left">Add Category</Heading>
      <Clearfix />
      <form onSubmit={(e) => addCategory(e)}>
        <TextInput label="Category Name" className="col s12" state={form} isRequired id="name" setState={setForm} type="text" value={form.name} />
        <Submit className="col s12">Add Category</Submit>
      </form>
    </>
  );
};

export default AddSection;
