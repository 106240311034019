import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import CircleLink from '../../ui/CircleLink';
import CircleNavLink from '../../ui/CircleNavLink';
import DataTable from '../../ui/DataTable';
import timestamp from '../../utilities/timestamp';

const SubscriptionInvoiceList = ({ subscription }) => {
  const dispatch = useDispatch();
  const [reload, setReload] = useState(true);
  const [list, setList] = useState([]);
  const [loading, updateLoading] = useState(true);

  // get the invoices list
  useEffect(async () => {
    __.showLoadingBar(dispatch);
    try {
      const response = await __.get(`/invoices/subscription?id=${subscription}`);

      if (response.status === 200) {
        setList(response.data);
        __.hideLoadingBar(dispatch);
        updateLoading(false);
      } else {
        throw Error('Cannot get list');
      }
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage(`API Error: ${err}`);
    }
  }, [reload]);

  const deleteInvoice = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        const response = await __.delete(`/invoices?id=${id}`);
        if (response.status === 204) {
          __.hideLoadingBar(dispatch);
          __.displaySuccessMessage('Invoice deleted successfully');
          setReload(!reload);
        } else {
          throw Error('Could not delete!');
        }
      } catch (err) {
        __.hideLoadingBar(dispatch);
        __.displayErrorMessage(`API Error: ${err}`);
      }
    };

    __.confirmDelete(deleteFn);
  };

  const columns = [
    {
      name: 'Invoice ID',
      sortable: false,
      cell: (row) => (
        <p>
          {row.id} ({row.status})
        </p>
      ),
    },

    {
      name: 'Dates',
      sortable: false,
      cell: (row) => (
        <p>
          Due Date: {timestamp.unixToDate(row.dueDate)}
          <br /> Date Created: {timestamp.unixToDate(row.dateCreated)}
        </p>
      ),
    },

    {
      name: 'Amount',
      sortable: false,
      cell: (row) => <p>${row.amount}</p>,
    },

    {
      name: '',
      sortable: false,
      right: true,
      cell: (row) => (
        <>
          <CircleNavLink route={`/admin/invoice-details/${row.id}`} icon="developer_board" tooltip="Details" />

          <CircleNavLink tooltip="Edit Invoice" icon="edit" className="mh4" route={`/admin/edit-invoice/${row.id}`} />

          <CircleLink
            icon="delete"
            tooltip="Delete Invoice"
            onClick={() => {
              deleteInvoice(row.id);
            }}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <DataTable loading={loading} columns={columns} data={list} />
    </>
  );
};

export default SubscriptionInvoiceList;
