import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { useState } from 'react';

// theme css file
const DateRange = ({ setToProp, setFromProp }) => {
  const [to, setTo] = useState(new Date());
  const [from, setFrom] = useState(new Date());

  // new Date('Mon May 31 2021 23:59:59 GMT-0600 (Mountain Daylight Time)').getTime() / 1000;

  const selectionRange = {
    startDate: from,
    endDate: to,
    key: 'selection',
  };

  const updateSelection = (ranges) => {
    setTo(new Date(ranges.selection.endDate));
    setFrom(new Date(ranges.selection.startDate));
    setToProp(new Date(ranges.selection.endDate).getTime() / 1000);
    setFromProp(new Date(ranges.selection.startDate).getTime() / 1000);
  };

  return (
    <DateRangePicker
      ranges={[selectionRange]}
      onChange={(ranges) => {
        updateSelection(ranges);
      }}
      showMonthAndYearPickers={false}
    />
  );
};

export default DateRange;
