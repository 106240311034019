import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import DataTable from '../../../ui/DataTable';
import Datepicker from '../../../ui/Datepicker';
import ModalButton from '../../../ui/ModalButton';
import Modal from '../../../ui/Modal';
import UploadAsset from '../cms/UploadAsset';
import AddCategory from './AddCategory';
import DummyText from '../../../ui/DummyText';

const Categories = () => {
  const [reload, setReload] = useState(false);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [instance, setInstance] = useState();

  const dispatch = useDispatch();

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/bms/categories?project=${localStorage.getItem('project')}`);
      if (response.status === 200) {
        setLoading(false);
        setList(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const deleteCategory = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        await __.delete(`/bms/categories?id=${id}`);
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('Deleted successfully');
        setReload(!reload);
      } catch (err) {
        __.displayErrorMessage(err.response.data.message || err);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    };

    __.confirmDelete(deleteFn);
  };

  const columns = [
    {
      name: 'Name',
      cell: (row) => <p>{row.name}</p>,
    },

    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          <CircleNavLink route={`/modules/bms/edit-category/${row.id}`} icon="edit" tooltip="Edit" className="mh4" />
          <CircleLink onClick={() => deleteCategory(row.id)} icon="delete" tooltip="Delete" />
        </span>
      ),
    },
  ];

  return (
    <>
      <Panel className="col s12 mv3">
        <Heading className="left">Video Categories</Heading>
        <ModalButton className="mv3 right" modalId="addCat">
          Add Category
        </ModalButton>
        <Clearfix />
        <Text>The Broadcasting Categories organize the video content and layout of the projects Broadcast/Media page. Each Category will create a single row of content on the project website.</Text>

        <DataTable loading={loading} columns={columns} data={list} />
      </Panel>
      <Modal id="addCat" setInstance={setInstance}>
        <AddCategory instance={instance} reload={reload} setReload={setReload} />
      </Modal>
    </>
  );
};

export default Categories;
