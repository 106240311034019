import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function SmallButton({ route, icon, children, position = 'left', className = '' }) {
  return (
    <NavLink to={route} className={`dim ph2 pv1 mb1 mh1 dib nButton nEmboss light-blue-text ${position} ${className}`}>
      <i className="material-icons md-18">{icon}</i> {children.toLowerCase()}
    </NavLink>
  );
}

SmallButton.propTypes = {
  children: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
};

export default SmallButton;
