import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import jwt from 'jsonwebtoken';
import __ from './functions';

// this component keeps the user logged in after a refresh, by setting the loggedin global state to true on refresh.

const ValidateSession = () => {
  // redux
  const state = useSelector((store) => store);
  const dispatch = useDispatch();

  useEffect(async () => {
    // check if token exists
    if (localStorage.getItem('token')) {
      // validate the token
      try {
        await __.post('/auth/validate', {
          token: localStorage.getItem('token'),
        });

        const decodedData = jwt.decode(localStorage.getItem('token'));
        localStorage.setItem('firstname', decodedData.firstname);
        dispatch({
          type: 'SET_AUTH',
          payload: {
            loggedIn: true,
            userType: decodedData.usertype,
            userId: decodedData.userId,
            firstName: decodedData.firstname,
            email: decodedData.email,
          },
        });

        // if usertype is user, get user project and module perms
        if (decodedData.usertype === 'users') {
          const userResponse = await __.get(`/users?id=${decodedData.userId}`);
          const userProjectsResponse = await __.get(`/projects/subscription?id=${userResponse.data[0].subscription}`);
          const projectList = userProjectsResponse.data.map((project) => project.id);
          dispatch({
            type: 'SET_USER_PROJECT_PERMS',
            payload: projectList,
          });

          if (!localStorage.getItem('project')) {
            localStorage.setItem('project', projectList[0]);
          }

          if (!projectList.includes(Number(localStorage.getItem('project')))) {
            localStorage.clear();
            __.redirect(dispatch, '/logout');
          }
        }
      } catch (err) {
        localStorage.clear();
        __.displayErrorMessage(err.response.data.message);
        __.redirect(dispatch, '/');
      }
    } else {
      __.redirect(dispatch, '/');
    }
  }, [state.loggedIn]);

  return null;
};

export default ValidateSession;
