import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import DataTable from '../../../ui/DataTable';
import __ from '../../../utilities/functions';
import timestamp from '../../../utilities/timestamp';
import CircleLink from '../../../ui/CircleLink';
import CircleAnchorLink from '../../../ui/CircleAnchorLink';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Button from '../../../ui/Button';
import DummyText from '../../../ui/DummyText';
import ModalButton from '../../../ui/ModalButton';
import CircleNavLink from '../../../ui/CircleNavLink';
import Modal from '../../../ui/Modal';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';

const EditFolder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState({});

  //get folder details
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/asm/folders?id=${id}`);
      setLoading(false);
      setForm(response.data[0]);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const editFolder = async (e) => {
    e.preventDefault();

    try {
      __.hideLoadingBar(dispatch);
      const response = await __.patch(`/asm/folders`, form);
      if (response.status === 200) {
        __.displaySuccessMessage('The folder has been added successfully');
        __.redirect(dispatch, '/modules/asm/folders');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message || err);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  return (
    <>
      <Panel className="col s12 mv3">
        <Heading className="left">Manage Folders</Heading>
        <Clearfix />
        <form onSubmit={(e) => editFolder(e)}>
          <TextInput label="Folder Name" className="col s12 l6" state={form} isRequired id="folder" value={form.folder} setState={setForm} type="text" />
          <Submit className="col s12">Save Change</Submit>
        </form>
      </Panel>
    </>
  );
};

export default EditFolder;
