import React, {useEffect } from "react";
import {Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const MyRedirect = () => {
  const state = useSelector((store) => store);
  const dispatch = useDispatch();

  // this will cancel the redirect immediately after the first fire so it doesnt have to go into a loop
  useEffect(() => {
    dispatch({ type: "REDIRECT", payload: { status: false } });
  });
  // console.log("redirect state", state.redirect.destination)

  return (
    <Redirect to={state.redirect.destination} />
    // <Redirect to="/change_password" />
  );
};

export default MyRedirect;
