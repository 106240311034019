import PropTypes from 'prop-types';

function Submit({ children, className, position = 'left' }) {
  return (
    <div className={`input-field ${className}`}>
      <button className={`coreBtn dim ph4 pv2 mb2 mh2 dib nButton nEmboss light-blue-text ${position}`} type="submit">
        {children.toLowerCase()}
      </button>
    </div>
  );
}

Submit.propTypes = {
  className: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['left', 'right']),
};

export default Submit;
