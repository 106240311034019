import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import DataTable from '../../ui/DataTable';
import CircleNavLink from '../../ui/CircleNavLink';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';

const Clients = () => {
  const dispatch = useDispatch();
  const [loading, updateLoading] = useState(true);

  // get client details
  const [clients, setClients] = useState([]);
  useEffect(async () => {
    __.showLoadingBar(dispatch);
    try {
      const response = await __.get(`/clients`);
      if (response.status === 200) {
        __.hideLoadingBar(dispatch);
        updateLoading(false);
        setClients(response.data);
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage(`API Error: ${err}`);
    }
  }, []);

  const columns = [
    {
      name: 'Name',
      cell: (row) => (
        <p>
          {row.firstname} {row.lastname} <br /> <small>{row.company}</small>
        </p>
      ),
    },
    {
      name: 'Contact Details',
      cell: (row) => (
        <p>
          {row.email} <br /> {row.phone}
        </p>
      ),
    },

    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          <CircleNavLink route={`/admin/client-details/${row.id}`} icon="developer_board" tooltip="Details" className="mh4" />
        </span>
      ),
    },
  ];

  return (
    <Panel className="col s12 mv3">
      <Heading className="left">Clients</Heading>
      <Button route="/admin/add-client" position="right" className="mv3">
        Add Client
      </Button>
      <DataTable data={clients} columns={columns} loading={loading} />
    </Panel>
  );
};

export default Clients;
