import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Submissions from '../../components/Submissions';
import __ from '../../../utilities/functions';
import Breadcrumb from '../../../ui/Breadcrumb';

const FormSubmissions = () => {
  const { id } = useParams();
  const [details, setDetails] = useState({});
  const dispatch = useDispatch();

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/com/forms?id=${id}`);
      if (response.status === 200) {
        setDetails(response.data[0]);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const breadcrumb = [
    { label: 'Forms', route: '/modules/com/forms' },
    { label: 'Form Submissions', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />

      <Panel className="col s12 mv3">
        <Heading className="left">Form Submissions ({details.label})</Heading>
        <Clearfix />
        <Submissions scope="form" scopeId={id} showProfileButton />
      </Panel>
    </>
  );
};

export default FormSubmissions;
