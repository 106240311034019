import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import jwt from 'jsonwebtoken';
import TextInput from './ui/TextInput';
import Heading from './ui/Heading';
import Submit from './ui/Submit';
import __ from './utilities/functions';

function Login() {
  const dispatch = useDispatch();
  const [form, setForm] = useState({});

  async function submitForm(e) {
    e.preventDefault();
    __.showLoadingBar(dispatch);
    try {
      const response = await __.post('/auth/login', {
        email: form.email,
        password: form.password,
      });

      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        const decodedData = jwt.decode(response.data.token);
        localStorage.setItem('firstname', decodedData.firstname);
        dispatch({
          type: 'SET_AUTH',
          payload: {
            loggedIn: true,
            userType: decodedData.usertype,
            userId: decodedData.userId,
            firstName: decodedData.firstname,
            email: decodedData.email,
          },
        });

        // if usertype is user, get user project and module perms
        if (decodedData.usertype === 'users') {
          const userResponse = await __.get(`/users?id=${decodedData.userId}`);
          const userProjectsResponse = await __.get(`/projects/subscription?id=${userResponse.data[0].subscription}`);
          const projectList = userProjectsResponse.data.map((project) => project.id);
          dispatch({
            type: 'SET_USER_PROJECT_PERMS',
            payload: projectList,
          });

          localStorage.setItem('project', projectList[0]);
        }

        __.hideLoadingBar(dispatch);
        __.redirect(dispatch, `/${decodedData.usertype}`);
      }
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage(err.response.data.message);
      // console.log('catch', err);
    }
  }

  return (
    <>
      <div className="row">
        <div className="col s12 l6 offset-l3 m6 offset-m3 xl4 offset-xl4">
          <Heading className="tc mt7">TheCORE Login</Heading>
          <form
            onSubmit={(e) => {
              submitForm(e);
            }}
          >
            <TextInput label="Email" className="col s12 mv4" state={form} isRequired id="email" setState={setForm} type="email" value={form.email} />
            <TextInput label="Password" className="col s12 mv4" state={form} isRequired id="password" setState={setForm} type="password" value={form.password} />
            <Submit className="col s12 no-padding-right" position="right">
              Login
            </Submit>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
