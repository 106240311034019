import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function CircleLink({ href = '#', icon, className = '', tooltip, onClick }) {
  return (
    <a
      href={href}
      className={`btn-floating btn-small circle_btn_hamb light-blue-text ${className}`}
      onClick={(e) => {
        onClick(e);
      }}
    >
      <i className="material-icons left" data-tip={tooltip}>
        {icon}
      </i>
    </a>
  );
}

export default CircleLink;
