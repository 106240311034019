import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import DataTable from '../../../ui/DataTable';

const Sections = () => {
  const [reload, setReload] = useState(false);
  const [loading, updateLoading] = useState(true);
  const [list, setList] = useState([]);

  const state = useSelector((store) => store);
  const dispatch = useDispatch();

  useEffect(async () => {
    __.showLoadingBar(dispatch);

    try {
      const response = await __.get(`/cms/sections?project=${localStorage.getItem('project')}`);
      if (response.status === 200) {
        updateLoading(false);
        setList(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const deleteSection = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        const response = await __.delete(`/cms/sections?id=${id}`);
        if (response.status === 204) {
          __.hideLoadingBar(dispatch);
          __.displaySuccessMessage('Section deleted successfully');
          setReload(!reload);
        } else {
          throw Error('Could not delete!');
        }
      } catch (err) {
        __.hideLoadingBar(dispatch);
        __.displayErrorMessage(`API Error: ${err}`);
      }
    };

    __.confirmDelete(deleteFn);
  };

  const columns = [
    {
      name: 'Name',
      sortable: false,
      cell: (row) => (
        <p>
          {row.name}
          <br />
          ID: {row.id}
        </p>
      ),
    },

    {
      name: 'Label',
      sortable: false,
      cell: (row) => <p>{row.label}</p>,
    },

    {
      name: '',
      sortable: false,
      right: true,
      cell: (row) => (
        <>
          <CircleNavLink route={`/modules/cms/edit-section/${row.id}`} icon="edit" tooltip="Edit Section" className="mh4" />

          <CircleLink
            icon="delete"
            tooltip="Delete Section"
            onClick={() => {
              deleteSection(row.id);
            }}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Panel className="col s12 mv3">
        <Heading className="left">Sections</Heading>
        <Button route="/modules/cms/add-section" position="right" className="mv3">
          Add Section
        </Button>
        <Clearfix />
        <Text>
          Content on your website is divided into sections. These sections are reusable code blocks that can be placed on multiple pages. A page is composed of a combination of these sections.{' '}
        </Text>
        <Text>Here you can see an overview of all available sections. You can add new sections, modify and delete existing sections.</Text>
        <DataTable loading={loading} columns={columns} data={list} />
      </Panel>
    </>
  );
};

export default Sections;
