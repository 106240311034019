import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import DataTable from '../../ui/DataTable';
import CircleNavLink from '../../ui/CircleNavLink';
import timestamp from '../../utilities/timestamp';

const ClientSubscriptionList = ({ client }) => {
  const dispatch = useDispatch();
  const [subs, setSubs] = useState([]);
  const [clientData, setClientData] = useState({});
  const [loading, updateLoading] = useState(true);

  // get data from api
  useEffect(async () => {
    __.showLoadingBar(dispatch);
    try {
      const response = await __.get(`/subscriptions/client?id=${client}`);
      if (response.status === 200) {
        setSubs(response.data);
        __.hideLoadingBar(dispatch);
      }
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage('API Error:', err);
    }
  }, []);

  // // get client details
  useEffect(async () => {
    __.showLoadingBar(dispatch);
    try {
      const response = await __.get(`/clients?id=${client}`);
      if (response.status === 200) {
        setClientData(response.data[0]);
        updateLoading(false);
        __.hideLoadingBar(dispatch);
      }
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage('API Error:', err);
    }
  }, []);

  // prepare table columns
  const columns = [
    {
      name: 'Client',
      sortable: false,
      cell: () => (
        <p>
          {clientData.firstname} {clientData.lastname} <br />
          <small>{clientData.email}</small>
        </p>
      ),
    },

    {
      name: 'Dates',
      sortable: false,
      cell: (row) => (
        <p>
          Start Date: {timestamp.unixToDate(row.startDate)} <br />
          Renewal Due: {timestamp.unixToDate(row.endDate)}
        </p>
      ),
    },

    {
      name: 'Status (Core ID)',
      sortable: false,
      cell: (row) => (
        <p>
          {row.status} <br />
          <small>Core ID: {row.coreId}</small>
        </p>
      ),
    },

    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          <CircleNavLink route={`/admin/subscription-details/${row.id}`} icon="developer_board" tooltip="Details" className="mh4" />
        </span>
      ),
    },
  ];

  return (
    <>
      <DataTable loading={loading} columns={columns} data={subs} />
    </>
  );
};

export default ClientSubscriptionList;
