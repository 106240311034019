import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../../utilities/functions';
import getInventoryDetails from '../../../utilities/getInventoryDetails';
import getCustomerDetails from '../../../utilities/getCustomerDetails';
import getLeadDetails from '../../../utilities/getLeadDetails';
import CircleLink from '../../../ui/CircleLink';
import CircleNavLink from '../../../ui/CircleNavLink';
import Text from '../../../ui/Text';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Button from '../../../ui/Button';
import DataTable from '../../../ui/DataTable';

const Engagements = () => {
  const dispatch = useDispatch();
  const [customerEngagements, setCustomerEngagements] = useState([]);
  const [leadEngagements, setLeadEngagements] = useState([]);
  const [reload, setReload] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/inv/tags?tagType=customer`);
      if (response.status === 200) {
        setLoading(false);
        setCustomerEngagements(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/inv/tags?tagType=lead`);
      if (response.status === 200) {
        setLoading(false);
        setLeadEngagements(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const deleteEngagement = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        const response = await __.delete(`/inv/tags?id=${id}`);
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('Deleted successfully');
        setReload(!reload);
      } catch (err) {
        __.displayErrorMessage(err.response.data.message || err);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    };

    __.confirmDelete(deleteFn);
  };

  const customerColumns = [
    {
      name: 'Inventory',
      cell: async (row) => {
        const details = await getInventoryDetails(row.inventory);
        return <p>{details.item}</p>;
      },
    },
    {
      name: 'Customer',
      cell: async (row) => {
        const details = await getCustomerDetails(row.typeId);
        return (
          <p>
            {details.firstname} {details.lastname}
          </p>
        );
      },
    },

    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          <CircleNavLink route={`/view-details/${row.id}`} icon="developer-board" tooltip="Details" className="mh4" />
          <CircleLink onClick={() => deleteEngagement(row.id)} icon="delete" tooltip="Delete" />
        </span>
      ),
    },
  ];

  const leadColumns = [
    {
      name: 'Inventory',
      cell: async (row) => {
        const details = await getInventoryDetails(row.inventory);
        return <p>{details.item}</p>;
      },
    },
    {
      name: 'Lead',
      cell: async (row) => {
        const details = await getLeadDetails(row.typeId);
        return (
          <p>
            {details.firstname} {details.lastname}
          </p>
        );
      },
    },

    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          <CircleNavLink route={`/view-details/${row.id}`} icon="developer-board" tooltip="Details" className="mh4" />
          <CircleLink onClick={() => deleteEngagement(row.id)} icon="delete" tooltip="Delete" />
        </span>
      ),
    },
  ];

  return (
    <>
      <Panel className="col s12 mv3">
        <Heading className="left">Inventory Engagements</Heading>
        <Clearfix />
        <Text>Description here</Text>
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">With Customers</Heading>
        <Clearfix />
        <DataTable data={customerEngagements} columns={customerColumns} loading={loading} />
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">With Leads</Heading>
        <Clearfix />
        <DataTable data={leadEngagements} columns={leadColumns} loading={loading} />
      </Panel>
    </>
  );
};

export default Engagements;
