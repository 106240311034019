import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function FolderIcon({ route, icon, children, position = 'left', className = '', title = null }) {
  const foldername = children.length > 10 ? `${children.substr(0, 10)}...` : children;

  return (
    <NavLink to={route} className={`dim ph4 pv3 mb2 mh2 dib nButton nEmboss light-blue-text tc ${position} ${className}`} style={{ borderRadius: '15px' }} title={title}>
      <i className="material-icons light-blue-text" style={{ fontSize: '60px' }}>
        {icon}
      </i>
      <br />
      {foldername.toLowerCase()}
    </NavLink>
  );
}

FolderIcon.propTypes = {
  children: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
};

export default FolderIcon;
