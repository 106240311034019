import { useEffect } from 'react';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import $ from 'jquery';

function SelectMenu({ list, defaultValue, className, id, label = null, state, setState, valueKey, idKey = 'id', labelValue = 0 }) {
  useEffect(() => {
    M.FormSelect.init($(`#${id}`), {
      classes: 'select-wrapper rounded nEngrave',
    });
  });

  return (
    <div className={`file-field selectpicker input-field ${className} selectMenu`}>
      <select
        id={id}
        value={defaultValue}
        onChange={(e) => {
          setState({ ...state, [id]: e.target.value });
        }}
        data-testid={id}
      >
        {label ? <option value={labelValue}>{`     ${label}`}</option> : ''}
        {list.map((option) => (
          <option key={option[idKey]} value={option[idKey]}>
            {`     ${option[valueKey]}`}
          </option>
        ))}
      </select>
      {/*<label className="no-padding-left ml0">{label}</label>*/}
    </div>
  );
}

// SelectMenu.propTypes = {
//   list: PropTypes.arrayOf(
//     PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })
//   ).isRequired,
//   initialValue: PropTypes.shape({
//     key: PropTypes.string,
//     value: PropTypes.string,
//   }),
//   onChange: PropTypes.func.isRequired,
//   className: PropTypes.string,
//   id: PropTypes.string.isRequired,
//   defaultValue: PropTypes.any,
//   label: PropTypes.string.isRequired,
// };

export default SelectMenu;
