import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import __ from '../../utilities/functions';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';
import CircleLink from '../../ui/CircleLink';
import ClientSubscriptionList from '../components/ClientSubscriptionList';
import InvoiceList from '../components/InvoiceList';
import TransactionList from '../components/TransactionList';
import Breadcrumb from '../../ui/Breadcrumb';
import Clearfix from '../../ui/Clearfix';
import timestamp from '../../utilities/timestamp';
import SubscriptionInvoiceList from '../components/SubscriptionInvoiceList';
import ClickButton from '../../ui/ClickButton';

const SubscriptionDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [details, setDetails] = useState({ modules: [] });
  const [reload, setReload] = useState(true);

  useEffect(async () => {
    __.showLoadingBar(dispatch);
    try {
      const response = await __.get(`/subscriptions?id=${id}`);
      if (response.status === 200) {
        setDetails(response.data[0]);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const breadcrumb = [
    { label: 'Subscriptions', route: '/admin/subscriptions' },
    { label: 'Subscription Details', route: '#' },
  ];

  const activateSubscription = async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.patch('/subscriptions/status', {
        id,
        status: 'active',
      });
      if (response.status === 204) {
        __.displaySuccessMessage('Subscription activated successfully');
        setReload(!reload);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const deactivateSubscription = async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.patch('/subscriptions/status', {
        id,
        status: 'inactive',
      });
      if (response.status === 204) {
        __.displaySuccessMessage('Subscription activated successfully');
        setReload(!reload);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  return (
    <div>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Subscription Details</Heading>

        <Clearfix />
        <p className="no-margin-top">
          Core ID: {details.coreId} <br />
          Start Date: {timestamp.unixToDate(details.startDate)}, Renewal Date: {timestamp.unixToDate(details.endDate)} <br />
          Max Projects: {details.maxProjects}, Max Users: {details.maxUsers} <br />
          Status: {_.upperFirst(details.status)}
        </p>
      </Panel>

      <Panel className="col s12 mv3">
        <Heading className="left">Invoices</Heading>
        {details.status === 'active' ? (
          <ClickButton onClick={() => deactivateSubscription()} className="right mv3">
            Deactivate Subscription
          </ClickButton>
        ) : (
          <ClickButton onClick={() => activateSubscription()} className="right mv3">
            Activate Subscription
          </ClickButton>
        )}
        <Clearfix />
        <SubscriptionInvoiceList subscription={id} />
      </Panel>
    </div>
  );
};

export default SubscriptionDetails;
