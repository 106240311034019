/**
 * this component can be used for both customer and lead profiles
 * to list out events from the scheduler tied to these accounts
 * typeIs is the id of the lead or customer
 * tagType is either lead or customer
 */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DataTable from '../../ui/DataTable';
import __ from '../../utilities/functions';
import timestamp from '../../utilities/timestamp';

const ProfileScheduledEvents = ({ typeId, tagType }) => {
  const dispatch = useDispatch();
  const [reload, setReload] = useState(true);

  const [loading, setLoading] = useState(true);
  // get customer events schedule
  const [events, setEvents] = useState([]);
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/sch/tags?typeId=${typeId}&tagType=${tagType}`);
      setLoading(false);
      setEvents(response.data);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const eventsColumn = [
    {
      name: 'Event',
      cell: (row) => <p>{row.eventDetails.event}</p>,
    },

    {
      name: 'Description',
      cell: (row) => <p>{row.eventDetails.description}</p>,
    },

    {
      name: 'Date and Time',
      cell: (row) => (
        <p>
          {timestamp.unixToDate(row.eventDetails.dateTime)} <br /> {timestamp.unixToTime(row.eventDetails.dateTime)}
        </p>
      ),
    },
  ];

  return <DataTable data={events} loading={loading} columns={eventsColumn} />;
};

export default ProfileScheduledEvents;
