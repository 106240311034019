import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import DataTable from '../../../ui/DataTable';
import TextInput from '../../../ui/TextInput';
import CodeEditor from '../../../ui/CodeEditor';
import Submit from '../../../ui/Submit';
import SelectMenu from '../../../ui/SelectMenu';
import FileUpload from '../../../ui/FileUpload';
import Breadcrumb from '../../../ui/Breadcrumb';
import config from '../../../utilities/config';

const UploadAsset = () => {
  const { folder } = useParams();
  const [loading, updateLoading] = useState(true);
  const [form, setForm] = useState({ folder, access: 'private' });

  const state = useSelector((store) => store);
  const dispatch = useDispatch();

  const uploadAsset = async (e) => {
    e.preventDefault();

    try {
      __.showLoadingBar(dispatch);
      const uploadData = new FormData();
      if (e.target.file) {
        uploadData.append('file', e.target.file.files[0]);
      }
      uploadData.append('label', form.label);
      uploadData.append('folder', folder);
      uploadData.append('access', form.access);

      const response = await __.post('/asm/assets', uploadData);
      if (response.status === 200) {
        __.displaySuccessMessage('Upload successful!');
        __.redirect(dispatch, '/modules/asm/assets');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Assets', route: '/modules/asm/assets' },
    { label: 'Folder', route: `/modules/asm/files/${folder}` },
    { label: 'Upload Asset', route: '#' },
  ];
  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Upload Asset</Heading>
        <Clearfix />
        <Text>
          Upload files to your dedicated cloud storage account. To upload files that can only be access by your Core logged in users, choose 'Private Access'. Uploading a file with 'Public Access'
          will make that file publicly available on the internet to anyone who has the download link. Do not use 'Public Access' for confidential or sensitive files.
        </Text>
        <form onSubmit={(e) => uploadAsset(e)}>
          <TextInput label="Label" className="col s12 l3" state={form} isRequired id="label" setState={setForm} type="text" value={form.label} />
          <Clearfix />
          <SelectMenu
            className="col s12 l2"
            id="access"
            state={form}
            setState={setForm}
            list={[
              { option: 'Private Access', value: 'private' },
              { option: 'Public Access', value: 'public' },
            ]}
            idKey="value"
            valueKey="option"
            defaultValue={form.access}
          />
          <Clearfix />
          <FileUpload label="choose file" id="file" className="col s12 l2" isRequired />
          <Submit className="col s12">Upload</Submit>
        </form>
      </Panel>
    </>
  );
};

export default UploadAsset;
