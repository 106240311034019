import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';
import CircleLink from '../../ui/CircleLink';
import ClientSubscriptionList from '../../admins/components/ClientSubscriptionList';
import InvoiceList from '../../admins/components/InvoiceList';
import TransactionList from '../../admins/components/TransactionList';
import Breadcrumb from '../../ui/Breadcrumb';
import Clearfix from '../../ui/Clearfix';
import timestamp from '../../utilities/timestamp';
import SubscriptionInvoiceList from '../../admins/components/SubscriptionInvoiceList';
import CircleNavLink from '../../ui/CircleNavLink';
import DataTable from '../../ui/DataTable';

const ManageUsers = () => {
  const { subscription } = useParams();
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const [reload, setReload] = useState(true);
  const [loading, updateLoading] = useState(true);

  const breadcrumb = [
    { label: 'Subscriptions', route: '/client/subscriptions' },
    { label: 'Subscriptions Details', route: `/client/subscription-details/${subscription}` },
    { label: 'Manage Users', route: '#' },
  ];

  const deleteUser = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        const response = await __.delete(`/users?id=${id}`);
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('User successfully deleted');
        setReload(!reload);
      } catch (err) {
        __.displayErrorMessage(err.response.data.message || err);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    };

    __.confirmDelete(deleteFn);
  };
  // get the projects
  useEffect(async () => {
    __.showLoadingBar(dispatch);
    try {
      const response = await __.get(`/users?subscription=${subscription}`);

      if (response.status === 200) {
        setList(response.data);
        __.hideLoadingBar(dispatch);
        updateLoading(false);
      } else {
        throw Error('Cannot get list');
      }
    } catch (err) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage(`API Error: ${err}`);
    }
  }, [reload]);

  const columns = [
    {
      name: 'User',
      sortable: false,
      cell: (row) => (
        <p>
          {row.firstname} {row.lastname}
        </p>
      ),
    },

    {
      name: 'Email',
      sortable: false,
      cell: (row) => <p>{row.email}</p>,
    },

    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          <CircleNavLink route={`/client/edit-user/${row.id}`} icon="edit" tooltip="Edit User" className="mh4" />
          <CircleLink onClick={() => deleteUser(row.id)} icon="delete" tooltip="Delete" />
        </span>
      ),
    },
  ];
  return (
    <div>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Manage Users</Heading>
        <Button route={`/client/add-user/${subscription}`} position="right" className="mv3">
          Add User
        </Button>
        <Clearfix />
        <DataTable loading={loading} columns={columns} data={list} />
      </Panel>
    </div>
  );
};

export default ManageUsers;
