import PropTypes from 'prop-types';
import { useEffect } from 'react';
import _ from 'lodash';
import $ from 'jquery';
import M from 'materialize-css';

function TextArea({ id, className, type, value, label, state, setState, isRequired, tooltip, disabled = false }) {
  useEffect(() => {
    // materialize fix
    if (!_.isEmpty(value)) {
      $('label').addClass('active');
    }

    // materialize tooltip
    M.Tooltip.init($('.tooltipped'), {});

    // enforce required field
    const inputElement = window.document.querySelector(`#${id}`);
    const disableForm = disabled ? inputElement.setAttribute('disabled', 'true') : null;
    return isRequired ? inputElement.setAttribute('required', 'true') : null;
  });

  return (
    <div className={`input-field ${className}`}>
      <textarea
        id={id}
        className="materialize-textarea textarea-inp"
        onChange={(e) => {
          setState({ ...state, [id]: e.target.value });
        }}
        value={value || ''}
        style={{ height: '300px', borderRadius: '10px !important' }}
      />
      <label htmlFor={id}>
        {label}{' '}
        {tooltip ? (
          <a className="tooltipped" data-position="top" data-tooltip={tooltip} style={{ cursor: 'pointer' }}>
            [?]
          </a>
        ) : null}
      </label>
    </div>
  );
}

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  isRequired: PropTypes.bool.isRequired,
  tooltip: PropTypes.any,
};

export default TextArea;
