/**
 * this component can be used for both customer and lead profiles
 * to list out inventory engagements tied to these accounts
 * typeIs is the id of the lead or customer
 * tagType is either lead or customer
 */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DataTable from '../../ui/DataTable';
import __ from '../../utilities/functions';
import timestamp from '../../utilities/timestamp';
import CircleLink from '../../ui/CircleLink';
import CircleNavLink from '../../ui/CircleNavLink';
import CircleAnchorLink from '../../ui/CircleAnchorLink';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import Clearfix from '../../ui/Clearfix';
import Button from '../../ui/Button';
import config from '../../utilities/config';

const ProfileDigitalAssets = ({ typeId, tagType }) => {
  const dispatch = useDispatch();
  const [reload, setReload] = useState(true);

  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState([]);
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/asm/tags?typeId=${typeId}&tagType=${tagType}`);
      setLoading(false);
      setAssets(response.data);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const detachAsset = (id) => {
    const detachFn = async () => {
      try {
        __.hideLoadingBar(dispatch);
        const response = await __.delete(`/asm/tags?id=${id}`);
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('Asset successfully detached');
        setReload(!reload);
      } catch (err) {
        __.displayErrorMessage(err.response.data.message || err);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    };

    __.confirmDelete(detachFn, 'Confirm Detachment', 'Do you want to detach this asset?');
  };

  const assetColumns = [
    {
      name: 'File Label',
      cell: (row) => (
        <p>
          {row.assetDetails.label} <br /> Uploaded: {timestamp.unixToDate(row.assetDetails.dateTime)}
        </p>
      ),
    },
    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          <CircleAnchorLink icon="cloud_download" tooltip="Download" className="mh3" href={`${config.storageUrl}${row.assetDetails.source}`} target="_blank" />
          <CircleLink onClick={() => detachAsset(row.id)} icon="remove_circle_outline" tooltip="Detach" />
        </span>
      ),
    },
  ];

  return (
    <>
      <Panel className="col s12 mv3">
        <Heading className="left">Digital Assets</Heading>
        <Button route={`/modules/asm/manage-tags/${tagType}/${typeId}`} position="right" className="mv3">
          Manage Assets
        </Button>
        <Clearfix />
        <DataTable data={assets} loading={loading} columns={assetColumns} />
      </Panel>
    </>
  );
};

export default ProfileDigitalAssets;
