import { useDispatch, useSelector } from 'react-redux';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import Clearfix from '../../ui/Clearfix';
import TransactionList from '../components/TransactionList';

const Transactions = () => {
  const state = useSelector((store) => store);

  return (
    <Panel className="col s12 mv3">
      <Heading className="left">Transactions</Heading>
      <Clearfix />
      <TransactionList client={state.userId} />
    </Panel>
  );
};

export default Transactions;
