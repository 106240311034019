import PropTypes from "prop-types";
import { useEffect } from "react";
import _ from "lodash";
import $ from "jquery";
import M from "materialize-css";

function TextInput({
  id,
  className,
  type,
  value,
  label,
  state,
  setState,
  isRequired,
  tooltip,
}) {
  useEffect(() => {
    // materialize fix
    if (!_.isEmpty(value)) {
      $("label").addClass("active");
    }

    // materialize tooltip
    M.Tooltip.init($(".tooltipped"), {});

    // enforce required field
    const inputElement = window.document.querySelector(`#${id}`);
    return isRequired ? inputElement.setAttribute("required", "true") : null;
  });

  return (
    <div className={`input-field ${className}`}>
      <input
        className="inp"
        id={id}
        type={type}
        value={value || ""}
        onChange={(e) => {
          setState({ ...state, [id]: e.target.value });
        }}
      />
      <label htmlFor={id}>
        {label}{" "}
        {tooltip ? (
          <a
            className="tooltipped"
            data-position="top"
            data-tooltip={tooltip}
            style={{ cursor: "pointer" }}
          >
            [?]
          </a>
        ) : null}
      </label>
    </div>
  );
}

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "email", "number", "password"]),
  label: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  isRequired: PropTypes.bool.isRequired,
  tooltip: PropTypes.any,
  value: PropTypes.any,
};

export default TextInput;
