/*eslint-disable*/
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';
import { locale } from 'moment/moment';
import __ from '../../../utilities/functions';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import SubHeading from '../../../ui/SubHeading';
import getCustomerDetails from '../../../utilities/getCustomerDetails';
import getLeadDetails from '../../../utilities/getLeadDetails';
import DataTable from '../../../ui/DataTable';
import Notes from '../../components/Notes';
import ModalButton from '../../../ui/ModalButton';
import Modal from '../../../ui/Modal';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import timestamp from '../../../utilities/timestamp';
import ProfileInventoryEngagements from '../../components/ProfileInventoryEngagements';
import ProfileScheduledEvents from '../../components/ProfileScheduledEvents';
import ProfileDigitalAssets from '../../components/ProfileDigitalAssets';
import Breadcrumb from '../../../ui/Breadcrumb';

const CustomerDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  // get the event details
  const [details, setDetails] = useState({});
  const [invitees, setInvitees] = useState([]);
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/sch/events?id=${id}`);
      setDetails(response.data[0]);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  // get the invitees
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/sch/tags?event=${id}`);
      setInvitees(response.data);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);
  const breadcrumb = [
    { label: 'Events', route: '/modules/sch/events' },
    { label: 'Event Details', route: '#' },
  ];
  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />

      <Panel className="col s12 mv3">
        <Heading className="left">Event Details: {details.event}</Heading>
        <Clearfix />
        <p>
          <b>Description: </b>
          {details.description}
        </p>
        <p>
          <b>Date and Time: </b>
          {timestamp.unixToDateTime(details.dateTime)}
        </p>
        <p>
          <b>Invitees:</b>
          {invitees.map((invitee) => {
            return (
              <span className="mh2">
                {invitee.inviteeDetails.firstname} {invitee.inviteeDetails.lastname} ({_.capitalize(invitee.tagType)})
              </span>
            );
          })}
        </p>
      </Panel>
    </>
  );
};

export default CustomerDetails;
