import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import DataTable from '../../../ui/DataTable';
import Text from '../../../ui/Text';
import DummyText from '../../../ui/DummyText';

const Forms = () => {
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/com/forms?project=${localStorage.getItem('project')}`);
      if (response.status === 200) {
        setLoading(false);
        setList(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const deleteForm = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        await __.delete(`/com/forms?id=${id}`);
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('Deleted successfully');
        setReload(!reload);
      } catch (err) {
        __.displayErrorMessage(err.response.data.message || err);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    };

    __.confirmDelete(deleteFn);
  };

  const columns = [
    {
      name: 'Form Label',
      cell: (row) => <p>{row.label}</p>,
    },

    {
      name: 'Form ID',
      cell: (row) => <p>{row.id}</p>,
    },

    {
      name: 'Notifications',
      cell: (row) => (
        <p>
          Phone: {row.notifySms}
          <br />
          Email: {row.notifyEmail}
        </p>
      ),
    },

    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          <CircleNavLink route={`/modules/com/form-submissions/${row.id}`} icon="fact_check" tooltip="Submissions" />
          <CircleNavLink route={`/modules/com/edit-form/${row.id}`} icon="edit" tooltip="Edit" className="mh4" />
          <CircleLink onClick={() => deleteForm(row.id)} icon="delete" tooltip="Delete" />
        </span>
      ),
    },
  ];

  return (
    <>
      <Panel className="col s12 mv3">
        <Heading className="left">Forms</Heading>
        <Button route="/modules/com/add-form" position="right" className="mv3">
          Add Form
        </Button>

        <Button route="/modules/com/all-submissions" position="right" className="mv3">
          All Submissions
        </Button>
        <Clearfix />
        <Text>
          Forms manage the different online communications “forms” that are used for lead generation. Each form captures and organizes the online submissions & downloads that create your lead list.
          Relational & traditional marketing leads can be manually loaded, allowing ALL your project leads to managed in on place, and be assigned to anyone on your team to manage.
        </Text>
        <DataTable loading={loading} columns={columns} data={list} />
      </Panel>
    </>
  );
};

export default Forms;
