import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useParams } from 'react-router';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import Breadcrumb from '../../../ui/Breadcrumb';
import Datepicker from '../../../ui/Datepicker';
import timestamp from '../../../utilities/timestamp';
import SubHeading from '../../../ui/SubHeading';
import TimePicker from '../../../ui/TimePicker';

const EditEvent = () => {
  const { id } = useParams();
  const [form, setForm] = useState({ id });
  const dispatch = useDispatch();
  const [date, setDate] = useState({ dateTime: timestamp.now() });
  const [time, setTime] = useState({ time: '12:00' });

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/sch/events?id=${id}`);
      if (response.status === 200) {
        setDate({ dateTime: response.data[0].dateTime });
        setTime({ time: timestamp.unixToTimeNoSeconds24(response.data[0].dateTime) });
        setForm(response.data[0]);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const editEvent = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const response = await __.patch(`/sch/events`, { ...form, dateTime: date.dateTime, time: time.time });
      if (response.status === 204) {
        __.displaySuccessMessage('Event updated!');
        __.redirect(dispatch, '/modules/sch/events');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Events', route: '/modules/sch/events' },
    { label: 'Edit Event', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Edit Event</Heading>
        <Clearfix />
        <form onSubmit={(e) => editEvent(e)}>
          <Clearfix />
          <SubHeading className="left">Event Details</SubHeading>
          <Clearfix />
          <TextInput label="Event Name" className="col s12 l3" state={form} isRequired id="event" setState={setForm} type="text" value={form.event} />
          <TextInput label="Description" className="col s12 l9" state={form} isRequired id="description" setState={setForm} type="text" value={form.description} />
          <Datepicker id="dateTime" state={date} setState={setDate} className="col s12 l4" label="Event Date" />
          <TimePicker label="Event Time (24-hour format)" className="col s12 l2" setState={setTime} state={time} id="time" />
          <Submit className="col s12">Save Changes</Submit>
          <Clearfix />
        </form>
      </Panel>
    </>
  );
};

export default EditEvent;
