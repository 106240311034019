import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function CircleAnchorLink({ href, icon, className = '', tooltip, target = '' }) {
  return (
    <a href={href} className={`btn-floating btn-small circle_btn_hamb light-blue-text ${className}`} target={target}>
      <i className="material-icons left" data-tip={tooltip}>
        {icon}
      </i>
    </a>
  );
}

export default CircleAnchorLink;
