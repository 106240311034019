import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import DataTable from '../../../ui/DataTable';
import Text from '../../../ui/Text';
import DummyText from '../../../ui/DummyText';

const Groups = () => {
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/inv/groups?project=${localStorage.getItem('project')}`);
      if (response.status === 200) {
        setLoading(false);
        setList(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const deleteGroup = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        await __.delete(`/inv/groups?id=${id}`);
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('Deleted successfully');
        setReload(!reload);
      } catch (err) {
        __.displayErrorMessage(err.response.data.message || err);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    };

    __.confirmDelete(deleteFn);
  };

  const columns = [
    {
      name: 'Name',
      cell: (row) => <p>{row.name}</p>,
    },

    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          <CircleNavLink route={`/modules/inv/edit-group/${row.id}`} icon="edit" tooltip="Edit" className="mh4" />
          <CircleLink onClick={() => deleteGroup(row.id)} icon="delete" tooltip="Delete" />
        </span>
      ),
    },
  ];

  return (
    <>
      <Panel className="col s12 mv3">
        <Heading className="left">Groups</Heading>
        <Button route="/modules/inv/add-group" position="right" className="mv3">
          Add Group
        </Button>
        <Clearfix />
        <Text>
          Groups allow you to organize your inventory in sections or groups. This functionality allows you to manage inventory sales in phases, floors, typology or whatever groupings that are required
          to help you better manage your inventory.
        </Text>
        <DataTable loading={loading} columns={columns} data={list} />
      </Panel>
    </>
  );
};

export default Groups;
