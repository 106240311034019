import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import __ from '../../../utilities/functions';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import Breadcrumb from '../../../ui/Breadcrumb';
import SubHeading from '../../../ui/SubHeading';
import Text from '../../../ui/Text';
import UsersSelect from '../../components/UsersSelect';

const EditLead = () => {
  const { id } = useParams();
  const [form, setForm] = useState({ project: localStorage.getItem('project') });
  const dispatch = useDispatch();

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/com/leads?id=${id}`);
      if (response.status === 200) {
        setForm(response.data[0]);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const editLead = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const response = await __.patch(`/com/leads`, form);
      if (response.status === 204) {
        __.displaySuccessMessage('Changes saved successfully!');
        __.redirect(dispatch, '/modules/com/leads');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Leads', route: '/modules/com/leads' },
    { label: 'Edit Lead', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">
          Edit Lead ({form.firstname} {form.lastname})
        </Heading>
        <Clearfix />
        <form onSubmit={(e) => editLead(e)}>
          <TextInput label="First Name" className="col s12 l3" state={form} isRequired id="firstname" setState={setForm} type="text" value={form.firstname} />
          <TextInput label="Last Name" className="col s12 l3" state={form} isRequired id="lastname" setState={setForm} type="text" value={form.lastname} />
          <TextInput label="Phone" className="col s12 l3" state={form} isRequired id="phone" setState={setForm} type="text" value={form.phone} />
          <TextInput label="Email" className="col s12 l3" state={form} isRequired id="email" setState={setForm} type="text" value={form.email} />

          <SubHeading className="left">Assign To User</SubHeading>
          <Clearfix />
          <Text>Assign this customer to a user. Once assigned, the customer will be visible under the 'My Customers' list of the user.</Text>

          <UsersSelect id="assignedTo" state={form} setState={setForm} className="col s12 l2" label="Assign to User" />

          <Submit className="col s12">Save Changes</Submit>
        </form>
      </Panel>
    </>
  );
};

export default EditLead;
