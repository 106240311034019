import { useEffect } from "react";
import { useDispatch } from "react-redux";
import __ from "./functions";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "LOGOUT" });
    __.redirect(dispatch, "/");
  }, []);

  return null;
};

export default Logout;
