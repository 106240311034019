import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import __ from '../../../utilities/functions';
import CircleNavLink from '../../../ui/CircleNavLink';
import CircleLink from '../../../ui/CircleLink';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Button from '../../../ui/Button';
import Clearfix from '../../../ui/Clearfix';
import DataTable from '../../../ui/DataTable';
import Text from '../../../ui/Text';
import DummyText from '../../../ui/DummyText';
import timestamp from '../../../utilities/timestamp';
import SubHeading from '../../../ui/SubHeading';

const Customers = () => {
  const state = useSelector((store) => store);

  const [list, setList] = useState([]);
  const [myCustomers, setMyCustomers] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [toDate, setToDate] = useState({ toDate: timestamp.now() });
  const [fromDate, setFromDate] = useState({ fromDate: timestamp.now() });

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/crm/customers?project=${localStorage.getItem('project')}`);
      if (response.status === 200) {
        setLoading(false);
        setList(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/crm/customers?assigned=${state.userId}`);
      if (response.status === 200) {
        setLoading(false);
        setMyCustomers(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const deleteCustomer = (id) => {
    const deleteFn = async () => {
      try {
        __.showLoadingBar(dispatch);
        await __.delete(`/crm/customers?id=${id}`);
        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('Deleted successfully');
        setReload(!reload);
      } catch (err) {
        __.displayErrorMessage(err.response.data.message || err);
      } finally {
        __.hideLoadingBar(dispatch);
      }
    };

    __.confirmDelete(deleteFn);
  };

  const columns = [
    {
      name: 'Name',
      cell: (row) => (
        <p>
          {row.firstname} {row.lastname}
        </p>
      ),
    },

    {
      name: 'Contact Details',
      cell: (row) => (
        <p>
          {row.email} <br /> {row.phone}
        </p>
      ),
    },

    {
      name: '',
      right: true,
      cell: (row) => (
        <span className="right">
          <CircleNavLink route={`/users/send-mail/${row.id}/customers`} icon="mail" tooltip="Send Mail" className="mh4" />
          <CircleNavLink route={`/modules/crm/edit-customer/${row.id}`} icon="edit" tooltip="Edit" />
          <CircleNavLink route={`/modules/crm/customer-details/${row.id}`} icon="developer_board" tooltip="Details" className="mh4" />
          <CircleLink onClick={() => deleteCustomer(row.id)} icon="delete" tooltip="Delete" />
        </span>
      ),
    },
  ];

  //get bulk customer data
  const getBulkCustomerData = async (ids) => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.post(`/crm/customers/batch`, { ids });
      if (response.status === 200) {
        setLoading(false);
        return response.data;
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  // using this as the wrapper function for the async getBulkCustomerData so that I dont have to pass async
  // function to the withSelectedFns
  const convertCustomerData = (ids) => {
    getBulkCustomerData(ids).then((data) => {
      __.jsonToCsv(JSON.stringify(data));
    });
  };

  // list of bulk functions available to this customers list
  const withSelectedFns = [
    {
      fn: convertCustomerData,
      label: 'Export To CSV',
      icon: 'list_alt',
    },
  ];

  return (
    <>
      <Panel className="col s12 mv3">
        <Heading className="left">Customers</Heading>
        <Button route="/modules/crm/add-customer" position="right" className="mv3">
          Add Customer
        </Button>
        <Clearfix />
        <Text>
          The Customer Relationship Management (CRM) module manages all your company's relationships and interactions with customers that have converted from your lead generation. Anyone on your team
          can view the complete customer history, profile, schedule, assigned inventory, sale & leasing documents and a full range of multi-media assets.
        </Text>
        <SubHeading className="left">My Customers</SubHeading>
        <Clearfix />
        <DataTable loading={loading} columns={columns} data={myCustomers} filters={['firstname', 'lastname', 'email', 'phone']} showDateRange withSelected withSelectedFns={withSelectedFns} />

        <SubHeading className="left">All Customers</SubHeading>
        <Clearfix />
        <DataTable loading={loading} columns={columns} data={list} filters={['firstname', 'lastname', 'email', 'phone']} showDateRange withSelected withSelectedFns={withSelectedFns} />
      </Panel>
    </>
  );
};

export default Customers;
