import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import __ from '../../../utilities/functions';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import Breadcrumb from '../../../ui/Breadcrumb';

const AddLead = () => {
  const [form, setForm] = useState({ project: localStorage.getItem('project'), assignedTo: 0 });
  const dispatch = useDispatch();

  const addLead = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const response = await __.post(`/com/leads`, form);
      if (response.status === 200) {
        __.displaySuccessMessage('Lead added!');
        __.redirect(dispatch, '/modules/com/leads');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Leads', route: '/modules/com/leads' },
    { label: 'Add Lead', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Add Lead</Heading>
        <Clearfix />
        <form onSubmit={(e) => addLead(e)}>
          <TextInput label="First Name" className="col s12 l3" state={form} isRequired id="firstname" setState={setForm} type="text" value={form.firstname} />
          <TextInput label="Last Name" className="col s12 l3" state={form} isRequired id="lastname" setState={setForm} type="text" value={form.lastname} />
          <Clearfix />
          <TextInput label="Phone" className="col s12 l2" state={form} isRequired id="phone" setState={setForm} type="text" value={form.phone} />
          <TextInput label="Email" className="col s12 l2" state={form} isRequired id="email" setState={setForm} type="text" value={form.email} />
          <Submit className="col s12">Add Lead</Submit>
        </form>
      </Panel>
    </>
  );
};

export default AddLead;
