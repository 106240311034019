import React from 'react';
import _ from 'lodash';

// eslint-disable-next-line react/prefer-stateless-function
class DTCheckbox extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.selected = props.selected;
    this.name = props.name;
    console.log(props);
  }

  render() {
    if (this.props.checked) {
      return (
        <div>
          <label>
            <input
              className="inp"
              type="checkbox"
              onChange={() => {
                this.props.onClick();
              }}
              checked
            />
            <span />
          </label>
        </div>
      );
    }
    return (
      <div>
        <label>
          <input
            className="inp"
            type="checkbox"
            onChange={() => {
              this.props.onClick();
            }}
          />
          <span />
        </label>
      </div>
    );
  }
}

export default DTCheckbox;
