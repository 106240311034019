import Clearfix from './Clearfix';

function Panel({ children, className }) {
  return (
    <div className={className}>
      <div className="main-body ph4">
        {children}
        <Clearfix />
      </div>
    </div>
  );
}

export default Panel;
