import { NotificationManager as Notification } from 'react-notifications';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import Papa from 'papaparse';
import config from './config';
import menuIndex from './menuIndices';

const functions = {
  stringToArray: (string, separator) => {
    const arr = [];
    const stringArr = string.split(separator);
    stringArr.map((char) => arr.push(char.trim()));

    return arr;
  },

  arrayToString: (array, joiner) => array.join(joiner),

  // this converts the JSON data into comma separated string
  parseJSON: (str) => {
    // convert to array
    let text = str;
    text = JSON.parse(text);
    return text.join(',');
  },

  // show loading bar
  showLoadingBar: (dispatch) => {
    dispatch({ type: 'SET_LOADING', payload: { value: true } });
  },

  // hide loading bar
  hideLoadingBar: (dispatch) => {
    dispatch({ type: 'SET_LOADING', payload: { value: false } });
  },

  displayErrorMessage: (message) => {
    if (message) {
      Notification.error(message);
    }
  },

  displaySuccessMessage: (message) => {
    Notification.success(message);
  },

  displayInfoMessage: (message) => {
    Notification.info(message);
  },

  redirect(dispatch, destination) {
    dispatch({ type: 'REDIRECT', payload: { status: true, destination } });
  },
  // api:GET
  async getFullcontact($url, data) {
    return axios({
      method: 'post',
      url: $url,
      data,
      headers: {
        Authorization: 'Bearer zaWkJS9RTWWgr0KmWzGSouAovrL7CUm0',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
      },
    });
  },

  // api:GET
  async get(endpoint) {
    const response = await axios({
      method: 'get',
      url: `${config.apiUrl}${endpoint}`,
      headers: {
        token: localStorage.getItem('token'),
        domain: localStorage.getItem('default_domain'),
      },
    });

    return response;
  },

  // get file
  async getFile(endpoint) {
    const response = await axios({
      method: 'get',
      url: `${config.apiUrl}${endpoint}`,
      headers: {
        token: localStorage.getItem('token'),
        domain: localStorage.getItem('default_domain'),
      },
    });

    return response;
  },

  async callApi(method, url, data = null) {
    return axios({
      method,
      url,
      data,
      headers: {
        token: localStorage.getItem('token'),
        domain: localStorage.getItem('default_domain'),
      },
    });
  },

  // api:POST
  async post(endpoint, data) {
    const response = await axios({
      method: 'post',
      url: `${config.apiUrl}${endpoint}`,
      data,
      headers: {
        token: localStorage.getItem('token'),
        domain: localStorage.getItem('default_domain'),
      },
    });

    return response;
  },

  // api: PATCH
  async patch(endpoint, data) {
    const response = await axios({
      method: 'patch',
      url: `${config.apiUrl}${endpoint}`,
      data,
      headers: {
        token: localStorage.getItem('token'),
        domain: localStorage.getItem('default_domain'),
      },
    });

    return response;
  },

  // api:: DELETE
  async delete(endpoint) {
    const response = await axios({
      method: 'delete',
      url: `${config.apiUrl}${endpoint}`,
      headers: {
        token: localStorage.getItem('token'),
        domain: localStorage.getItem('default_domain'),
      },
    });

    return response;
  },

  // delete confirmation dialogue
  confirmDelete(deleteFunction, title = null, message = null) {
    confirmAlert({
      title: title || 'Confirm Delete',
      message: message || 'Are you sure you want to delete this?',
      buttons: [
        {
          label: 'Yes!',
          onClick: () => {
            deleteFunction();
          },
        },
        {
          label: 'Nah!!',
          onClick: () => {
            this.displaySuccessMessage('¯\\_(ツ)_/¯');
          },
        },
      ],
    });
  },

  /// /call this funciton to update a list when an entry is deleted from it
  updateDelete(list, deletedId, updateList) {
    const newList = list.filter((item) => {
      if (item.id !== deletedId) {
        return true;
      }
      return false;
    });

    updateList(newList);
  },

  // get menu index
  getMenuIndex(menu) {
    return menuIndex[menu];
  },

  cad(amount) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'CAD' }).format(amount); // '$100.00'
  },

  // confirmation dialogue
  confirmAction(message, fn) {
    confirmAlert({
      title: 'Confirmation',
      message,
      buttons: [
        {
          label: 'Yes!',
          onClick: () => {
            fn();
          },
        },
        {
          label: 'No!!',
          onClick: () => {
            // this.displaySuccessMessage("Heh!");
          },
        },
      ],
    });
  },

  download({ filename, content, contentType }) {
    const element = document.createElement('a');
    element.setAttribute('href', `data:${contentType};charset=utf-8,${encodeURIComponent(content)}`);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  },

  jsonToCsv(jsonData) {
    const csv = Papa.unparse(jsonData);
    this.download({ filename: 'ExportedData.csv', content: csv, contentType: 'text/csv' });
    // window.location.href = `data:text/csv, filename="exportedData.csv";charset=utf-8,${csv}`;
    // console.log('we here', csv);
  },

  displayApiError(err) {
    if (err?.response?.data?.statusCode !== 406) {
      this.displayErrorMessage(err?.response?.data?.message);
    }
  },
};

export default functions;
