import { useEffect } from 'react';
import M from 'materialize-css';
import $ from 'jquery';
import timestamp from '../utilities/timestamp';

const TimePicker = ({ state, setState, id, label, className }) => {
  useEffect(() => {
    $('.timepickerLabel').addClass('active');
    M.Timepicker.init(document.querySelectorAll(`#${id}`), {
      defaultTime: 'now',
      twelveHour: false,
      onSelect: (hour, minute) => {
        // setState({ ...state, dueDate: timestamp.dateToUnix($(`#${id}`).val(), 'MMMM DD, YYYY') });

        setState({ ...state, time: `${hour}:${minute}` });
      },
    });
  }, []);

  return (
    <div className={`input-field ${className}`}>
      <input type="text" className="timepicker inp" id={id} value={state[id]} onChange={() => {}} />
      <label htmlFor={id} className="timepickerLabel">
        {label}
      </label>
    </div>
  );
};

export default TimePicker;
