import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import __ from '../../utilities/functions';
import SelectMenu from '../../ui/SelectMenu';

const CustomersSelect = ({ className, id, setState, state }) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);

  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/crm/customers?project=${localStorage.getItem('project')}`);
      if (response.status === 200) {
        setList(response.data);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  return <SelectMenu id={id} list={list} setState={setState} label="Customers" state={state} className={className} idKey="id" valueKey="firstname" defaultValue={state[id]} />;
};

export default CustomersSelect;
