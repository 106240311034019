import PropTypes from 'prop-types';
import { useEffect } from 'react';
import _ from 'lodash';
import $ from 'jquery';
import M from 'materialize-css';

function FileUpload({ id, className, value, label, isRequired }) {
  useEffect(() => {
    // materialize fix
    if (!_.isEmpty(value)) {
      $('label').addClass('active');
    }

    // materialize tooltip
    M.Tooltip.init($('.tooltipped'), {});

    // enforce required field
    const inputElement = window.document.querySelector(`#${id}`);
    return isRequired ? inputElement.setAttribute('required', 'true') : null;
  });

  return (
    <div className={`input-field file-field ${className}`}>
      <div className="coreBtn dim ph4 pv2 mb2 mh2 dib nButton nEmboss light-blue-text">
        <span>{label}</span>
        <input type="file" id={id} />
      </div>
      <div className="file-path-wrapper">
        <input className="file-path validate" type="text" />
      </div>
    </div>
  );
}

FileUpload.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  value: PropTypes.any,
};

export default FileUpload;
