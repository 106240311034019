import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import TextInput from '../../ui/TextInput';
import Submit from '../../ui/Submit';
import redirect from '../../utilities/redirect';
import Breadcrumb from '../../ui/Breadcrumb';
import Clearfix from '../../ui/Clearfix';

const ChangePassword = () => {
  const [form, setForm] = useState({});
  const dispatch = useDispatch();

  const changePassword = async (e) => {
    e.preventDefault();
    __.showLoadingBar(dispatch);
    if (form.newPassword !== form.confirmPassword) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage('Passwords do not match, please retry.');
      return;
    }

    try {
      const response = await __.patch('/auth/login', form);
      if (response.status === 200) {
        __.displaySuccessMessage('Password changed successfully!');
        __.redirect(dispatch, '/admin/dashboard');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  return (
    <>
      <Panel className="col s12 mv4">
        <Heading className="left">Change Password</Heading>
        <Clearfix />
        <form onSubmit={(e) => changePassword(e)}>
          <TextInput label="Old Password" className="col s12 l3" state={form} isRequired id="oldPassword" setState={setForm} type="password" value={form.oldPassword} />
          <TextInput label="New Password" className="col s12 l3" state={form} isRequired id="newPassword" setState={setForm} type="password" value={form.newPassword} />
          <TextInput label="Retype New Password" className="col s12 l3" state={form} isRequired id="confirmPassword" setState={setForm} type="password" value={form.confirmPassword} />
          <Submit className="col s12">Change Password</Submit>
        </form>
      </Panel>
    </>
  );
};

export default ChangePassword;
