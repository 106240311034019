/**
 * this component can be used for both customer and lead profiles
 * to list out inventory engagements tied to these accounts
 * typeIs is the id of the lead or customer
 * tagType is either lead or customer
 */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import DataTable from '../../ui/DataTable';
import __ from '../../utilities/functions';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import Clearfix from '../../ui/Clearfix';
import ModalButton from '../../ui/ModalButton';
import Modal from '../../ui/Modal';
import CustomersSelect from './CustomersSelect';
import Submit from '../../ui/Submit';
import LeadsSelect from './LeadsSelect';

const ItemEngagements = ({ id }) => {
  const dispatch = useDispatch();
  const [reload, setReload] = useState(true);
  const [form, setForm] = useState({ inventory: id });

  const [instance, setInstance] = useState();

  const [loading, setLoading] = useState(true);
  const [engagements, setEngagements] = useState([]);
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/inv/tags?item=${id}`);
      setLoading(false);
      setEngagements(response.data);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const addEngagement = async (e, type) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const response = await __.post(`/inv/tags`, {
        inventory: id,
        tagType: type,
        typeId: form[type],
      });
      if (response.status === 200) {
        setReload(!reload);
        __.displaySuccessMessage('Added successfully!');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const engagementsColumns = [
    {
      name: 'Inventory Item',
      cell: (row) => <p>{row.inventoryName}</p>,
    },

    {
      name: 'Profile',
      cell: (row) => (
        <p>
          {row.ownerDetails.firstname} {row.ownerDetails.lastname} ({_.capitalize(row.tagType)})
        </p>
      ),
    },
  ];

  return (
    <Panel className="col s12 mv3">
      <Heading className="left">Inventory Engagements</Heading>
      <ModalButton className="mv3 right" modalId="addCustomerEngagement">
        Add Customer Engagement
      </ModalButton>
      <ModalButton className="mv3 right" modalId="addLeadEngagement">
        Add Lead Engagement
      </ModalButton>
      <Clearfix />
      <DataTable data={engagements} loading={loading} columns={engagementsColumns} />

      <Modal id="addCustomerEngagement" setInstance={setInstance}>
        <p>Explanation here</p>
        <form
          onSubmit={(e) => {
            addEngagement(e, 'customer');
          }}
        >
          <CustomersSelect id="customer" className="col s12" state={form} setState={setForm} />
          <Submit className="col s12">Add Engagement</Submit>
        </form>
      </Modal>

      <Modal id="addLeadEngagement" setInstance={setInstance}>
        <p>Explanation here</p>
        <form
          onSubmit={(e) => {
            addEngagement(e, 'lead');
          }}
        >
          <LeadsSelect id="lead" className="col s12" state={form} setState={setForm} />
          <Submit className="col s12">Add Engagement</Submit>
        </form>
      </Modal>
    </Panel>
  );
};

export default ItemEngagements;
