import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function CircleNavLink({ route, icon, className = '', tooltip }) {
  return (
    <NavLink to={route} className={`btn-floating btn-small circle_btn_hamb light-blue-text ${className}`}>
      <i className="material-icons" data-tip={tooltip}>
        {icon}
      </i>
    </NavLink>
  );
}

CircleNavLink.propTypes = {
  route: PropTypes.string.isRequired,
};

export default CircleNavLink;
