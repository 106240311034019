import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import __ from '../../../utilities/functions';
import DataTable from '../../../ui/DataTable';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import Breadcrumb from '../../../ui/Breadcrumb';
import Panel from '../../../ui/Panel';
import CodeEditor from '../../../ui/CodeEditor';

const AddGroup = () => {
  const [sections, setSections] = useState();
  const dispatch = useDispatch();
  const [selectedSections, setSelectedSections] = useState([]);
  const [form, setForm] = useState({ name: '' });
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    try {
      const response = await __.get(`/cms/sections?project=${localStorage.getItem('project')}`);
      if (response.status === 200) {
        setSections(response.data);
        setLoading(false);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const addGroup = async (e) => {
    e.preventDefault();
    try {
      __.showLoadingBar(dispatch);
      const response = await __.post(`/cms/groups`, {
        name: form.name,
        sections: selectedSections,
        project: localStorage.getItem('project'),
      });
      if (response.status === 200) {
        __.displaySuccessMessage('Group added!');
        __.redirect(dispatch, '/modules/cms/groups');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const columns = [
    {
      name: 'Sections',
      sortable: false,
      selected: true,
      cell: (row) => (
        <p>
          {row.label} (ID: {row.id})
        </p>
      ),
    },
  ];

  const withSelectedFns = [
    {
      fn: () => {},
      label: null,
    },
  ];
  const breadcrumb = [
    { label: 'Groups', route: '/modules/cms/groups' },
    { label: 'Add Group', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />

      <Panel className="col s12 mv3">
        <Heading className="left">Add Group</Heading>
        <Clearfix />
        <form onSubmit={(e) => addGroup(e)}>
          <TextInput label="Group Name" className="col s6" state={form} isRequired id="name" setState={setForm} type="text" value={form?.name || ''} />
          <DataTable loading={loading} columns={columns} data={sections} withSelected withSelectedFns={withSelectedFns} captureSelectedIds setCapturedSelection={setSelectedSections} />

          <Submit className="col s12">Add</Submit>
        </form>
      </Panel>
    </>
  );
};

export default AddGroup;
