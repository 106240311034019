import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';
import { locale } from 'moment/moment';
import __ from '../../../utilities/functions';
import Panel from '../../../ui/Panel';
import Heading from '../../../ui/Heading';
import Clearfix from '../../../ui/Clearfix';
import Text from '../../../ui/Text';
import SubHeading from '../../../ui/SubHeading';
import getCustomerDetails from '../../../utilities/getCustomerDetails';
import getLeadDetails from '../../../utilities/getLeadDetails';
import DataTable from '../../../ui/DataTable';
import Notes from '../../components/Notes';
import ModalButton from '../../../ui/ModalButton';
import Modal from '../../../ui/Modal';
import TextInput from '../../../ui/TextInput';
import Submit from '../../../ui/Submit';
import ProfileInventoryEngagements from '../../components/ProfileInventoryEngagements';
import ItemEngagements from '../../components/ItemEngagements';
import Breadcrumb from '../../../ui/Breadcrumb';
import ProfileDigitalAssets from '../../components/ProfileDigitalAssets';
import SelectMenu from '../../../ui/SelectMenu';

const ItemDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [reload, setReload] = useState(true);

  // modal instance
  const [instance, setInstance] = useState();
  const [closeModal, setCloseModal] = useState(false);
  const [noteForm, setNoteForm] = useState({});

  // get the item details
  const [details, setDetails] = useState({ data: [] });
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/inv/items?id=${id}`);
      setDetails(response.data[0]);
      // console.log(response.data[0]);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  // get active engagements
  const [loading, setLoading] = useState(true);
  const [engagements, setEngagements] = useState([]);
  useEffect(async () => {
    try {
      __.showLoadingBar(dispatch);
      const response = await __.get(`/inv/tags?item=${id}`);
      response.data.map(async (data, i) => {
        let tagTypeDetails;
        if (data.tagType === 'customer') {
          tagTypeDetails = await getCustomerDetails(data.typeId);
          response.data[i].tagTypeDetails = tagTypeDetails;
        }
      });
      setLoading(false);

      setEngagements(response.data);
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, [reload]);

  const addNote = async (e) => {
    try {
      e.preventDefault();
      __.showLoadingBar(dispatch);
      const response = await __.post(`/notes`, {
        note: noteForm.note,
        addedBy: localStorage.getItem('firstname'),
        recipientId: id,
        recipientType: 'inventory',
      });
      if (response.status === 200) {
        setLoading(false);
        setReload(!reload);
        setNoteForm({});
        __.displaySuccessMessage('Note added successful! Close the pop up or add another note.');
        // instance.close();
      }
    } catch (err) {
      console.log('err', err);
      // __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const setAvailability = async (e) => {
    try {
      e.preventDefault();
      __.showLoadingBar(dispatch);
      const response = await __.patch(`/inv/items/status`, {
        id: details.id,
        status: details.status,
      });
      if (response.status === 204) {
        setLoading(false);
        setReload(!reload);
        __.displaySuccessMessage('Inventory status updated successfully!');
        // instance.close();
      }
    } catch (err) {
      console.log('err', err);
      // __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Items', route: '/modules/inv/items' },
    { label: 'Item Details', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv3">
        <Heading className="left">Inventory Item Details</Heading>
        <ModalButton className="mv3 right" modalId="manageAvailability">
          Manage Availability
        </ModalButton>
        <Clearfix />
        <SubHeading className="left">Item: {details.item}</SubHeading>
        <Clearfix />
        <Text>
          <b>Cost:</b> {__.cad(details.cost)}{' '}
          {Object.keys(details.data).map((key, i) => (
            <span key={i}>
              <b> {_.capitalize(key)}:</b> {details.data[key]}
            </span>
          ))}
        </Text>
      </Panel>

      <ItemEngagements id={id} />

      <ProfileDigitalAssets typeId={id} tagType="inventory" />

      <Notes id={id} recipientType="inventory" parentReload={reload} />

      <Modal id="addNote" setInstance={setInstance}>
        <form onSubmit={(e) => addNote(e)}>
          <TextInput label="Note" className="col s12" state={noteForm} isRequired id="note" setState={setNoteForm} value={noteForm.note} type="text" />
          <Submit className="col s12 l3">Add Note</Submit>
        </form>
      </Modal>

      <Modal id="manageAvailability" setInstance={setInstance}>
        <Heading className="left">Manage Availability</Heading>
        <Clearfix />

        <p>Here you can set the availability of an item to Available, Hold or Sold. </p>
        <form onSubmit={(e) => setAvailability(e)}>
          <SelectMenu
            list={[
              { id: 0, name: 'Available' },
              { id: 1, name: 'Hold' },
              { id: 2, name: 'Sold' },
            ]}
            state={details}
            setState={setDetails}
            id="status"
            className="col s12 l4"
            idKey="id"
            valueKey="name"
            defaultValue={String(details.status)}
            label="Choose Availability"
            labelValue="none"
          />
          <Clearfix />
          <select defaultValue="2">
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
          <Submit className="col s12 l3">Save</Submit>
        </form>
        <Clearfix />

        <p>Changes made here will affect the item's status in realtime</p>
      </Modal>
    </>
  );
};

export default ItemDetails;
