import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import M from 'materialize-css';
import $ from 'jquery';
import __ from '../../utilities/functions';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import TextInput from '../../ui/TextInput';
import Submit from '../../ui/Submit';
import redirect from '../../utilities/redirect';
import Breadcrumb from '../../ui/Breadcrumb';
import Clients from '../components/ClientDropdown';
import SubscriptionDropdown from '../components/SubscriptionDropdown';
import timestamp from '../../utilities/timestamp';
import Datepicker from '../../ui/Datepicker';
import Clearfix from '../../ui/Clearfix';

const AddInvoice = () => {
  const [form, setForm] = useState({ dueDate: timestamp.now() });
  const dispatch = useDispatch();

  const addInvoice = async (e) => {
    e.preventDefault();
    __.showLoadingBar(dispatch);
    try {
      const response = await __.post('/invoices', form);
      if (response.status === 200) {
        __.displaySuccessMessage('Added successfully!');
        __.redirect(dispatch, '/admin/invoices');
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Invoices', route: '/admin/invoices' },
    { label: 'Add Invoice', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv4">
        <Heading className="left">Add Invoice</Heading>
        <Clearfix />
        <form onSubmit={(e) => addInvoice(e)}>
          <TextInput label="Description" className="col s12 l3" state={form} isRequired id="description" setState={setForm} type="text" value={form.description} />
          <TextInput label="Amount" className="col s12 l3" state={form} isRequired id="amount" setState={setForm} type="text" value={form.amount} />
          <SubscriptionDropdown setState={setForm} state={form} id="subscription" className="col s12 l4" label="Choose Subscription" initialValue={{ key: '0', value: 'None' }} />
          <Datepicker id="dueDate" setState={setForm} state={form} label="Choose Due Date" className="col s12 l2" />
          <Submit className="col s12">Add Invoice</Submit>
        </form>
      </Panel>
    </>
  );
};

export default AddInvoice;
