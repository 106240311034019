import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function Button({ route, icon, children, position = 'left', className = '' }) {
  return (
    <NavLink to={route} className={`dim ph4 pv2 mb2 mh2 dib nButton nEmboss light-blue-text ${position} ${className}`}>
      <i className="material-icons md-36">{icon}</i> {children.toLowerCase()}
    </NavLink>
  );
}

Button.propTypes = {
  children: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
};

export default Button;
