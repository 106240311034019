import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import __ from '../../utilities/functions';
import Panel from '../../ui/Panel';
import Heading from '../../ui/Heading';
import TextInput from '../../ui/TextInput';
import Submit from '../../ui/Submit';
import redirect from '../../utilities/redirect';
import Breadcrumb from '../../ui/Breadcrumb';
import Clearfix from '../../ui/Clearfix';

const EditClient = () => {
  const { id } = useParams();
  const [form, setForm] = useState({ id });
  const dispatch = useDispatch();

  // get details
  useEffect(async () => {
    __.showLoadingBar(dispatch);
    try {
      const response = await __.get(`/clients?id=${id}`);
      if (response.status === 200) {
        setForm(response.data[0]);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message || err);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  }, []);

  const saveEdits = async (e) => {
    e.preventDefault();
    __.showLoadingBar(dispatch);
    try {
      const response = await __.patch('/clients', form);
      if (response.status === 204) {
        __.displaySuccessMessage('Changes saved!');
        __.redirect(dispatch, `/admin/client-details/${id}`);
      }
    } catch (err) {
      __.displayErrorMessage(err.response.data.message || err);
    } finally {
      __.hideLoadingBar(dispatch);
    }
  };

  const breadcrumb = [
    { label: 'Clients', route: '/admin/clients' },
    { label: 'Client Details', route: `/admin/client-details/${id}` },
    { label: 'Edit Client', route: '#' },
  ];

  return (
    <>
      <Breadcrumb trail={breadcrumb} className="col s12" />
      <Panel className="col s12 mv4">
        <Heading>Edit Client</Heading>
        <Clearfix />
        <form onSubmit={(e) => saveEdits(e)}>
          <TextInput label="First Name" className="col s12 l3" state={form} isRequired id="firstname" setState={setForm} type="text" value={form.firstname} />
          <TextInput label="Last Name" className="col s12 l3" state={form} isRequired id="lastname" setState={setForm} type="text" value={form.lastname} />
          <TextInput label="Company" className="col s12 l2" state={form} isRequired id="company" setState={setForm} type="text" value={form.company} />
          <TextInput label="Email" className="col s12 l4" state={form} isRequired id="email" setState={setForm} type="text" value={form.email} />
          <TextInput label="Phone" className="col s12 l3" state={form} isRequired id="phone" setState={setForm} type="text" value={form.phone} />
          <Submit className="col s12">Save Changes</Submit>
        </form>
      </Panel>
    </>
  );
};

export default EditClient;
