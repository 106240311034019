import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import $ from 'jquery';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';
import SelectMenu from '../../ui/SelectMenu';

/**
 * A <select> dropdown list of all clients
 * @param defaultValue
 * @param className
 * @param id
 * @param initialValue
 * @param state
 * @param setState
 * @param label
 * @returns {JSX.Element}
 * @constructor
 */
function SubscriptionDropdown({ defaultValue, className, id, initialValue = null, state, setState, label }) {
  const dispatch = useDispatch();

  useEffect(() => {
    M.FormSelect.init($(`#${id}`), {
      classes: 'select-wrapper',
    });
  });

  const [list, setList] = useState([]);

  useEffect(() => {
    __.showLoadingBar(dispatch);
    __.get(`/subscriptions`).then((response) => {
      __.hideLoadingBar(dispatch);

      if (response.error) {
        __.displayErrorMessage(response.message);
        return;
      }

      setList(response.data);
    });
  }, []);

  return <SelectMenu state={state} setState={setState} list={list} label={label} id={id} className={className} defaultValue={defaultValue} valueKey="coreId" idKey="id" />;
}

export default SubscriptionDropdown;
